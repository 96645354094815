import logo from "../logo.svg";
import "../App.css";
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  DateInput,
  useNotify,
  useRedirect,
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ShowButton,
  Loading,
  useGetOne,
  useUpdate,
} from "react-admin";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "../Header";
import { commonColors, escalas } from "../siteSettings";
import Banner from "../assets/Banner-Scorad.jpg";
import Next from "../assets/next.png";
import Imagen1 from "../assets/Que-es-Dermatitis.jpg";
import Imagen2 from "../assets/Tips-EASI.jpg";
import Imagen3 from "../assets/Tips-Scorad.jpg";
import Scorad from "../assets/PO-Scorad.png";
import EASI from "../assets/Escala-EASI.png";
import DLQI from "../assets/Escala-DLQI.png";
import ADCT from "../assets/Escala-ADCT.png";
import HADS from "../assets/Encuesta-HADS.png";
import POEM from "../assets/Escala-POEM.png";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import { useNavigate, useParams } from "react-router-dom";
// import MainFeaturedPost from '../MainFeaturedPost';
// import FeaturedPost from '../FeaturedPost';
// import Main from '../Main';
// import Sidebar from '../Sidebar';
import Footer from "../Footer";
const defaultTheme = createTheme();

function PacienteShow(props) {
  const navigate = useNavigate();
  const { idescala } = useParams();
  const { id } = useParams();
  const escala = idescala ? escalas.find((item) => item.id === idescala) : null;
  const Banner = escala
    ? require(`../assets/${escala.banner}`)
    : require(`../assets/Banner-2.jpg`);
  const settings = {
    bannerTitle:
      "Una herramienta práctica para la evaluación clinimétrica de pacientes con Dermatitis Atópica",
  };
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = (data) => {
    notify(`Paciente creado con éxito`); // default message is 'ra.notification.created'
    navigate(`/escala/${idescala}/list`);
  };

  const { data: paciente, isLoading, error } = useGetOne("pacientes", { id });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  if (idescala === "scorad" && paciente && paciente.Scorad) {
    return (
      <>
        <CssBaseline />
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: { xs: 2, md: 12 },
            width: "100%",
            backgroundColor: "transparent",
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h1 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.titulo}
          </h1>
          <h2 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.subtitulo}
          </h2>
        </Box>
        <Container maxWidth="lg">
          <h1
            style={{
              color: commonColors.morado,
              borderColor: commonColors.morado,
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
            }}
          >
            Resultados PO SCORAD
          </h1>
          <div style={{ display: "flex" }}>
            <div className="h3-triangle">
              <h3 style={{ margin: 0 }}>
                {paciente.nombre} {paciente.apellido}
              </h3>
            </div>
            <div className="triangle" />
          </div>
          <Box
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              width: "100%",
            }}
            display="flex"
          >
            <Grid
              sx={{ alignItems: "center", justifyContent: "center" }}
              container
              spacing={1}
            >
              {paciente &&
                paciente.Scorad &&
                Object.keys(paciente.Scorad).map((fecha) => (
                  <Paper
                    sx={{
                      backgroundColor: commonColors.morado,
                      maxWidth: "800px",
                      width: "80%",
                      padding: "0",
                      marginBottom: 5,
                    }}
                    elevation={3}
                  >
                    <h4 style={{ color: "white", textAlign: "center" }}>
                      {paciente.Scorad &&
                        paciente.Scorad[fecha] &&
                        paciente.Scorad[fecha]["updateAt"] &&
                        paciente.Scorad[fecha]["updateAt"].toString()}
                    </h4>
                    <Box
                      sx={{
                        backgroundColor: "#EAE8F1",
                        maxWidth: "800px",
                        width: "100%",
                        padding: "0",
                      }}
                      display="block"
                    >
                      <Grid container display="flex">
                        <Grid
                          md={3}
                          sx={{ textAlign: "center", margin: "auto" }}
                          item
                        >
                          <img
                            src={
                              paciente.genero && paciente.genero === "f"
                                ? require("../assets/Mujer.png")
                                : require("../assets/Hombre.png")
                            }
                          />
                          <p>
                            {paciente.nombre} {paciente.apellido}
                          </p>
                        </Grid>
                        <Grid
                          sx={{
                            backgroundColor: "white",
                            padding: "20px 40px",
                          }}
                          md={9}
                          item
                        >
                          <h2 style={{ color: commonColors.morado }}>
                            Puntaje:{" "}
                            {paciente.Scorad &&
                              paciente.Scorad[fecha] &&
                              paciente.Scorad[fecha].total}
                          </h2>
                          <h2 style={{ color: commonColors.morado }}>
                            Gravedad:{" "}
                            {paciente.Scorad === 0 ||
                            (paciente.Scorad[fecha] &&
                              paciente.Scorad[fecha].total &&
                              paciente.Scorad[fecha].total < 25)
                              ? "Leve"
                              : paciente &&
                                  paciente.Scorad &&
                                  paciente.Scorad[fecha] &&
                                  paciente.Scorad[fecha].total > 25 &&
                                  paciente.Scorad[fecha].total < 50
                                ? "Moderada"
                                : "Grave"}
                          </h2>
                          {
                            //JSON.stringify(paciente.Scorad[fecha])
                          }
                          <img
                            style={{
                              width: "284px",
                              height: "728px",
                              maxWidth: "284px !important",
                            }}
                            src={
                              !Object.keys(
                                paciente.Scorad[fecha].zonasAfectadas,
                              ) ||
                              !Object.keys(
                                paciente.Scorad[fecha].zonasAfectadas,
                              ).length
                                ? require("../assets/bodyhombre/body.png")
                                : Object.keys(
                                      paciente.Scorad[fecha].zonasAfectadas,
                                    ).join() === "torso"
                                  ? require("../assets/bodyhombre/bodytorso.png")
                                  : Object.keys(
                                        paciente.Scorad[fecha].zonasAfectadas,
                                      ).join() === "cabeza y cuello"
                                    ? require("../assets/bodyhombre/bodycabeza.png")
                                    : Object.keys(
                                          paciente.Scorad[fecha].zonasAfectadas,
                                        ).join() === "cadera"
                                      ? require("../assets/bodyhombre/bodycadera.png")
                                      : Object.keys(
                                            paciente.Scorad[fecha]
                                              .zonasAfectadas,
                                          ).length == 2 &&
                                          Object.keys(
                                            paciente.Scorad[fecha]
                                              .zonasAfectadas,
                                          ).includes("cadera") &&
                                          Object.keys(
                                            paciente.Scorad[fecha]
                                              .zonasAfectadas,
                                          ).includes("pierna izquierda")
                                        ? require("../assets/bodyhombre/bodycaderapiernaizq.png")
                                        : Object.keys(
                                              paciente.Scorad[fecha]
                                                .zonasAfectadas,
                                            ).length == 2 &&
                                            Object.keys(
                                              paciente.Scorad[fecha]
                                                .zonasAfectadas,
                                            ).includes("cadera") &&
                                            Object.keys(
                                              paciente.Scorad[fecha]
                                                .zonasAfectadas,
                                            ).includes("pierna derecha")
                                          ? require("../assets/bodyhombre/bodycaderapiernader.png")
                                          : Object.keys(
                                                paciente.Scorad[fecha]
                                                  .zonasAfectadas,
                                              ).length == 3 &&
                                              Object.keys(
                                                paciente.Scorad[fecha]
                                                  .zonasAfectadas,
                                              ).includes("cadera") &&
                                              Object.keys(
                                                paciente.Scorad[fecha]
                                                  .zonasAfectadas,
                                              ).includes("pierna izquierda") &&
                                              Object.keys(
                                                paciente.Scorad[fecha]
                                                  .zonasAfectadas,
                                              ).includes("pierna derecha")
                                            ? require("../assets/bodyhombre/bodycaderapiernas.png")
                                            : Object.keys(
                                                  paciente.Scorad[fecha]
                                                    .zonasAfectadas,
                                                ).join() === "brazo izquierdo"
                                              ? require("../assets/bodyhombre/bodybrazoizq.png")
                                              : Object.keys(
                                                    paciente.Scorad[fecha]
                                                      .zonasAfectadas,
                                                  ).join() === "brazo derecho"
                                                ? require("../assets/bodyhombre/bodybrazoder.png")
                                                : Object.keys(
                                                      paciente.Scorad[fecha]
                                                        .zonasAfectadas,
                                                    ).join() ===
                                                    "pierna izquierda"
                                                  ? require("../assets/bodyhombre/bodypiernader.png")
                                                  : Object.keys(
                                                        paciente.Scorad[fecha]
                                                          .zonasAfectadas,
                                                      ).join() ===
                                                      "pierna derecha"
                                                    ? require("../assets/bodyhombre/bodypiernaizq.png")
                                                    : Object.keys(
                                                          paciente.Scorad[fecha]
                                                            .zonasAfectadas,
                                                        ).join() === "hombros"
                                                      ? require("../assets/bodyhombre/bodyhombros.png")
                                                      : Object.keys(
                                                            paciente.Scorad[
                                                              fecha
                                                            ].zonasAfectadas,
                                                          ).length == 2 &&
                                                          Object.keys(
                                                            paciente.Scorad[
                                                              fecha
                                                            ].zonasAfectadas,
                                                          ).includes("torso") &&
                                                          Object.keys(
                                                            paciente.Scorad[
                                                              fecha
                                                            ].zonasAfectadas,
                                                          ).includes(
                                                            "cabeza y cuello",
                                                          )
                                                        ? require("../assets/bodyhombre/bodycabezatorso.png")
                                                        : Object.keys(
                                                              paciente.Scorad[
                                                                fecha
                                                              ].zonasAfectadas,
                                                            ).length == 2 &&
                                                            Object.keys(
                                                              paciente.Scorad[
                                                                fecha
                                                              ].zonasAfectadas,
                                                            ).includes(
                                                              "cabeza y cuello",
                                                            ) &&
                                                            Object.keys(
                                                              paciente.Scorad[
                                                                fecha
                                                              ].zonasAfectadas,
                                                            ).includes(
                                                              "brazo izquierdo",
                                                            )
                                                          ? require("../assets/bodyhombre/cabezabrazoizq.png")
                                                          : Object.keys(
                                                                paciente.Scorad[
                                                                  fecha
                                                                ]
                                                                  .zonasAfectadas,
                                                              ).length == 3 &&
                                                              Object.keys(
                                                                paciente.Scorad[
                                                                  fecha
                                                                ]
                                                                  .zonasAfectadas,
                                                              ).includes(
                                                                "cabeza y cuello",
                                                              ) &&
                                                              Object.keys(
                                                                paciente.Scorad[
                                                                  fecha
                                                                ]
                                                                  .zonasAfectadas,
                                                              ).includes(
                                                                "brazo izquierdo",
                                                              ) &&
                                                              Object.keys(
                                                                paciente.Scorad[
                                                                  fecha
                                                                ]
                                                                  .zonasAfectadas,
                                                              ).includes(
                                                                "pierna izquierda",
                                                              )
                                                            ? require("../assets/bodyhombre/cabezabrazoizqpiernaizq.png")
                                                            : Object.keys(
                                                                  paciente
                                                                    .Scorad[
                                                                    fecha
                                                                  ]
                                                                    .zonasAfectadas,
                                                                ).length == 3 &&
                                                                Object.keys(
                                                                  paciente
                                                                    .Scorad[
                                                                    fecha
                                                                  ]
                                                                    .zonasAfectadas,
                                                                ).includes(
                                                                  "cabeza y cuello",
                                                                ) &&
                                                                Object.keys(
                                                                  paciente
                                                                    .Scorad[
                                                                    fecha
                                                                  ]
                                                                    .zonasAfectadas,
                                                                ).includes(
                                                                  "brazo izquierdo",
                                                                ) &&
                                                                Object.keys(
                                                                  paciente
                                                                    .Scorad[
                                                                    fecha
                                                                  ]
                                                                    .zonasAfectadas,
                                                                ).includes(
                                                                  "pierna derecha",
                                                                )
                                                              ? require("../assets/bodyhombre/cabezabrazoizqpiernader.png")
                                                              : Object.keys(
                                                                    paciente
                                                                      .Scorad[
                                                                      fecha
                                                                    ]
                                                                      .zonasAfectadas,
                                                                  ).length ==
                                                                    4 &&
                                                                  Object.keys(
                                                                    paciente
                                                                      .Scorad[
                                                                      fecha
                                                                    ]
                                                                      .zonasAfectadas,
                                                                  ).includes(
                                                                    "cabeza y cuello",
                                                                  ) &&
                                                                  Object.keys(
                                                                    paciente
                                                                      .Scorad[
                                                                      fecha
                                                                    ]
                                                                      .zonasAfectadas,
                                                                  ).includes(
                                                                    "brazo izquierdo",
                                                                  ) &&
                                                                  Object.keys(
                                                                    paciente
                                                                      .Scorad[
                                                                      fecha
                                                                    ]
                                                                      .zonasAfectadas,
                                                                  ).includes(
                                                                    "pierna derecha",
                                                                  ) &&
                                                                  Object.keys(
                                                                    paciente
                                                                      .Scorad[
                                                                      fecha
                                                                    ]
                                                                      .zonasAfectadas,
                                                                  ).includes(
                                                                    "pierna izquierda",
                                                                  )
                                                                ? require("../assets/bodyhombre/cabezabrazoizqpiernas.png")
                                                                : Object.keys(
                                                                      paciente
                                                                        .Scorad[
                                                                        fecha
                                                                      ]
                                                                        .zonasAfectadas,
                                                                    ).length ==
                                                                      2 &&
                                                                    Object.keys(
                                                                      paciente
                                                                        .Scorad[
                                                                        fecha
                                                                      ]
                                                                        .zonasAfectadas,
                                                                    ).includes(
                                                                      "cabeza y cuello",
                                                                    ) &&
                                                                    Object.keys(
                                                                      paciente
                                                                        .Scorad[
                                                                        fecha
                                                                      ]
                                                                        .zonasAfectadas,
                                                                    ).includes(
                                                                      "brazo derecho",
                                                                    )
                                                                  ? require("../assets/bodyhombre/cabezabrazoder.png")
                                                                  : Object.keys(
                                                                        paciente
                                                                          .Scorad[
                                                                          fecha
                                                                        ]
                                                                          .zonasAfectadas,
                                                                      )
                                                                        .length ==
                                                                        3 &&
                                                                      Object.keys(
                                                                        paciente
                                                                          .Scorad[
                                                                          fecha
                                                                        ]
                                                                          .zonasAfectadas,
                                                                      ).includes(
                                                                        "cabeza y cuello",
                                                                      ) &&
                                                                      Object.keys(
                                                                        paciente
                                                                          .Scorad[
                                                                          fecha
                                                                        ]
                                                                          .zonasAfectadas,
                                                                      ).includes(
                                                                        "brazo derecho",
                                                                      ) &&
                                                                      Object.keys(
                                                                        paciente
                                                                          .Scorad[
                                                                          fecha
                                                                        ]
                                                                          .zonasAfectadas,
                                                                      ).includes(
                                                                        "pierna izquierda",
                                                                      )
                                                                    ? require("../assets/bodyhombre/cabezabrazoderpiernaizq.png")
                                                                    : Object.keys(
                                                                          paciente
                                                                            .Scorad[
                                                                            fecha
                                                                          ]
                                                                            .zonasAfectadas,
                                                                        )
                                                                          .length ==
                                                                          3 &&
                                                                        Object.keys(
                                                                          paciente
                                                                            .Scorad[
                                                                            fecha
                                                                          ]
                                                                            .zonasAfectadas,
                                                                        ).includes(
                                                                          "cabeza y cuello",
                                                                        ) &&
                                                                        Object.keys(
                                                                          paciente
                                                                            .Scorad[
                                                                            fecha
                                                                          ]
                                                                            .zonasAfectadas,
                                                                        ).includes(
                                                                          "brazo derecho",
                                                                        ) &&
                                                                        Object.keys(
                                                                          paciente
                                                                            .Scorad[
                                                                            fecha
                                                                          ]
                                                                            .zonasAfectadas,
                                                                        ).includes(
                                                                          "pierna derecha",
                                                                        )
                                                                      ? require("../assets/bodyhombre/cabezabrazoderpiernader.png")
                                                                      : Object.keys(
                                                                            paciente
                                                                              .Scorad[
                                                                              fecha
                                                                            ]
                                                                              .zonasAfectadas,
                                                                          )
                                                                            .length ==
                                                                            4 &&
                                                                          Object.keys(
                                                                            paciente
                                                                              .Scorad[
                                                                              fecha
                                                                            ]
                                                                              .zonasAfectadas,
                                                                          ).includes(
                                                                            "cabeza y cuello",
                                                                          ) &&
                                                                          Object.keys(
                                                                            paciente
                                                                              .Scorad[
                                                                              fecha
                                                                            ]
                                                                              .zonasAfectadas,
                                                                          ).includes(
                                                                            "brazo derecho",
                                                                          ) &&
                                                                          Object.keys(
                                                                            paciente
                                                                              .Scorad[
                                                                              fecha
                                                                            ]
                                                                              .zonasAfectadas,
                                                                          ).includes(
                                                                            "pierna derecha",
                                                                          ) &&
                                                                          Object.keys(
                                                                            paciente
                                                                              .Scorad[
                                                                              fecha
                                                                            ]
                                                                              .zonasAfectadas,
                                                                          ).includes(
                                                                            "pierna izquierda",
                                                                          )
                                                                        ? require("../assets/bodyhombre/cabezabrazoderpiernas.png")
                                                                        : Object.keys(
                                                                              paciente
                                                                                .Scorad[
                                                                                fecha
                                                                              ]
                                                                                .zonasAfectadas,
                                                                            )
                                                                              .length ==
                                                                              2 &&
                                                                            Object.keys(
                                                                              paciente
                                                                                .Scorad[
                                                                                fecha
                                                                              ]
                                                                                .zonasAfectadas,
                                                                            ).includes(
                                                                              "cabeza y cuello",
                                                                            ) &&
                                                                            Object.keys(
                                                                              paciente
                                                                                .Scorad[
                                                                                fecha
                                                                              ]
                                                                                .zonasAfectadas,
                                                                            ).includes(
                                                                              "pierna izquierda",
                                                                            )
                                                                          ? require("../assets/bodyhombre/bodycabezapiernaizq.png")
                                                                          : Object.keys(
                                                                                paciente
                                                                                  .Scorad[
                                                                                  fecha
                                                                                ]
                                                                                  .zonasAfectadas,
                                                                              )
                                                                                .length ==
                                                                                2 &&
                                                                              Object.keys(
                                                                                paciente
                                                                                  .Scorad[
                                                                                  fecha
                                                                                ]
                                                                                  .zonasAfectadas,
                                                                              ).includes(
                                                                                "cabeza y cuello",
                                                                              ) &&
                                                                              Object.keys(
                                                                                paciente
                                                                                  .Scorad[
                                                                                  fecha
                                                                                ]
                                                                                  .zonasAfectadas,
                                                                              ).includes(
                                                                                "pierna derecha",
                                                                              )
                                                                            ? require("../assets/bodyhombre/bodycabezapiernader.png")
                                                                            : Object.keys(
                                                                                  paciente
                                                                                    .Scorad[
                                                                                    fecha
                                                                                  ]
                                                                                    .zonasAfectadas,
                                                                                )
                                                                                  .length ==
                                                                                  3 &&
                                                                                Object.keys(
                                                                                  paciente
                                                                                    .Scorad[
                                                                                    fecha
                                                                                  ]
                                                                                    .zonasAfectadas,
                                                                                ).includes(
                                                                                  "cabeza y cuello",
                                                                                ) &&
                                                                                Object.keys(
                                                                                  paciente
                                                                                    .Scorad[
                                                                                    fecha
                                                                                  ]
                                                                                    .zonasAfectadas,
                                                                                ).includes(
                                                                                  "pierna derecha",
                                                                                ) &&
                                                                                Object.keys(
                                                                                  paciente
                                                                                    .Scorad[
                                                                                    fecha
                                                                                  ]
                                                                                    .zonasAfectadas,
                                                                                ).includes(
                                                                                  "pierna izquierda",
                                                                                )
                                                                              ? require("../assets/bodyhombre/bodycabezapiernas.png")
                                                                              : Object.keys(
                                                                                    paciente
                                                                                      .Scorad[
                                                                                      fecha
                                                                                    ]
                                                                                      .zonasAfectadas,
                                                                                  )
                                                                                    .length ==
                                                                                    2 &&
                                                                                  Object.keys(
                                                                                    paciente
                                                                                      .Scorad[
                                                                                      fecha
                                                                                    ]
                                                                                      .zonasAfectadas,
                                                                                  ).includes(
                                                                                    "cadera",
                                                                                  ) &&
                                                                                  Object.keys(
                                                                                    paciente
                                                                                      .Scorad[
                                                                                      fecha
                                                                                    ]
                                                                                      .zonasAfectadas,
                                                                                  ).includes(
                                                                                    "cabeza y cuello",
                                                                                  )
                                                                                ? require("../assets/bodyhombre/bodycabezacadera.png")
                                                                                : Object.keys(
                                                                                      paciente
                                                                                        .Scorad[
                                                                                        fecha
                                                                                      ]
                                                                                        .zonasAfectadas,
                                                                                    )
                                                                                      .length ==
                                                                                      3 &&
                                                                                    Object.keys(
                                                                                      paciente
                                                                                        .Scorad[
                                                                                        fecha
                                                                                      ]
                                                                                        .zonasAfectadas,
                                                                                    ).includes(
                                                                                      "cadera",
                                                                                    ) &&
                                                                                    Object.keys(
                                                                                      paciente
                                                                                        .Scorad[
                                                                                        fecha
                                                                                      ]
                                                                                        .zonasAfectadas,
                                                                                    ).includes(
                                                                                      "cabeza y cuello",
                                                                                    ) &&
                                                                                    Object.keys(
                                                                                      paciente
                                                                                        .Scorad[
                                                                                        fecha
                                                                                      ]
                                                                                        .zonasAfectadas,
                                                                                    ).includes(
                                                                                      "pierna derecha",
                                                                                    )
                                                                                  ? require("../assets/bodyhombre/bodycabezacaderapiernader.png")
                                                                                  : Object.keys(
                                                                                        paciente
                                                                                          .Scorad[
                                                                                          fecha
                                                                                        ]
                                                                                          .zonasAfectadas,
                                                                                      )
                                                                                        .length ==
                                                                                        3 &&
                                                                                      Object.keys(
                                                                                        paciente
                                                                                          .Scorad[
                                                                                          fecha
                                                                                        ]
                                                                                          .zonasAfectadas,
                                                                                      ).includes(
                                                                                        "cadera",
                                                                                      ) &&
                                                                                      Object.keys(
                                                                                        paciente
                                                                                          .Scorad[
                                                                                          fecha
                                                                                        ]
                                                                                          .zonasAfectadas,
                                                                                      ).includes(
                                                                                        "cabeza y cuello",
                                                                                      ) &&
                                                                                      Object.keys(
                                                                                        paciente
                                                                                          .Scorad[
                                                                                          fecha
                                                                                        ]
                                                                                          .zonasAfectadas,
                                                                                      ).includes(
                                                                                        "pierna izquierda",
                                                                                      )
                                                                                    ? require("../assets/bodyhombre/bodycabezacaderapiernaizq.png")
                                                                                    : Object.keys(
                                                                                          paciente
                                                                                            .Scorad[
                                                                                            fecha
                                                                                          ]
                                                                                            .zonasAfectadas,
                                                                                        )
                                                                                          .length ==
                                                                                          4 &&
                                                                                        Object.keys(
                                                                                          paciente
                                                                                            .Scorad[
                                                                                            fecha
                                                                                          ]
                                                                                            .zonasAfectadas,
                                                                                        ).includes(
                                                                                          "cadera",
                                                                                        ) &&
                                                                                        Object.keys(
                                                                                          paciente
                                                                                            .Scorad[
                                                                                            fecha
                                                                                          ]
                                                                                            .zonasAfectadas,
                                                                                        ).includes(
                                                                                          "cabeza y cuello",
                                                                                        ) &&
                                                                                        Object.keys(
                                                                                          paciente
                                                                                            .Scorad[
                                                                                            fecha
                                                                                          ]
                                                                                            .zonasAfectadas,
                                                                                        ).includes(
                                                                                          "pierna izquierda",
                                                                                        ) &&
                                                                                        Object.keys(
                                                                                          paciente
                                                                                            .Scorad[
                                                                                            fecha
                                                                                          ]
                                                                                            .zonasAfectadas,
                                                                                        ).includes(
                                                                                          "pierna derecha",
                                                                                        )
                                                                                      ? require("../assets/bodyhombre/bodycabezacaderapiernas.png")
                                                                                      : Object.keys(
                                                                                            paciente
                                                                                              .Scorad[
                                                                                              fecha
                                                                                            ]
                                                                                              .zonasAfectadas,
                                                                                          )
                                                                                            .length ==
                                                                                            3 &&
                                                                                          Object.keys(
                                                                                            paciente
                                                                                              .Scorad[
                                                                                              fecha
                                                                                            ]
                                                                                              .zonasAfectadas,
                                                                                          ).includes(
                                                                                            "cadera",
                                                                                          ) &&
                                                                                          Object.keys(
                                                                                            paciente
                                                                                              .Scorad[
                                                                                              fecha
                                                                                            ]
                                                                                              .zonasAfectadas,
                                                                                          ).includes(
                                                                                            "cabeza y cuello",
                                                                                          ) &&
                                                                                          Object.keys(
                                                                                            paciente
                                                                                              .Scorad[
                                                                                              fecha
                                                                                            ]
                                                                                              .zonasAfectadas,
                                                                                          ).includes(
                                                                                            "brazo derecho",
                                                                                          )
                                                                                        ? require("../assets/bodyhombre/bodycabezacaderabrazoder.png")
                                                                                        : Object.keys(
                                                                                              paciente
                                                                                                .Scorad[
                                                                                                fecha
                                                                                              ]
                                                                                                .zonasAfectadas,
                                                                                            )
                                                                                              .length ==
                                                                                              3 &&
                                                                                            Object.keys(
                                                                                              paciente
                                                                                                .Scorad[
                                                                                                fecha
                                                                                              ]
                                                                                                .zonasAfectadas,
                                                                                            ).includes(
                                                                                              "cadera",
                                                                                            ) &&
                                                                                            Object.keys(
                                                                                              paciente
                                                                                                .Scorad[
                                                                                                fecha
                                                                                              ]
                                                                                                .zonasAfectadas,
                                                                                            ).includes(
                                                                                              "cabeza y cuello",
                                                                                            ) &&
                                                                                            Object.keys(
                                                                                              paciente
                                                                                                .Scorad[
                                                                                                fecha
                                                                                              ]
                                                                                                .zonasAfectadas,
                                                                                            ).includes(
                                                                                              "brazo izquierdo",
                                                                                            )
                                                                                          ? require("../assets/bodyhombre/bodycabezacaderabrazoizq.png")
                                                                                          : Object.keys(
                                                                                                paciente
                                                                                                  .Scorad[
                                                                                                  fecha
                                                                                                ]
                                                                                                  .zonasAfectadas,
                                                                                              )
                                                                                                .length ==
                                                                                                4 &&
                                                                                              Object.keys(
                                                                                                paciente
                                                                                                  .Scorad[
                                                                                                  fecha
                                                                                                ]
                                                                                                  .zonasAfectadas,
                                                                                              ).includes(
                                                                                                "cadera",
                                                                                              ) &&
                                                                                              Object.keys(
                                                                                                paciente
                                                                                                  .Scorad[
                                                                                                  fecha
                                                                                                ]
                                                                                                  .zonasAfectadas,
                                                                                              ).includes(
                                                                                                "cabeza y cuello",
                                                                                              ) &&
                                                                                              Object.keys(
                                                                                                paciente
                                                                                                  .Scorad[
                                                                                                  fecha
                                                                                                ]
                                                                                                  .zonasAfectadas,
                                                                                              ).includes(
                                                                                                "brazo izquierdo",
                                                                                              ) &&
                                                                                              Object.keys(
                                                                                                paciente
                                                                                                  .Scorad[
                                                                                                  fecha
                                                                                                ]
                                                                                                  .zonasAfectadas,
                                                                                              ).includes(
                                                                                                "brazo derecho",
                                                                                              )
                                                                                            ? require("../assets/bodyhombre/bodycabezacaderabrazoizqbrazoder.png")
                                                                                            : Object.keys(
                                                                                                  paciente
                                                                                                    .Scorad[
                                                                                                    fecha
                                                                                                  ]
                                                                                                    .zonasAfectadas,
                                                                                                )
                                                                                                  .length ==
                                                                                                  4 &&
                                                                                                Object.keys(
                                                                                                  paciente
                                                                                                    .Scorad[
                                                                                                    fecha
                                                                                                  ]
                                                                                                    .zonasAfectadas,
                                                                                                ).includes(
                                                                                                  "cadera",
                                                                                                ) &&
                                                                                                Object.keys(
                                                                                                  paciente
                                                                                                    .Scorad[
                                                                                                    fecha
                                                                                                  ]
                                                                                                    .zonasAfectadas,
                                                                                                ).includes(
                                                                                                  "cabeza y cuello",
                                                                                                ) &&
                                                                                                Object.keys(
                                                                                                  paciente
                                                                                                    .Scorad[
                                                                                                    fecha
                                                                                                  ]
                                                                                                    .zonasAfectadas,
                                                                                                ).includes(
                                                                                                  "brazo izquierdo",
                                                                                                ) &&
                                                                                                Object.keys(
                                                                                                  paciente
                                                                                                    .Scorad[
                                                                                                    fecha
                                                                                                  ]
                                                                                                    .zonasAfectadas,
                                                                                                ).includes(
                                                                                                  "pierna derecha",
                                                                                                )
                                                                                              ? require("../assets/bodyhombre/bodycabezacaderabrazoizqpiernader.png")
                                                                                              : Object.keys(
                                                                                                    paciente
                                                                                                      .Scorad[
                                                                                                      fecha
                                                                                                    ]
                                                                                                      .zonasAfectadas,
                                                                                                  )
                                                                                                    .length ==
                                                                                                    4 &&
                                                                                                  Object.keys(
                                                                                                    paciente
                                                                                                      .Scorad[
                                                                                                      fecha
                                                                                                    ]
                                                                                                      .zonasAfectadas,
                                                                                                  ).includes(
                                                                                                    "cadera",
                                                                                                  ) &&
                                                                                                  Object.keys(
                                                                                                    paciente
                                                                                                      .Scorad[
                                                                                                      fecha
                                                                                                    ]
                                                                                                      .zonasAfectadas,
                                                                                                  ).includes(
                                                                                                    "cabeza y cuello",
                                                                                                  ) &&
                                                                                                  Object.keys(
                                                                                                    paciente
                                                                                                      .Scorad[
                                                                                                      fecha
                                                                                                    ]
                                                                                                      .zonasAfectadas,
                                                                                                  ).includes(
                                                                                                    "brazo derecho",
                                                                                                  ) &&
                                                                                                  Object.keys(
                                                                                                    paciente
                                                                                                      .Scorad[
                                                                                                      fecha
                                                                                                    ]
                                                                                                      .zonasAfectadas,
                                                                                                  ).includes(
                                                                                                    "pierna derecha",
                                                                                                  )
                                                                                                ? require("../assets/bodyhombre/bodycabezacaderabrazoderpiernader.png")
                                                                                                : Object.keys(
                                                                                                      paciente
                                                                                                        .Scorad[
                                                                                                        fecha
                                                                                                      ]
                                                                                                        .zonasAfectadas,
                                                                                                    )
                                                                                                      .length ==
                                                                                                      4 &&
                                                                                                    Object.keys(
                                                                                                      paciente
                                                                                                        .Scorad[
                                                                                                        fecha
                                                                                                      ]
                                                                                                        .zonasAfectadas,
                                                                                                    ).includes(
                                                                                                      "cadera",
                                                                                                    ) &&
                                                                                                    Object.keys(
                                                                                                      paciente
                                                                                                        .Scorad[
                                                                                                        fecha
                                                                                                      ]
                                                                                                        .zonasAfectadas,
                                                                                                    ).includes(
                                                                                                      "cabeza y cuello",
                                                                                                    ) &&
                                                                                                    Object.keys(
                                                                                                      paciente
                                                                                                        .Scorad[
                                                                                                        fecha
                                                                                                      ]
                                                                                                        .zonasAfectadas,
                                                                                                    ).includes(
                                                                                                      "brazo izquierdo",
                                                                                                    ) &&
                                                                                                    Object.keys(
                                                                                                      paciente
                                                                                                        .Scorad[
                                                                                                        fecha
                                                                                                      ]
                                                                                                        .zonasAfectadas,
                                                                                                    ).includes(
                                                                                                      "pierna izquierda",
                                                                                                    )
                                                                                                  ? require("../assets/bodyhombre/bodycabezacaderabrazoizqpiernaizq.png")
                                                                                                  : Object.keys(
                                                                                                        paciente
                                                                                                          .Scorad[
                                                                                                          fecha
                                                                                                        ]
                                                                                                          .zonasAfectadas,
                                                                                                      )
                                                                                                        .length ==
                                                                                                        4 &&
                                                                                                      Object.keys(
                                                                                                        paciente
                                                                                                          .Scorad[
                                                                                                          fecha
                                                                                                        ]
                                                                                                          .zonasAfectadas,
                                                                                                      ).includes(
                                                                                                        "cadera",
                                                                                                      ) &&
                                                                                                      Object.keys(
                                                                                                        paciente
                                                                                                          .Scorad[
                                                                                                          fecha
                                                                                                        ]
                                                                                                          .zonasAfectadas,
                                                                                                      ).includes(
                                                                                                        "cabeza y cuello",
                                                                                                      ) &&
                                                                                                      Object.keys(
                                                                                                        paciente
                                                                                                          .Scorad[
                                                                                                          fecha
                                                                                                        ]
                                                                                                          .zonasAfectadas,
                                                                                                      ).includes(
                                                                                                        "brazo derecho",
                                                                                                      ) &&
                                                                                                      Object.keys(
                                                                                                        paciente
                                                                                                          .Scorad[
                                                                                                          fecha
                                                                                                        ]
                                                                                                          .zonasAfectadas,
                                                                                                      ).includes(
                                                                                                        "pierna izquierda",
                                                                                                      )
                                                                                                    ? require("../assets/bodyhombre/bodycabezacaderabrazoderpiernaizq.png")
                                                                                                    : Object.keys(
                                                                                                          paciente
                                                                                                            .Scorad[
                                                                                                            fecha
                                                                                                          ]
                                                                                                            .zonasAfectadas,
                                                                                                        )
                                                                                                          .length ==
                                                                                                          5 &&
                                                                                                        Object.keys(
                                                                                                          paciente
                                                                                                            .Scorad[
                                                                                                            fecha
                                                                                                          ]
                                                                                                            .zonasAfectadas,
                                                                                                        ).includes(
                                                                                                          "cadera",
                                                                                                        ) &&
                                                                                                        Object.keys(
                                                                                                          paciente
                                                                                                            .Scorad[
                                                                                                            fecha
                                                                                                          ]
                                                                                                            .zonasAfectadas,
                                                                                                        ).includes(
                                                                                                          "cabeza y cuello",
                                                                                                        ) &&
                                                                                                        Object.keys(
                                                                                                          paciente
                                                                                                            .Scorad[
                                                                                                            fecha
                                                                                                          ]
                                                                                                            .zonasAfectadas,
                                                                                                        ).includes(
                                                                                                          "brazo derecho",
                                                                                                        ) &&
                                                                                                        Object.keys(
                                                                                                          paciente
                                                                                                            .Scorad[
                                                                                                            fecha
                                                                                                          ]
                                                                                                            .zonasAfectadas,
                                                                                                        ).includes(
                                                                                                          "pierna izquierda",
                                                                                                        ) &&
                                                                                                        Object.keys(
                                                                                                          paciente
                                                                                                            .Scorad[
                                                                                                            fecha
                                                                                                          ]
                                                                                                            .zonasAfectadas,
                                                                                                        ).includes(
                                                                                                          "pierna derecha",
                                                                                                        )
                                                                                                      ? require("../assets/bodyhombre/bodycabezacaderabrazoderpiernas.png")
                                                                                                      : Object.keys(
                                                                                                            paciente
                                                                                                              .Scorad[
                                                                                                              fecha
                                                                                                            ]
                                                                                                              .zonasAfectadas,
                                                                                                          )
                                                                                                            .length ==
                                                                                                            5 &&
                                                                                                          Object.keys(
                                                                                                            paciente
                                                                                                              .Scorad[
                                                                                                              fecha
                                                                                                            ]
                                                                                                              .zonasAfectadas,
                                                                                                          ).includes(
                                                                                                            "cadera",
                                                                                                          ) &&
                                                                                                          Object.keys(
                                                                                                            paciente
                                                                                                              .Scorad[
                                                                                                              fecha
                                                                                                            ]
                                                                                                              .zonasAfectadas,
                                                                                                          ).includes(
                                                                                                            "cabeza y cuello",
                                                                                                          ) &&
                                                                                                          Object.keys(
                                                                                                            paciente
                                                                                                              .Scorad[
                                                                                                              fecha
                                                                                                            ]
                                                                                                              .zonasAfectadas,
                                                                                                          ).includes(
                                                                                                            "brazo izquierdo",
                                                                                                          ) &&
                                                                                                          Object.keys(
                                                                                                            paciente
                                                                                                              .Scorad[
                                                                                                              fecha
                                                                                                            ]
                                                                                                              .zonasAfectadas,
                                                                                                          ).includes(
                                                                                                            "pierna izquierda",
                                                                                                          ) &&
                                                                                                          Object.keys(
                                                                                                            paciente
                                                                                                              .Scorad[
                                                                                                              fecha
                                                                                                            ]
                                                                                                              .zonasAfectadas,
                                                                                                          ).includes(
                                                                                                            "pierna derecha",
                                                                                                          )
                                                                                                        ? require("../assets/bodyhombre/bodycabezacaderabrazoizqpiernas.png")
                                                                                                        : Object.keys(
                                                                                                              paciente
                                                                                                                .Scorad[
                                                                                                                fecha
                                                                                                              ]
                                                                                                                .zonasAfectadas,
                                                                                                            )
                                                                                                              .length ==
                                                                                                              5 &&
                                                                                                            Object.keys(
                                                                                                              paciente
                                                                                                                .Scorad[
                                                                                                                fecha
                                                                                                              ]
                                                                                                                .zonasAfectadas,
                                                                                                            ).includes(
                                                                                                              "cadera",
                                                                                                            ) &&
                                                                                                            Object.keys(
                                                                                                              paciente
                                                                                                                .Scorad[
                                                                                                                fecha
                                                                                                              ]
                                                                                                                .zonasAfectadas,
                                                                                                            ).includes(
                                                                                                              "cabeza y cuello",
                                                                                                            ) &&
                                                                                                            Object.keys(
                                                                                                              paciente
                                                                                                                .Scorad[
                                                                                                                fecha
                                                                                                              ]
                                                                                                                .zonasAfectadas,
                                                                                                            ).includes(
                                                                                                              "brazo izquierdo",
                                                                                                            ) &&
                                                                                                            Object.keys(
                                                                                                              paciente
                                                                                                                .Scorad[
                                                                                                                fecha
                                                                                                              ]
                                                                                                                .zonasAfectadas,
                                                                                                            ).includes(
                                                                                                              "brazo derecho",
                                                                                                            ) &&
                                                                                                            Object.keys(
                                                                                                              paciente
                                                                                                                .Scorad[
                                                                                                                fecha
                                                                                                              ]
                                                                                                                .zonasAfectadas,
                                                                                                            ).includes(
                                                                                                              "pierna derecha",
                                                                                                            )
                                                                                                          ? require("../assets/bodyhombre/bodycabezacaderabrazoizqbrazoderpiernader.png")
                                                                                                          : Object.keys(
                                                                                                                paciente
                                                                                                                  .Scorad[
                                                                                                                  fecha
                                                                                                                ]
                                                                                                                  .zonasAfectadas,
                                                                                                              )
                                                                                                                .length ==
                                                                                                                5 &&
                                                                                                              Object.keys(
                                                                                                                paciente
                                                                                                                  .Scorad[
                                                                                                                  fecha
                                                                                                                ]
                                                                                                                  .zonasAfectadas,
                                                                                                              ).includes(
                                                                                                                "cadera",
                                                                                                              ) &&
                                                                                                              Object.keys(
                                                                                                                paciente
                                                                                                                  .Scorad[
                                                                                                                  fecha
                                                                                                                ]
                                                                                                                  .zonasAfectadas,
                                                                                                              ).includes(
                                                                                                                "cabeza y cuello",
                                                                                                              ) &&
                                                                                                              Object.keys(
                                                                                                                paciente
                                                                                                                  .Scorad[
                                                                                                                  fecha
                                                                                                                ]
                                                                                                                  .zonasAfectadas,
                                                                                                              ).includes(
                                                                                                                "brazo izquierdo",
                                                                                                              ) &&
                                                                                                              Object.keys(
                                                                                                                paciente
                                                                                                                  .Scorad[
                                                                                                                  fecha
                                                                                                                ]
                                                                                                                  .zonasAfectadas,
                                                                                                              ).includes(
                                                                                                                "brazo derecho",
                                                                                                              ) &&
                                                                                                              Object.keys(
                                                                                                                paciente
                                                                                                                  .Scorad[
                                                                                                                  fecha
                                                                                                                ]
                                                                                                                  .zonasAfectadas,
                                                                                                              ).includes(
                                                                                                                "pierna izquierda",
                                                                                                              )
                                                                                                            ? require("../assets/bodyhombre/bodycabezacaderabrazoizqbrazoderpiernaizq.png")
                                                                                                            : Object.keys(
                                                                                                                  paciente
                                                                                                                    .Scorad[
                                                                                                                    fecha
                                                                                                                  ]
                                                                                                                    .zonasAfectadas,
                                                                                                                )
                                                                                                                  .length ==
                                                                                                                  2 &&
                                                                                                                Object.keys(
                                                                                                                  paciente
                                                                                                                    .Scorad[
                                                                                                                    fecha
                                                                                                                  ]
                                                                                                                    .zonasAfectadas,
                                                                                                                ).includes(
                                                                                                                  "brazo derecho",
                                                                                                                ) &&
                                                                                                                Object.keys(
                                                                                                                  paciente
                                                                                                                    .Scorad[
                                                                                                                    fecha
                                                                                                                  ]
                                                                                                                    .zonasAfectadas,
                                                                                                                ).includes(
                                                                                                                  "pierna izquierda",
                                                                                                                )
                                                                                                              ? require("../assets/bodyhombre/bodybrazoderpiernaizq.png")
                                                                                                              : Object.keys(
                                                                                                                    paciente
                                                                                                                      .Scorad[
                                                                                                                      fecha
                                                                                                                    ]
                                                                                                                      .zonasAfectadas,
                                                                                                                  )
                                                                                                                    .length ==
                                                                                                                    2 &&
                                                                                                                  Object.keys(
                                                                                                                    paciente
                                                                                                                      .Scorad[
                                                                                                                      fecha
                                                                                                                    ]
                                                                                                                      .zonasAfectadas,
                                                                                                                  ).includes(
                                                                                                                    "brazo derecho",
                                                                                                                  ) &&
                                                                                                                  Object.keys(
                                                                                                                    paciente
                                                                                                                      .Scorad[
                                                                                                                      fecha
                                                                                                                    ]
                                                                                                                      .zonasAfectadas,
                                                                                                                  ).includes(
                                                                                                                    "pierna derecha",
                                                                                                                  )
                                                                                                                ? require("../assets/bodyhombre/bodybrazoderpiernader.png")
                                                                                                                : Object.keys(
                                                                                                                      paciente
                                                                                                                        .Scorad[
                                                                                                                        fecha
                                                                                                                      ]
                                                                                                                        .zonasAfectadas,
                                                                                                                    )
                                                                                                                      .length ==
                                                                                                                      2 &&
                                                                                                                    Object.keys(
                                                                                                                      paciente
                                                                                                                        .Scorad[
                                                                                                                        fecha
                                                                                                                      ]
                                                                                                                        .zonasAfectadas,
                                                                                                                    ).includes(
                                                                                                                      "brazo derecho",
                                                                                                                    ) &&
                                                                                                                    Object.keys(
                                                                                                                      paciente
                                                                                                                        .Scorad[
                                                                                                                        fecha
                                                                                                                      ]
                                                                                                                        .zonasAfectadas,
                                                                                                                    ).includes(
                                                                                                                      "cabeza y cuello",
                                                                                                                    )
                                                                                                                  ? require("../assets/bodyhombre/cabezabrazoder.png")
                                                                                                                  : Object.keys(
                                                                                                                        paciente
                                                                                                                          .Scorad[
                                                                                                                          fecha
                                                                                                                        ]
                                                                                                                          .zonasAfectadas,
                                                                                                                      )
                                                                                                                        .length ==
                                                                                                                        2 &&
                                                                                                                      Object.keys(
                                                                                                                        paciente
                                                                                                                          .Scorad[
                                                                                                                          fecha
                                                                                                                        ]
                                                                                                                          .zonasAfectadas,
                                                                                                                      ).includes(
                                                                                                                        "brazo izquierdo",
                                                                                                                      ) &&
                                                                                                                      Object.keys(
                                                                                                                        paciente
                                                                                                                          .Scorad[
                                                                                                                          fecha
                                                                                                                        ]
                                                                                                                          .zonasAfectadas,
                                                                                                                      ).includes(
                                                                                                                        "cabeza y cuello",
                                                                                                                      )
                                                                                                                    ? require("../assets/bodyhombre/cabezabrazoizq.png")
                                                                                                                    : Object.keys(
                                                                                                                          paciente
                                                                                                                            .Scorad[
                                                                                                                            fecha
                                                                                                                          ]
                                                                                                                            .zonasAfectadas,
                                                                                                                        )
                                                                                                                          .length ==
                                                                                                                          2 &&
                                                                                                                        Object.keys(
                                                                                                                          paciente
                                                                                                                            .Scorad[
                                                                                                                            fecha
                                                                                                                          ]
                                                                                                                            .zonasAfectadas,
                                                                                                                        ).includes(
                                                                                                                          "brazo izquierdo",
                                                                                                                        ) &&
                                                                                                                        Object.keys(
                                                                                                                          paciente
                                                                                                                            .Scorad[
                                                                                                                            fecha
                                                                                                                          ]
                                                                                                                            .zonasAfectadas,
                                                                                                                        ).includes(
                                                                                                                          "pierna izquierda",
                                                                                                                        )
                                                                                                                      ? require("../assets/bodyhombre/bodybrazoizqpiernaizq.png")
                                                                                                                      : Object.keys(
                                                                                                                            paciente
                                                                                                                              .Scorad[
                                                                                                                              fecha
                                                                                                                            ]
                                                                                                                              .zonasAfectadas,
                                                                                                                          )
                                                                                                                            .length ==
                                                                                                                            2 &&
                                                                                                                          Object.keys(
                                                                                                                            paciente
                                                                                                                              .Scorad[
                                                                                                                              fecha
                                                                                                                            ]
                                                                                                                              .zonasAfectadas,
                                                                                                                          ).includes(
                                                                                                                            "brazo izquierdo",
                                                                                                                          ) &&
                                                                                                                          Object.keys(
                                                                                                                            paciente
                                                                                                                              .Scorad[
                                                                                                                              fecha
                                                                                                                            ]
                                                                                                                              .zonasAfectadas,
                                                                                                                          ).includes(
                                                                                                                            "pierna derecha",
                                                                                                                          )
                                                                                                                        ? require("../assets/bodyhombre/bodybrazoizqpiernader.png")
                                                                                                                        : Object.keys(
                                                                                                                              paciente
                                                                                                                                .Scorad[
                                                                                                                                fecha
                                                                                                                              ]
                                                                                                                                .zonasAfectadas,
                                                                                                                            )
                                                                                                                              .length ==
                                                                                                                              2 &&
                                                                                                                            Object.keys(
                                                                                                                              paciente
                                                                                                                                .Scorad[
                                                                                                                                fecha
                                                                                                                              ]
                                                                                                                                .zonasAfectadas,
                                                                                                                            ).includes(
                                                                                                                              "brazo izquierdo",
                                                                                                                            ) &&
                                                                                                                            Object.keys(
                                                                                                                              paciente
                                                                                                                                .Scorad[
                                                                                                                                fecha
                                                                                                                              ]
                                                                                                                                .zonasAfectadas,
                                                                                                                            ).includes(
                                                                                                                              "cadera",
                                                                                                                            )
                                                                                                                          ? require("../assets/bodyhombre/pelvisbrazoizq.png")
                                                                                                                          : Object.keys(
                                                                                                                                paciente
                                                                                                                                  .Scorad[
                                                                                                                                  fecha
                                                                                                                                ]
                                                                                                                                  .zonasAfectadas,
                                                                                                                              )
                                                                                                                                .length ==
                                                                                                                                3 &&
                                                                                                                              Object.keys(
                                                                                                                                paciente
                                                                                                                                  .Scorad[
                                                                                                                                  fecha
                                                                                                                                ]
                                                                                                                                  .zonasAfectadas,
                                                                                                                              ).includes(
                                                                                                                                "brazo izquierdo",
                                                                                                                              ) &&
                                                                                                                              Object.keys(
                                                                                                                                paciente
                                                                                                                                  .Scorad[
                                                                                                                                  fecha
                                                                                                                                ]
                                                                                                                                  .zonasAfectadas,
                                                                                                                              ).includes(
                                                                                                                                "cadera",
                                                                                                                              ) &&
                                                                                                                              Object.keys(
                                                                                                                                paciente
                                                                                                                                  .Scorad[
                                                                                                                                  fecha
                                                                                                                                ]
                                                                                                                                  .zonasAfectadas,
                                                                                                                              ).includes(
                                                                                                                                "pierna derecha",
                                                                                                                              )
                                                                                                                            ? require("../assets/bodyhombre/pelvisbrazoizqpiernader.png")
                                                                                                                            : Object.keys(
                                                                                                                                  paciente
                                                                                                                                    .Scorad[
                                                                                                                                    fecha
                                                                                                                                  ]
                                                                                                                                    .zonasAfectadas,
                                                                                                                                )
                                                                                                                                  .length ==
                                                                                                                                  3 &&
                                                                                                                                Object.keys(
                                                                                                                                  paciente
                                                                                                                                    .Scorad[
                                                                                                                                    fecha
                                                                                                                                  ]
                                                                                                                                    .zonasAfectadas,
                                                                                                                                ).includes(
                                                                                                                                  "brazo izquierdo",
                                                                                                                                ) &&
                                                                                                                                Object.keys(
                                                                                                                                  paciente
                                                                                                                                    .Scorad[
                                                                                                                                    fecha
                                                                                                                                  ]
                                                                                                                                    .zonasAfectadas,
                                                                                                                                ).includes(
                                                                                                                                  "cadera",
                                                                                                                                ) &&
                                                                                                                                Object.keys(
                                                                                                                                  paciente
                                                                                                                                    .Scorad[
                                                                                                                                    fecha
                                                                                                                                  ]
                                                                                                                                    .zonasAfectadas,
                                                                                                                                ).includes(
                                                                                                                                  "pierna izquierda",
                                                                                                                                )
                                                                                                                              ? require("../assets/bodyhombre/pelvisbrazoizqpiernaizq.png")
                                                                                                                              : Object.keys(
                                                                                                                                    paciente
                                                                                                                                      .Scorad[
                                                                                                                                      fecha
                                                                                                                                    ]
                                                                                                                                      .zonasAfectadas,
                                                                                                                                  )
                                                                                                                                    .length ==
                                                                                                                                    4 &&
                                                                                                                                  Object.keys(
                                                                                                                                    paciente
                                                                                                                                      .Scorad[
                                                                                                                                      fecha
                                                                                                                                    ]
                                                                                                                                      .zonasAfectadas,
                                                                                                                                  ).includes(
                                                                                                                                    "brazo izquierdo",
                                                                                                                                  ) &&
                                                                                                                                  Object.keys(
                                                                                                                                    paciente
                                                                                                                                      .Scorad[
                                                                                                                                      fecha
                                                                                                                                    ]
                                                                                                                                      .zonasAfectadas,
                                                                                                                                  ).includes(
                                                                                                                                    "cadera",
                                                                                                                                  ) &&
                                                                                                                                  Object.keys(
                                                                                                                                    paciente
                                                                                                                                      .Scorad[
                                                                                                                                      fecha
                                                                                                                                    ]
                                                                                                                                      .zonasAfectadas,
                                                                                                                                  ).includes(
                                                                                                                                    "pierna izquierda",
                                                                                                                                  ) &&
                                                                                                                                  Object.keys(
                                                                                                                                    paciente
                                                                                                                                      .Scorad[
                                                                                                                                      fecha
                                                                                                                                    ]
                                                                                                                                      .zonasAfectadas,
                                                                                                                                  ).includes(
                                                                                                                                    "pierna derecha",
                                                                                                                                  )
                                                                                                                                ? require("../assets/bodyhombre/pelvisbrazoizqpiernas.png")
                                                                                                                                : Object.keys(
                                                                                                                                      paciente
                                                                                                                                        .Scorad[
                                                                                                                                        fecha
                                                                                                                                      ]
                                                                                                                                        .zonasAfectadas,
                                                                                                                                    )
                                                                                                                                      .length ==
                                                                                                                                      5 &&
                                                                                                                                    Object.keys(
                                                                                                                                      paciente
                                                                                                                                        .Scorad[
                                                                                                                                        fecha
                                                                                                                                      ]
                                                                                                                                        .zonasAfectadas,
                                                                                                                                    ).includes(
                                                                                                                                      "brazo izquierdo",
                                                                                                                                    ) &&
                                                                                                                                    Object.keys(
                                                                                                                                      paciente
                                                                                                                                        .Scorad[
                                                                                                                                        fecha
                                                                                                                                      ]
                                                                                                                                        .zonasAfectadas,
                                                                                                                                    ).includes(
                                                                                                                                      "cadera",
                                                                                                                                    ) &&
                                                                                                                                    Object.keys(
                                                                                                                                      paciente
                                                                                                                                        .Scorad[
                                                                                                                                        fecha
                                                                                                                                      ]
                                                                                                                                        .zonasAfectadas,
                                                                                                                                    ).includes(
                                                                                                                                      "pierna izquierda",
                                                                                                                                    ) &&
                                                                                                                                    Object.keys(
                                                                                                                                      paciente
                                                                                                                                        .Scorad[
                                                                                                                                        fecha
                                                                                                                                      ]
                                                                                                                                        .zonasAfectadas,
                                                                                                                                    ).includes(
                                                                                                                                      "pierna derecha",
                                                                                                                                    ) &&
                                                                                                                                    Object.keys(
                                                                                                                                      paciente
                                                                                                                                        .Scorad[
                                                                                                                                        fecha
                                                                                                                                      ]
                                                                                                                                        .zonasAfectadas,
                                                                                                                                    ).includes(
                                                                                                                                      "torso",
                                                                                                                                    )
                                                                                                                                  ? require("../assets/bodyhombre/pelvisbrazoizqpiernastorso.png")
                                                                                                                                  : Object.keys(
                                                                                                                                        paciente
                                                                                                                                          .Scorad[
                                                                                                                                          fecha
                                                                                                                                        ]
                                                                                                                                          .zonasAfectadas,
                                                                                                                                      )
                                                                                                                                        .length ==
                                                                                                                                        3 &&
                                                                                                                                      Object.keys(
                                                                                                                                        paciente
                                                                                                                                          .Scorad[
                                                                                                                                          fecha
                                                                                                                                        ]
                                                                                                                                          .zonasAfectadas,
                                                                                                                                      ).includes(
                                                                                                                                        "brazo izquierdo",
                                                                                                                                      ) &&
                                                                                                                                      Object.keys(
                                                                                                                                        paciente
                                                                                                                                          .Scorad[
                                                                                                                                          fecha
                                                                                                                                        ]
                                                                                                                                          .zonasAfectadas,
                                                                                                                                      ).includes(
                                                                                                                                        "pierna derecha",
                                                                                                                                      ) &&
                                                                                                                                      Object.keys(
                                                                                                                                        paciente
                                                                                                                                          .Scorad[
                                                                                                                                          fecha
                                                                                                                                        ]
                                                                                                                                          .zonasAfectadas,
                                                                                                                                      ).includes(
                                                                                                                                        "pierna izquierda",
                                                                                                                                      )
                                                                                                                                    ? require("../assets/bodyhombre/bodybrazoizqpiernas.png")
                                                                                                                                    : Object.keys(
                                                                                                                                          paciente
                                                                                                                                            .Scorad[
                                                                                                                                            fecha
                                                                                                                                          ]
                                                                                                                                            .zonasAfectadas,
                                                                                                                                        )
                                                                                                                                          .length ==
                                                                                                                                          3 &&
                                                                                                                                        Object.keys(
                                                                                                                                          paciente
                                                                                                                                            .Scorad[
                                                                                                                                            fecha
                                                                                                                                          ]
                                                                                                                                            .zonasAfectadas,
                                                                                                                                        ).includes(
                                                                                                                                          "brazo derecho",
                                                                                                                                        ) &&
                                                                                                                                        Object.keys(
                                                                                                                                          paciente
                                                                                                                                            .Scorad[
                                                                                                                                            fecha
                                                                                                                                          ]
                                                                                                                                            .zonasAfectadas,
                                                                                                                                        ).includes(
                                                                                                                                          "pierna derecha",
                                                                                                                                        ) &&
                                                                                                                                        Object.keys(
                                                                                                                                          paciente
                                                                                                                                            .Scorad[
                                                                                                                                            fecha
                                                                                                                                          ]
                                                                                                                                            .zonasAfectadas,
                                                                                                                                        ).includes(
                                                                                                                                          "pierna izquierda",
                                                                                                                                        )
                                                                                                                                      ? require("../assets/bodyhombre/bodybrazoderpiernas.png")
                                                                                                                                      : Object.keys(
                                                                                                                                            paciente
                                                                                                                                              .Scorad[
                                                                                                                                              fecha
                                                                                                                                            ]
                                                                                                                                              .zonasAfectadas,
                                                                                                                                          )
                                                                                                                                            .length ==
                                                                                                                                            3 &&
                                                                                                                                          Object.keys(
                                                                                                                                            paciente
                                                                                                                                              .Scorad[
                                                                                                                                              fecha
                                                                                                                                            ]
                                                                                                                                              .zonasAfectadas,
                                                                                                                                          ).includes(
                                                                                                                                            "brazo derecho",
                                                                                                                                          ) &&
                                                                                                                                          Object.keys(
                                                                                                                                            paciente
                                                                                                                                              .Scorad[
                                                                                                                                              fecha
                                                                                                                                            ]
                                                                                                                                              .zonasAfectadas,
                                                                                                                                          ).includes(
                                                                                                                                            "cadera",
                                                                                                                                          ) &&
                                                                                                                                          Object.keys(
                                                                                                                                            paciente
                                                                                                                                              .Scorad[
                                                                                                                                              fecha
                                                                                                                                            ]
                                                                                                                                              .zonasAfectadas,
                                                                                                                                          ).includes(
                                                                                                                                            "pierna derecha",
                                                                                                                                          )
                                                                                                                                        ? require("../assets/bodyhombre/pelvisbrazoderpiernader.png")
                                                                                                                                        : Object.keys(
                                                                                                                                              paciente
                                                                                                                                                .Scorad[
                                                                                                                                                fecha
                                                                                                                                              ]
                                                                                                                                                .zonasAfectadas,
                                                                                                                                            )
                                                                                                                                              .length ==
                                                                                                                                              3 &&
                                                                                                                                            Object.keys(
                                                                                                                                              paciente
                                                                                                                                                .Scorad[
                                                                                                                                                fecha
                                                                                                                                              ]
                                                                                                                                                .zonasAfectadas,
                                                                                                                                            ).includes(
                                                                                                                                              "brazo derecho",
                                                                                                                                            ) &&
                                                                                                                                            Object.keys(
                                                                                                                                              paciente
                                                                                                                                                .Scorad[
                                                                                                                                                fecha
                                                                                                                                              ]
                                                                                                                                                .zonasAfectadas,
                                                                                                                                            ).includes(
                                                                                                                                              "cadera",
                                                                                                                                            ) &&
                                                                                                                                            Object.keys(
                                                                                                                                              paciente
                                                                                                                                                .Scorad[
                                                                                                                                                fecha
                                                                                                                                              ]
                                                                                                                                                .zonasAfectadas,
                                                                                                                                            ).includes(
                                                                                                                                              "pierna izquierda",
                                                                                                                                            )
                                                                                                                                          ? require("../assets/bodyhombre/pelvisbrazoderpiernaizq.png")
                                                                                                                                          : Object.keys(
                                                                                                                                                paciente
                                                                                                                                                  .Scorad[
                                                                                                                                                  fecha
                                                                                                                                                ]
                                                                                                                                                  .zonasAfectadas,
                                                                                                                                              )
                                                                                                                                                .length ==
                                                                                                                                                4 &&
                                                                                                                                              Object.keys(
                                                                                                                                                paciente
                                                                                                                                                  .Scorad[
                                                                                                                                                  fecha
                                                                                                                                                ]
                                                                                                                                                  .zonasAfectadas,
                                                                                                                                              ).includes(
                                                                                                                                                "brazo derecho",
                                                                                                                                              ) &&
                                                                                                                                              Object.keys(
                                                                                                                                                paciente
                                                                                                                                                  .Scorad[
                                                                                                                                                  fecha
                                                                                                                                                ]
                                                                                                                                                  .zonasAfectadas,
                                                                                                                                              ).includes(
                                                                                                                                                "cadera",
                                                                                                                                              ) &&
                                                                                                                                              Object.keys(
                                                                                                                                                paciente
                                                                                                                                                  .Scorad[
                                                                                                                                                  fecha
                                                                                                                                                ]
                                                                                                                                                  .zonasAfectadas,
                                                                                                                                              ).includes(
                                                                                                                                                "pierna izquierda",
                                                                                                                                              ) &&
                                                                                                                                              Object.keys(
                                                                                                                                                paciente
                                                                                                                                                  .Scorad[
                                                                                                                                                  fecha
                                                                                                                                                ]
                                                                                                                                                  .zonasAfectadas,
                                                                                                                                              ).includes(
                                                                                                                                                "pierna derecha",
                                                                                                                                              )
                                                                                                                                            ? require("../assets/bodyhombre/pelvisbrazoderpiernas.png")
                                                                                                                                            : Object.keys(
                                                                                                                                                  paciente
                                                                                                                                                    .Scorad[
                                                                                                                                                    fecha
                                                                                                                                                  ]
                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                )
                                                                                                                                                  .length ==
                                                                                                                                                  5 &&
                                                                                                                                                Object.keys(
                                                                                                                                                  paciente
                                                                                                                                                    .Scorad[
                                                                                                                                                    fecha
                                                                                                                                                  ]
                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                ).includes(
                                                                                                                                                  "brazo derecho",
                                                                                                                                                ) &&
                                                                                                                                                Object.keys(
                                                                                                                                                  paciente
                                                                                                                                                    .Scorad[
                                                                                                                                                    fecha
                                                                                                                                                  ]
                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                ).includes(
                                                                                                                                                  "cadera",
                                                                                                                                                ) &&
                                                                                                                                                Object.keys(
                                                                                                                                                  paciente
                                                                                                                                                    .Scorad[
                                                                                                                                                    fecha
                                                                                                                                                  ]
                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                ).includes(
                                                                                                                                                  "pierna izquierda",
                                                                                                                                                ) &&
                                                                                                                                                Object.keys(
                                                                                                                                                  paciente
                                                                                                                                                    .Scorad[
                                                                                                                                                    fecha
                                                                                                                                                  ]
                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                ).includes(
                                                                                                                                                  "pierna derecha",
                                                                                                                                                ) &&
                                                                                                                                                Object.keys(
                                                                                                                                                  paciente
                                                                                                                                                    .Scorad[
                                                                                                                                                    fecha
                                                                                                                                                  ]
                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                ).includes(
                                                                                                                                                  "torso",
                                                                                                                                                )
                                                                                                                                              ? require("../assets/bodyhombre/pelvisbrazoderpiernastorso.png")
                                                                                                                                              : Object.keys(
                                                                                                                                                    paciente
                                                                                                                                                      .Scorad[
                                                                                                                                                      fecha
                                                                                                                                                    ]
                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                  )
                                                                                                                                                    .length ==
                                                                                                                                                    2 &&
                                                                                                                                                  Object.keys(
                                                                                                                                                    paciente
                                                                                                                                                      .Scorad[
                                                                                                                                                      fecha
                                                                                                                                                    ]
                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                  ).includes(
                                                                                                                                                    "brazo derecho",
                                                                                                                                                  ) &&
                                                                                                                                                  Object.keys(
                                                                                                                                                    paciente
                                                                                                                                                      .Scorad[
                                                                                                                                                      fecha
                                                                                                                                                    ]
                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                  ).includes(
                                                                                                                                                    "cadera",
                                                                                                                                                  )
                                                                                                                                                ? require("../assets/bodyhombre/pelvisbrazoder.png")
                                                                                                                                                : Object.keys(
                                                                                                                                                      paciente
                                                                                                                                                        .Scorad[
                                                                                                                                                        fecha
                                                                                                                                                      ]
                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                    )
                                                                                                                                                      .length ==
                                                                                                                                                      2 &&
                                                                                                                                                    Object.keys(
                                                                                                                                                      paciente
                                                                                                                                                        .Scorad[
                                                                                                                                                        fecha
                                                                                                                                                      ]
                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                    ).includes(
                                                                                                                                                      "torso",
                                                                                                                                                    ) &&
                                                                                                                                                    Object.keys(
                                                                                                                                                      paciente
                                                                                                                                                        .Scorad[
                                                                                                                                                        fecha
                                                                                                                                                      ]
                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                    ).includes(
                                                                                                                                                      "cadera",
                                                                                                                                                    )
                                                                                                                                                  ? require("../assets/bodyhombre/torsocadera.png")
                                                                                                                                                  : Object.keys(
                                                                                                                                                        paciente
                                                                                                                                                          .Scorad[
                                                                                                                                                          fecha
                                                                                                                                                        ]
                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                      )
                                                                                                                                                        .length ==
                                                                                                                                                        3 &&
                                                                                                                                                      Object.keys(
                                                                                                                                                        paciente
                                                                                                                                                          .Scorad[
                                                                                                                                                          fecha
                                                                                                                                                        ]
                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                      ).includes(
                                                                                                                                                        "torso",
                                                                                                                                                      ) &&
                                                                                                                                                      Object.keys(
                                                                                                                                                        paciente
                                                                                                                                                          .Scorad[
                                                                                                                                                          fecha
                                                                                                                                                        ]
                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                      ).includes(
                                                                                                                                                        "cadera",
                                                                                                                                                      ) &&
                                                                                                                                                      Object.keys(
                                                                                                                                                        paciente
                                                                                                                                                          .Scorad[
                                                                                                                                                          fecha
                                                                                                                                                        ]
                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                      ).includes(
                                                                                                                                                        "brazo derecho",
                                                                                                                                                      )
                                                                                                                                                    ? require("../assets/bodyhombre/torsocaderabrazoder.png")
                                                                                                                                                    : Object.keys(
                                                                                                                                                          paciente
                                                                                                                                                            .Scorad[
                                                                                                                                                            fecha
                                                                                                                                                          ]
                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                        )
                                                                                                                                                          .length ==
                                                                                                                                                          4 &&
                                                                                                                                                        Object.keys(
                                                                                                                                                          paciente
                                                                                                                                                            .Scorad[
                                                                                                                                                            fecha
                                                                                                                                                          ]
                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                        ).includes(
                                                                                                                                                          "torso",
                                                                                                                                                        ) &&
                                                                                                                                                        Object.keys(
                                                                                                                                                          paciente
                                                                                                                                                            .Scorad[
                                                                                                                                                            fecha
                                                                                                                                                          ]
                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                        ).includes(
                                                                                                                                                          "cadera",
                                                                                                                                                        ) &&
                                                                                                                                                        Object.keys(
                                                                                                                                                          paciente
                                                                                                                                                            .Scorad[
                                                                                                                                                            fecha
                                                                                                                                                          ]
                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                        ).includes(
                                                                                                                                                          "brazo derecho",
                                                                                                                                                        ) &&
                                                                                                                                                        Object.keys(
                                                                                                                                                          paciente
                                                                                                                                                            .Scorad[
                                                                                                                                                            fecha
                                                                                                                                                          ]
                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                        ).includes(
                                                                                                                                                          "pierna derecha",
                                                                                                                                                        )
                                                                                                                                                      ? require("../assets/bodyhombre/torsocaderabrazoderpiernader.png")
                                                                                                                                                      : Object.keys(
                                                                                                                                                            paciente
                                                                                                                                                              .Scorad[
                                                                                                                                                              fecha
                                                                                                                                                            ]
                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                          )
                                                                                                                                                            .length ==
                                                                                                                                                            4 &&
                                                                                                                                                          Object.keys(
                                                                                                                                                            paciente
                                                                                                                                                              .Scorad[
                                                                                                                                                              fecha
                                                                                                                                                            ]
                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                          ).includes(
                                                                                                                                                            "torso",
                                                                                                                                                          ) &&
                                                                                                                                                          Object.keys(
                                                                                                                                                            paciente
                                                                                                                                                              .Scorad[
                                                                                                                                                              fecha
                                                                                                                                                            ]
                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                          ).includes(
                                                                                                                                                            "cadera",
                                                                                                                                                          ) &&
                                                                                                                                                          Object.keys(
                                                                                                                                                            paciente
                                                                                                                                                              .Scorad[
                                                                                                                                                              fecha
                                                                                                                                                            ]
                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                          ).includes(
                                                                                                                                                            "brazo derecho",
                                                                                                                                                          ) &&
                                                                                                                                                          Object.keys(
                                                                                                                                                            paciente
                                                                                                                                                              .Scorad[
                                                                                                                                                              fecha
                                                                                                                                                            ]
                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                          ).includes(
                                                                                                                                                            "pierna izquierda",
                                                                                                                                                          )
                                                                                                                                                        ? require("../assets/bodyhombre/torsocaderabrazoderpiernaizq.png")
                                                                                                                                                        : Object.keys(
                                                                                                                                                              paciente
                                                                                                                                                                .Scorad[
                                                                                                                                                                fecha
                                                                                                                                                              ]
                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                            )
                                                                                                                                                              .length ==
                                                                                                                                                              3 &&
                                                                                                                                                            Object.keys(
                                                                                                                                                              paciente
                                                                                                                                                                .Scorad[
                                                                                                                                                                fecha
                                                                                                                                                              ]
                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                            ).includes(
                                                                                                                                                              "torso",
                                                                                                                                                            ) &&
                                                                                                                                                            Object.keys(
                                                                                                                                                              paciente
                                                                                                                                                                .Scorad[
                                                                                                                                                                fecha
                                                                                                                                                              ]
                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                            ).includes(
                                                                                                                                                              "cadera",
                                                                                                                                                            ) &&
                                                                                                                                                            Object.keys(
                                                                                                                                                              paciente
                                                                                                                                                                .Scorad[
                                                                                                                                                                fecha
                                                                                                                                                              ]
                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                            ).includes(
                                                                                                                                                              "brazo izquierdo",
                                                                                                                                                            )
                                                                                                                                                          ? require("../assets/bodyhombre/torsocaderabrazoizq.png")
                                                                                                                                                          : Object.keys(
                                                                                                                                                                paciente
                                                                                                                                                                  .Scorad[
                                                                                                                                                                  fecha
                                                                                                                                                                ]
                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                              )
                                                                                                                                                                .length ==
                                                                                                                                                                4 &&
                                                                                                                                                              Object.keys(
                                                                                                                                                                paciente
                                                                                                                                                                  .Scorad[
                                                                                                                                                                  fecha
                                                                                                                                                                ]
                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                              ).includes(
                                                                                                                                                                "torso",
                                                                                                                                                              ) &&
                                                                                                                                                              Object.keys(
                                                                                                                                                                paciente
                                                                                                                                                                  .Scorad[
                                                                                                                                                                  fecha
                                                                                                                                                                ]
                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                              ).includes(
                                                                                                                                                                "cadera",
                                                                                                                                                              ) &&
                                                                                                                                                              Object.keys(
                                                                                                                                                                paciente
                                                                                                                                                                  .Scorad[
                                                                                                                                                                  fecha
                                                                                                                                                                ]
                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                              ).includes(
                                                                                                                                                                "brazo izquierdo",
                                                                                                                                                              ) &&
                                                                                                                                                              Object.keys(
                                                                                                                                                                paciente
                                                                                                                                                                  .Scorad[
                                                                                                                                                                  fecha
                                                                                                                                                                ]
                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                              ).includes(
                                                                                                                                                                "pierna derecha",
                                                                                                                                                              )
                                                                                                                                                            ? require("../assets/bodyhombre/torsocaderabrazoizqpiernader.png")
                                                                                                                                                            : Object.keys(
                                                                                                                                                                  paciente
                                                                                                                                                                    .Scorad[
                                                                                                                                                                    fecha
                                                                                                                                                                  ]
                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                )
                                                                                                                                                                  .length ==
                                                                                                                                                                  4 &&
                                                                                                                                                                Object.keys(
                                                                                                                                                                  paciente
                                                                                                                                                                    .Scorad[
                                                                                                                                                                    fecha
                                                                                                                                                                  ]
                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                ).includes(
                                                                                                                                                                  "torso",
                                                                                                                                                                ) &&
                                                                                                                                                                Object.keys(
                                                                                                                                                                  paciente
                                                                                                                                                                    .Scorad[
                                                                                                                                                                    fecha
                                                                                                                                                                  ]
                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                ).includes(
                                                                                                                                                                  "cadera",
                                                                                                                                                                ) &&
                                                                                                                                                                Object.keys(
                                                                                                                                                                  paciente
                                                                                                                                                                    .Scorad[
                                                                                                                                                                    fecha
                                                                                                                                                                  ]
                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                ).includes(
                                                                                                                                                                  "brazo izquierdo",
                                                                                                                                                                ) &&
                                                                                                                                                                Object.keys(
                                                                                                                                                                  paciente
                                                                                                                                                                    .Scorad[
                                                                                                                                                                    fecha
                                                                                                                                                                  ]
                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                ).includes(
                                                                                                                                                                  "pierna izquierda",
                                                                                                                                                                )
                                                                                                                                                              ? require("../assets/bodyhombre/torsocaderabrazoizqpiernaizq.png")
                                                                                                                                                              : Object.keys(
                                                                                                                                                                    paciente
                                                                                                                                                                      .Scorad[
                                                                                                                                                                      fecha
                                                                                                                                                                    ]
                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                  )
                                                                                                                                                                    .length ==
                                                                                                                                                                    4 &&
                                                                                                                                                                  Object.keys(
                                                                                                                                                                    paciente
                                                                                                                                                                      .Scorad[
                                                                                                                                                                      fecha
                                                                                                                                                                    ]
                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                  ).includes(
                                                                                                                                                                    "torso",
                                                                                                                                                                  ) &&
                                                                                                                                                                  Object.keys(
                                                                                                                                                                    paciente
                                                                                                                                                                      .Scorad[
                                                                                                                                                                      fecha
                                                                                                                                                                    ]
                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                  ).includes(
                                                                                                                                                                    "cadera",
                                                                                                                                                                  ) &&
                                                                                                                                                                  Object.keys(
                                                                                                                                                                    paciente
                                                                                                                                                                      .Scorad[
                                                                                                                                                                      fecha
                                                                                                                                                                    ]
                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                  ).includes(
                                                                                                                                                                    "brazo izquierdo",
                                                                                                                                                                  ) &&
                                                                                                                                                                  Object.keys(
                                                                                                                                                                    paciente
                                                                                                                                                                      .Scorad[
                                                                                                                                                                      fecha
                                                                                                                                                                    ]
                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                  ).includes(
                                                                                                                                                                    "brazo derecho",
                                                                                                                                                                  )
                                                                                                                                                                ? require("../assets/bodyhombre/torsocaderabrazos.png")
                                                                                                                                                                : Object.keys(
                                                                                                                                                                      paciente
                                                                                                                                                                        .Scorad[
                                                                                                                                                                        fecha
                                                                                                                                                                      ]
                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                    )
                                                                                                                                                                      .length ==
                                                                                                                                                                      5 &&
                                                                                                                                                                    Object.keys(
                                                                                                                                                                      paciente
                                                                                                                                                                        .Scorad[
                                                                                                                                                                        fecha
                                                                                                                                                                      ]
                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                    ).includes(
                                                                                                                                                                      "torso",
                                                                                                                                                                    ) &&
                                                                                                                                                                    Object.keys(
                                                                                                                                                                      paciente
                                                                                                                                                                        .Scorad[
                                                                                                                                                                        fecha
                                                                                                                                                                      ]
                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                    ).includes(
                                                                                                                                                                      "cadera",
                                                                                                                                                                    ) &&
                                                                                                                                                                    Object.keys(
                                                                                                                                                                      paciente
                                                                                                                                                                        .Scorad[
                                                                                                                                                                        fecha
                                                                                                                                                                      ]
                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                    ).includes(
                                                                                                                                                                      "brazo izquierdo",
                                                                                                                                                                    ) &&
                                                                                                                                                                    Object.keys(
                                                                                                                                                                      paciente
                                                                                                                                                                        .Scorad[
                                                                                                                                                                        fecha
                                                                                                                                                                      ]
                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                    ).includes(
                                                                                                                                                                      "brazo derecho",
                                                                                                                                                                    ) &&
                                                                                                                                                                    Object.keys(
                                                                                                                                                                      paciente
                                                                                                                                                                        .Scorad[
                                                                                                                                                                        fecha
                                                                                                                                                                      ]
                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                    ).includes(
                                                                                                                                                                      "pierna derecha",
                                                                                                                                                                    )
                                                                                                                                                                  ? require("../assets/bodyhombre/torsocaderabrazospiernader.png")
                                                                                                                                                                  : Object.keys(
                                                                                                                                                                        paciente
                                                                                                                                                                          .Scorad[
                                                                                                                                                                          fecha
                                                                                                                                                                        ]
                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                      )
                                                                                                                                                                        .length ==
                                                                                                                                                                        5 &&
                                                                                                                                                                      Object.keys(
                                                                                                                                                                        paciente
                                                                                                                                                                          .Scorad[
                                                                                                                                                                          fecha
                                                                                                                                                                        ]
                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                      ).includes(
                                                                                                                                                                        "torso",
                                                                                                                                                                      ) &&
                                                                                                                                                                      Object.keys(
                                                                                                                                                                        paciente
                                                                                                                                                                          .Scorad[
                                                                                                                                                                          fecha
                                                                                                                                                                        ]
                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                      ).includes(
                                                                                                                                                                        "cadera",
                                                                                                                                                                      ) &&
                                                                                                                                                                      Object.keys(
                                                                                                                                                                        paciente
                                                                                                                                                                          .Scorad[
                                                                                                                                                                          fecha
                                                                                                                                                                        ]
                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                      ).includes(
                                                                                                                                                                        "brazo izquierdo",
                                                                                                                                                                      ) &&
                                                                                                                                                                      Object.keys(
                                                                                                                                                                        paciente
                                                                                                                                                                          .Scorad[
                                                                                                                                                                          fecha
                                                                                                                                                                        ]
                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                      ).includes(
                                                                                                                                                                        "brazo derecho",
                                                                                                                                                                      ) &&
                                                                                                                                                                      Object.keys(
                                                                                                                                                                        paciente
                                                                                                                                                                          .Scorad[
                                                                                                                                                                          fecha
                                                                                                                                                                        ]
                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                      ).includes(
                                                                                                                                                                        "pierna izquierda",
                                                                                                                                                                      )
                                                                                                                                                                    ? require("../assets/bodyhombre/torsocaderabrazospiernaizq.png")
                                                                                                                                                                    : Object.keys(
                                                                                                                                                                          paciente
                                                                                                                                                                            .Scorad[
                                                                                                                                                                            fecha
                                                                                                                                                                          ]
                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                        )
                                                                                                                                                                          .length ==
                                                                                                                                                                          6 &&
                                                                                                                                                                        Object.keys(
                                                                                                                                                                          paciente
                                                                                                                                                                            .Scorad[
                                                                                                                                                                            fecha
                                                                                                                                                                          ]
                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                        ).includes(
                                                                                                                                                                          "torso",
                                                                                                                                                                        ) &&
                                                                                                                                                                        Object.keys(
                                                                                                                                                                          paciente
                                                                                                                                                                            .Scorad[
                                                                                                                                                                            fecha
                                                                                                                                                                          ]
                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                        ).includes(
                                                                                                                                                                          "cadera",
                                                                                                                                                                        ) &&
                                                                                                                                                                        Object.keys(
                                                                                                                                                                          paciente
                                                                                                                                                                            .Scorad[
                                                                                                                                                                            fecha
                                                                                                                                                                          ]
                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                        ).includes(
                                                                                                                                                                          "brazo izquierdo",
                                                                                                                                                                        ) &&
                                                                                                                                                                        Object.keys(
                                                                                                                                                                          paciente
                                                                                                                                                                            .Scorad[
                                                                                                                                                                            fecha
                                                                                                                                                                          ]
                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                        ).includes(
                                                                                                                                                                          "brazo derecho",
                                                                                                                                                                        ) &&
                                                                                                                                                                        Object.keys(
                                                                                                                                                                          paciente
                                                                                                                                                                            .Scorad[
                                                                                                                                                                            fecha
                                                                                                                                                                          ]
                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                        ).includes(
                                                                                                                                                                          "pierna izquierda",
                                                                                                                                                                        ) &&
                                                                                                                                                                        Object.keys(
                                                                                                                                                                          paciente
                                                                                                                                                                            .Scorad[
                                                                                                                                                                            fecha
                                                                                                                                                                          ]
                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                        ).includes(
                                                                                                                                                                          "pierna derecha",
                                                                                                                                                                        )
                                                                                                                                                                      ? require("../assets/bodyhombre/torsocaderabrazospiernas.png")
                                                                                                                                                                      : Object.keys(
                                                                                                                                                                            paciente
                                                                                                                                                                              .Scorad[
                                                                                                                                                                              fecha
                                                                                                                                                                            ]
                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                          )
                                                                                                                                                                            .length ==
                                                                                                                                                                            3 &&
                                                                                                                                                                          Object.keys(
                                                                                                                                                                            paciente
                                                                                                                                                                              .Scorad[
                                                                                                                                                                              fecha
                                                                                                                                                                            ]
                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                          ).includes(
                                                                                                                                                                            "torso",
                                                                                                                                                                          ) &&
                                                                                                                                                                          Object.keys(
                                                                                                                                                                            paciente
                                                                                                                                                                              .Scorad[
                                                                                                                                                                              fecha
                                                                                                                                                                            ]
                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                          ).includes(
                                                                                                                                                                            "cadera",
                                                                                                                                                                          ) &&
                                                                                                                                                                          Object.keys(
                                                                                                                                                                            paciente
                                                                                                                                                                              .Scorad[
                                                                                                                                                                              fecha
                                                                                                                                                                            ]
                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                          ).includes(
                                                                                                                                                                            "pierna derecha",
                                                                                                                                                                          )
                                                                                                                                                                        ? require("../assets/bodyhombre/torsocaderapiernader.png")
                                                                                                                                                                        : Object.keys(
                                                                                                                                                                              paciente
                                                                                                                                                                                .Scorad[
                                                                                                                                                                                fecha
                                                                                                                                                                              ]
                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                            )
                                                                                                                                                                              .length ==
                                                                                                                                                                              3 &&
                                                                                                                                                                            Object.keys(
                                                                                                                                                                              paciente
                                                                                                                                                                                .Scorad[
                                                                                                                                                                                fecha
                                                                                                                                                                              ]
                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                            ).includes(
                                                                                                                                                                              "torso",
                                                                                                                                                                            ) &&
                                                                                                                                                                            Object.keys(
                                                                                                                                                                              paciente
                                                                                                                                                                                .Scorad[
                                                                                                                                                                                fecha
                                                                                                                                                                              ]
                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                            ).includes(
                                                                                                                                                                              "cadera",
                                                                                                                                                                            ) &&
                                                                                                                                                                            Object.keys(
                                                                                                                                                                              paciente
                                                                                                                                                                                .Scorad[
                                                                                                                                                                                fecha
                                                                                                                                                                              ]
                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                            ).includes(
                                                                                                                                                                              "pierna izquierda",
                                                                                                                                                                            )
                                                                                                                                                                          ? require("../assets/bodyhombre/torsocaderapiernaizq.png")
                                                                                                                                                                          : Object.keys(
                                                                                                                                                                                paciente
                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                  fecha
                                                                                                                                                                                ]
                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                              )
                                                                                                                                                                                .length ==
                                                                                                                                                                                4 &&
                                                                                                                                                                              Object.keys(
                                                                                                                                                                                paciente
                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                  fecha
                                                                                                                                                                                ]
                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                              ).includes(
                                                                                                                                                                                "torso",
                                                                                                                                                                              ) &&
                                                                                                                                                                              Object.keys(
                                                                                                                                                                                paciente
                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                  fecha
                                                                                                                                                                                ]
                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                              ).includes(
                                                                                                                                                                                "cadera",
                                                                                                                                                                              ) &&
                                                                                                                                                                              Object.keys(
                                                                                                                                                                                paciente
                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                  fecha
                                                                                                                                                                                ]
                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                              ).includes(
                                                                                                                                                                                "pierna izquierda",
                                                                                                                                                                              ) &&
                                                                                                                                                                              Object.keys(
                                                                                                                                                                                paciente
                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                  fecha
                                                                                                                                                                                ]
                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                              ).includes(
                                                                                                                                                                                "pierna derecha",
                                                                                                                                                                              )
                                                                                                                                                                            ? require("../assets/bodyhombre/torsocaderapiernas.png")
                                                                                                                                                                            : Object.keys(
                                                                                                                                                                                  paciente
                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                    fecha
                                                                                                                                                                                  ]
                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                )
                                                                                                                                                                                  .length ==
                                                                                                                                                                                  2 &&
                                                                                                                                                                                Object.keys(
                                                                                                                                                                                  paciente
                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                    fecha
                                                                                                                                                                                  ]
                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                ).includes(
                                                                                                                                                                                  "brazo derecho",
                                                                                                                                                                                ) &&
                                                                                                                                                                                Object.keys(
                                                                                                                                                                                  paciente
                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                    fecha
                                                                                                                                                                                  ]
                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                ).includes(
                                                                                                                                                                                  "torso",
                                                                                                                                                                                )
                                                                                                                                                                              ? require("../assets/bodyhombre/torsobrazoder.png")
                                                                                                                                                                              : Object.keys(
                                                                                                                                                                                    paciente
                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                      fecha
                                                                                                                                                                                    ]
                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                  )
                                                                                                                                                                                    .length ==
                                                                                                                                                                                    2 &&
                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                    paciente
                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                      fecha
                                                                                                                                                                                    ]
                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                  ).includes(
                                                                                                                                                                                    "pierna derecha",
                                                                                                                                                                                  ) &&
                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                    paciente
                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                      fecha
                                                                                                                                                                                    ]
                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                  ).includes(
                                                                                                                                                                                    "torso",
                                                                                                                                                                                  )
                                                                                                                                                                                ? require("../assets/bodyhombre/torsopiernader.png")
                                                                                                                                                                                : Object.keys(
                                                                                                                                                                                      paciente
                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                        fecha
                                                                                                                                                                                      ]
                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                    )
                                                                                                                                                                                      .length ==
                                                                                                                                                                                      2 &&
                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                      paciente
                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                        fecha
                                                                                                                                                                                      ]
                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                    ).includes(
                                                                                                                                                                                      "pierna izquierda",
                                                                                                                                                                                    ) &&
                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                      paciente
                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                        fecha
                                                                                                                                                                                      ]
                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                    ).includes(
                                                                                                                                                                                      "torso",
                                                                                                                                                                                    )
                                                                                                                                                                                  ? require("../assets/bodyhombre/torsopiernaizq.png")
                                                                                                                                                                                  : Object.keys(
                                                                                                                                                                                        paciente
                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                          fecha
                                                                                                                                                                                        ]
                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                      )
                                                                                                                                                                                        .length ==
                                                                                                                                                                                        3 &&
                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                        paciente
                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                          fecha
                                                                                                                                                                                        ]
                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                      ).includes(
                                                                                                                                                                                        "pierna izquierda",
                                                                                                                                                                                      ) &&
                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                        paciente
                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                          fecha
                                                                                                                                                                                        ]
                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                      ).includes(
                                                                                                                                                                                        "pierna derecha",
                                                                                                                                                                                      ) &&
                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                        paciente
                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                          fecha
                                                                                                                                                                                        ]
                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                      ).includes(
                                                                                                                                                                                        "torso",
                                                                                                                                                                                      )
                                                                                                                                                                                    ? require("../assets/bodyhombre/torsopiernas.png")
                                                                                                                                                                                    : Object.keys(
                                                                                                                                                                                          paciente
                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                            fecha
                                                                                                                                                                                          ]
                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                        )
                                                                                                                                                                                          .length ==
                                                                                                                                                                                          3 &&
                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                          paciente
                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                            fecha
                                                                                                                                                                                          ]
                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                        ).includes(
                                                                                                                                                                                          "brazo derecho",
                                                                                                                                                                                        ) &&
                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                          paciente
                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                            fecha
                                                                                                                                                                                          ]
                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                        ).includes(
                                                                                                                                                                                          "torso",
                                                                                                                                                                                        ) &&
                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                          paciente
                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                            fecha
                                                                                                                                                                                          ]
                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                        ).includes(
                                                                                                                                                                                          "pierna derecha",
                                                                                                                                                                                        )
                                                                                                                                                                                      ? require("../assets/bodyhombre/torsobrazoderpiernader.png")
                                                                                                                                                                                      : Object.keys(
                                                                                                                                                                                            paciente
                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                              fecha
                                                                                                                                                                                            ]
                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                          )
                                                                                                                                                                                            .length ==
                                                                                                                                                                                            3 &&
                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                            paciente
                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                              fecha
                                                                                                                                                                                            ]
                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                          ).includes(
                                                                                                                                                                                            "brazo derecho",
                                                                                                                                                                                          ) &&
                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                            paciente
                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                              fecha
                                                                                                                                                                                            ]
                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                          ).includes(
                                                                                                                                                                                            "torso",
                                                                                                                                                                                          ) &&
                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                            paciente
                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                              fecha
                                                                                                                                                                                            ]
                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                          ).includes(
                                                                                                                                                                                            "pierna izquierda",
                                                                                                                                                                                          )
                                                                                                                                                                                        ? require("../assets/bodyhombre/torsobrazoderpiernaizq.png")
                                                                                                                                                                                        : Object.keys(
                                                                                                                                                                                              paciente
                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                fecha
                                                                                                                                                                                              ]
                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                            )
                                                                                                                                                                                              .length ==
                                                                                                                                                                                              4 &&
                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                              paciente
                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                fecha
                                                                                                                                                                                              ]
                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                            ).includes(
                                                                                                                                                                                              "brazo derecho",
                                                                                                                                                                                            ) &&
                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                              paciente
                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                fecha
                                                                                                                                                                                              ]
                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                            ).includes(
                                                                                                                                                                                              "torso",
                                                                                                                                                                                            ) &&
                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                              paciente
                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                fecha
                                                                                                                                                                                              ]
                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                            ).includes(
                                                                                                                                                                                              "pierna derecha",
                                                                                                                                                                                            ) &&
                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                              paciente
                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                fecha
                                                                                                                                                                                              ]
                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                            ).includes(
                                                                                                                                                                                              "pierna izquierda",
                                                                                                                                                                                            )
                                                                                                                                                                                          ? require("../assets/bodyhombre/torsobrazoderpiernas.png")
                                                                                                                                                                                          : Object.keys(
                                                                                                                                                                                                paciente
                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                  fecha
                                                                                                                                                                                                ]
                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                              )
                                                                                                                                                                                                .length ==
                                                                                                                                                                                                2 &&
                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                paciente
                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                  fecha
                                                                                                                                                                                                ]
                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                "brazo izquierdo",
                                                                                                                                                                                              ) &&
                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                paciente
                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                  fecha
                                                                                                                                                                                                ]
                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                "torso",
                                                                                                                                                                                              )
                                                                                                                                                                                            ? require("../assets/bodyhombre/torsobrazoizq.png")
                                                                                                                                                                                            : Object.keys(
                                                                                                                                                                                                  paciente
                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                    fecha
                                                                                                                                                                                                  ]
                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                )
                                                                                                                                                                                                  .length ==
                                                                                                                                                                                                  3 &&
                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                  paciente
                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                    fecha
                                                                                                                                                                                                  ]
                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                  "brazo izquierdo",
                                                                                                                                                                                                ) &&
                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                  paciente
                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                    fecha
                                                                                                                                                                                                  ]
                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                  "torso",
                                                                                                                                                                                                ) &&
                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                  paciente
                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                    fecha
                                                                                                                                                                                                  ]
                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                  "pierna derecha",
                                                                                                                                                                                                )
                                                                                                                                                                                              ? require("../assets/bodyhombre/torsobrazoizqpiernader.png")
                                                                                                                                                                                              : Object.keys(
                                                                                                                                                                                                    paciente
                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                      fecha
                                                                                                                                                                                                    ]
                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                  )
                                                                                                                                                                                                    .length ==
                                                                                                                                                                                                    3 &&
                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                    paciente
                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                      fecha
                                                                                                                                                                                                    ]
                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                    "brazo izquierdo",
                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                    paciente
                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                      fecha
                                                                                                                                                                                                    ]
                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                    "torso",
                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                    paciente
                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                      fecha
                                                                                                                                                                                                    ]
                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                    "pierna izquierda",
                                                                                                                                                                                                  )
                                                                                                                                                                                                ? require("../assets/bodyhombre/torsobrazoizqpiernaizq.png")
                                                                                                                                                                                                : Object.keys(
                                                                                                                                                                                                      paciente
                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                        fecha
                                                                                                                                                                                                      ]
                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                    )
                                                                                                                                                                                                      .length ==
                                                                                                                                                                                                      4 &&
                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                      paciente
                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                        fecha
                                                                                                                                                                                                      ]
                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                      "brazo izquierdo",
                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                      paciente
                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                        fecha
                                                                                                                                                                                                      ]
                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                      "torso",
                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                      paciente
                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                        fecha
                                                                                                                                                                                                      ]
                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                      "pierna derecha",
                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                      paciente
                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                        fecha
                                                                                                                                                                                                      ]
                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                      "pierna izquierda",
                                                                                                                                                                                                    )
                                                                                                                                                                                                  ? require("../assets/bodyhombre/torsobrazoizqpiernas.png")
                                                                                                                                                                                                  : Object.keys(
                                                                                                                                                                                                        paciente
                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                          fecha
                                                                                                                                                                                                        ]
                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                      )
                                                                                                                                                                                                        .length ==
                                                                                                                                                                                                        2 &&
                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                        paciente
                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                          fecha
                                                                                                                                                                                                        ]
                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                        "brazo izquierdo",
                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                        paciente
                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                          fecha
                                                                                                                                                                                                        ]
                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                        "brazo derecho",
                                                                                                                                                                                                      )
                                                                                                                                                                                                    ? require("../assets/bodyhombre/brazos.png")
                                                                                                                                                                                                    : Object.keys(
                                                                                                                                                                                                          paciente
                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                            fecha
                                                                                                                                                                                                          ]
                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                        )
                                                                                                                                                                                                          .length ==
                                                                                                                                                                                                          3 &&
                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                          paciente
                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                            fecha
                                                                                                                                                                                                          ]
                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                          "brazo izquierdo",
                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                          paciente
                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                            fecha
                                                                                                                                                                                                          ]
                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                          "cabeza y cuello",
                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                          paciente
                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                            fecha
                                                                                                                                                                                                          ]
                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                          "brazo derecho",
                                                                                                                                                                                                        )
                                                                                                                                                                                                      ? require("../assets/bodyhombre/cabezabrazos.png")
                                                                                                                                                                                                      : Object.keys(
                                                                                                                                                                                                            paciente
                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                              fecha
                                                                                                                                                                                                            ]
                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                          )
                                                                                                                                                                                                            .length ==
                                                                                                                                                                                                            3 &&
                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                            paciente
                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                              fecha
                                                                                                                                                                                                            ]
                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                            "brazo izquierdo",
                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                            paciente
                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                              fecha
                                                                                                                                                                                                            ]
                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                            "torso",
                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                            paciente
                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                              fecha
                                                                                                                                                                                                            ]
                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                            "brazo derecho",
                                                                                                                                                                                                          )
                                                                                                                                                                                                        ? require("../assets/bodyhombre/brazostorso.png")
                                                                                                                                                                                                        : Object.keys(
                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                fecha
                                                                                                                                                                                                              ]
                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                            )
                                                                                                                                                                                                              .length ==
                                                                                                                                                                                                              4 &&
                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                fecha
                                                                                                                                                                                                              ]
                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                              "brazo izquierdo",
                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                fecha
                                                                                                                                                                                                              ]
                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                              "torso",
                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                fecha
                                                                                                                                                                                                              ]
                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                              "brazo derecho",
                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                fecha
                                                                                                                                                                                                              ]
                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                              "pierna izquierda",
                                                                                                                                                                                                            )
                                                                                                                                                                                                          ? require("../assets/bodyhombre/brazostorsopiernaizq.png")
                                                                                                                                                                                                          : Object.keys(
                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                ]
                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                              )
                                                                                                                                                                                                                .length ==
                                                                                                                                                                                                                4 &&
                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                ]
                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                "brazo izquierdo",
                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                ]
                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                "torso",
                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                ]
                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                "brazo derecho",
                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                ]
                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                "pierna derecha",
                                                                                                                                                                                                              )
                                                                                                                                                                                                            ? require("../assets/bodyhombre/brazostorsopiernader.png")
                                                                                                                                                                                                            : Object.keys(
                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                )
                                                                                                                                                                                                                  .length ==
                                                                                                                                                                                                                  5 &&
                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                  "brazo izquierdo",
                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                  "torso",
                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                  "brazo derecho",
                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                  "pierna derecha",
                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                  "pierna izquierda",
                                                                                                                                                                                                                )
                                                                                                                                                                                                              ? require("../assets/bodyhombre/brazostorsopiernas.png")
                                                                                                                                                                                                              : Object.keys(
                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                  )
                                                                                                                                                                                                                    .length ==
                                                                                                                                                                                                                    6 &&
                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                    "brazo izquierdo",
                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                    "torso",
                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                    "brazo derecho",
                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                    "pierna derecha",
                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                    "pierna izquierda",
                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                    "cabeza y cuello",
                                                                                                                                                                                                                  )
                                                                                                                                                                                                                ? require("../assets/bodyhombre/menoscadera.png")
                                                                                                                                                                                                                : Object.keys(
                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                    )
                                                                                                                                                                                                                      .length ==
                                                                                                                                                                                                                      3 &&
                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                      "brazo izquierdo",
                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                      "cadera",
                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                      "brazo derecho",
                                                                                                                                                                                                                    )
                                                                                                                                                                                                                  ? require("../assets/bodyhombre/brazospelvis.png")
                                                                                                                                                                                                                  : Object.keys(
                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                      )
                                                                                                                                                                                                                        .length ==
                                                                                                                                                                                                                        4 &&
                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                        "brazo izquierdo",
                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                        "cadera",
                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                        "brazo derecho",
                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                        "pierna derecha",
                                                                                                                                                                                                                      )
                                                                                                                                                                                                                    ? require("../assets/bodyhombre/brazospelvispiernader.png")
                                                                                                                                                                                                                    : Object.keys(
                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                        )
                                                                                                                                                                                                                          .length ==
                                                                                                                                                                                                                          4 &&
                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                          "brazo izquierdo",
                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                          "cadera",
                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                          "brazo derecho",
                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                          "pierna izquierda",
                                                                                                                                                                                                                        )
                                                                                                                                                                                                                      ? require("../assets/bodyhombre/brazospelvispiernaizq.png")
                                                                                                                                                                                                                      : Object.keys(
                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                          )
                                                                                                                                                                                                                            .length ==
                                                                                                                                                                                                                            5 &&
                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                            "brazo izquierdo",
                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                            "cadera",
                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                            "brazo derecho",
                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                            "pierna izquierda",
                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                            "pierna derecha",
                                                                                                                                                                                                                          )
                                                                                                                                                                                                                        ? require("../assets/bodyhombre/brazospelvispiernas.png")
                                                                                                                                                                                                                        : Object.keys(
                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                            )
                                                                                                                                                                                                                              .length ==
                                                                                                                                                                                                                              3 &&
                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                              "brazo izquierdo",
                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                              "pierna izquierda",
                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                              "brazo derecho",
                                                                                                                                                                                                                            )
                                                                                                                                                                                                                          ? require("../assets/bodyhombre/brazospiernaizq.png")
                                                                                                                                                                                                                          : Object.keys(
                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                .length ==
                                                                                                                                                                                                                                3 &&
                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                "brazo izquierdo",
                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                "pierna derecha",
                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                "brazo derecho",
                                                                                                                                                                                                                              )
                                                                                                                                                                                                                            ? require("../assets/bodyhombre/brazospiernader.png")
                                                                                                                                                                                                                            : Object.keys(
                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                  .length ==
                                                                                                                                                                                                                                  3 &&
                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                  "torso",
                                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                  "cabeza y cuello",
                                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                  "cadera",
                                                                                                                                                                                                                                )
                                                                                                                                                                                                                              ? require("../assets/bodyhombre/bodycabezatorsocadera.png")
                                                                                                                                                                                                                              : Object.keys(
                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                    .length ==
                                                                                                                                                                                                                                    4 &&
                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                    "torso",
                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                    "cabeza y cuello",
                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                    "cadera",
                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                    "pierna derecha",
                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                ? require("../assets/bodyhombre/bodycabezatorsocaderapiernader.png")
                                                                                                                                                                                                                                : Object.keys(
                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                      .length ==
                                                                                                                                                                                                                                      4 &&
                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                      "torso",
                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                      "cabeza y cuello",
                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                      "cadera",
                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                      "pierna izquierda",
                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                  ? require("../assets/bodyhombre/bodycabezatorsocaderapiernaizq.png")
                                                                                                                                                                                                                                  : Object.keys(
                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                        .length ==
                                                                                                                                                                                                                                        5 &&
                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                        "torso",
                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                        "cabeza y cuello",
                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                        "cadera",
                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                        "pierna izquierda",
                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                        "pierna derecha",
                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                    ? require("../assets/bodyhombre/bodycabezatorsocaderapiernas.png")
                                                                                                                                                                                                                                    : Object.keys(
                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                          .length ==
                                                                                                                                                                                                                                          3 &&
                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                          "torso",
                                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                          "cabeza y cuello",
                                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                          "brazo izquierdo",
                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                      ? require("../assets/bodyhombre/bodycabezatorsobrazoizq.png")
                                                                                                                                                                                                                                      : Object.keys(
                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                            .length ==
                                                                                                                                                                                                                                            3 &&
                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                            "torso",
                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                            "cabeza y cuello",
                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                            "pierna izquierda",
                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                        ? require("../assets/bodyhombre/bodycabezatorsopiernaizq.png")
                                                                                                                                                                                                                                        : Object.keys(
                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                              .length ==
                                                                                                                                                                                                                                              3 &&
                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                              "torso",
                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                              "cabeza y cuello",
                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                              "pierna derecha",
                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                          ? require("../assets/bodyhombre/bodycabezatorsopiernader.png")
                                                                                                                                                                                                                                          : Object.keys(
                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                .length ==
                                                                                                                                                                                                                                                4 &&
                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                "torso",
                                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                "cabeza y cuello",
                                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                "pierna derecha",
                                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                "pierna izquierda",
                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                            ? require("../assets/bodyhombre/bodycabezatorsopiernas.png")
                                                                                                                                                                                                                                            : Object.keys(
                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                  .length ==
                                                                                                                                                                                                                                                  4 &&
                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                  "torso",
                                                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                  "cabeza y cuello",
                                                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                  "brazo izquierdo",
                                                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                  "pierna izquierda",
                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                              ? require("../assets/bodyhombre/bodycabezatorsobrazoizqpiernaizq.png")
                                                                                                                                                                                                                                              : Object.keys(
                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                    .length ==
                                                                                                                                                                                                                                                    4 &&
                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                    "torso",
                                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                    "cabeza y cuello",
                                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                    "brazo izquierdo",
                                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                    "pierna derecha",
                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                ? require("../assets/bodyhombre/bodycabezatorsobrazoizqpiernader.png")
                                                                                                                                                                                                                                                : Object.keys(
                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                      .length ==
                                                                                                                                                                                                                                                      5 &&
                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                      "torso",
                                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                      "cabeza y cuello",
                                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                      "brazo izquierdo",
                                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                      "pierna derecha",
                                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                      "pierna izquierda",
                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                  ? require("../assets/bodyhombre/bodycabezatorsobrazoizqpiernas.png")
                                                                                                                                                                                                                                                  : Object.keys(
                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                        .length ==
                                                                                                                                                                                                                                                        3 &&
                                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                        "torso",
                                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                        "cabeza y cuello",
                                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                        "brazo derecho",
                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                    ? require("../assets/bodyhombre/bodycabezatorsobrazoder.png")
                                                                                                                                                                                                                                                    : Object.keys(
                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                          .length ==
                                                                                                                                                                                                                                                          4 &&
                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                          "torso",
                                                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                          "cabeza y cuello",
                                                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                          "brazo derecho",
                                                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                          "pierna derecha",
                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                      ? require("../assets/bodyhombre/bodycabezatorsobrazoderpiernader.png")
                                                                                                                                                                                                                                                      : Object.keys(
                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                            .length ==
                                                                                                                                                                                                                                                            4 &&
                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                            "torso",
                                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                            "cabeza y cuello",
                                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                            "brazo derecho",
                                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                            "pierna izquierda",
                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                        ? require("../assets/bodyhombre/bodycabezatorsobrazoderpiernaizq.png")
                                                                                                                                                                                                                                                        : Object.keys(
                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                              .length ==
                                                                                                                                                                                                                                                              5 &&
                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                              "torso",
                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                              "cabeza y cuello",
                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                              "brazo derecho",
                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                              "pierna izquierda",
                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                              "pierna derecha",
                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                          ? require("../assets/bodyhombre/bodycabezatorsobrazoderpiernas.png")
                                                                                                                                                                                                                                                          : Object.keys(
                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                .length ==
                                                                                                                                                                                                                                                                4 &&
                                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                                "brazo izquierdo",
                                                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                                "pierna derecha",
                                                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                                "pierna izquierda",
                                                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                                "brazo derecho",
                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                            ? require("../assets/bodyhombre/extremidades.png")
                                                                                                                                                                                                                                                            : Object.keys(
                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                  .length ==
                                                                                                                                                                                                                                                                  4 &&
                                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                                  "brazo izquierdo",
                                                                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                                  "cabeza y cuello",
                                                                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                                  "brazo derecho",
                                                                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                                  "pierna izquierda",
                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                              ? require("../assets/bodyhombre/cabezabrazospiernaizq.png")
                                                                                                                                                                                                                                                              : Object.keys(
                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                    .length ==
                                                                                                                                                                                                                                                                    4 &&
                                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                                    "brazo izquierdo",
                                                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                                    "cabeza y cuello",
                                                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                                    "brazo derecho",
                                                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                                    "pierna derecha",
                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                ? require("../assets/bodyhombre/cabezabrazospiernader.png")
                                                                                                                                                                                                                                                                : Object.keys(
                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                      .length ==
                                                                                                                                                                                                                                                                      4 &&
                                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                                      "torso",
                                                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                                      "cabeza y cuello",
                                                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                                      "brazo derecho",
                                                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                                      "brazo izquierdo",
                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                  ? require("../assets/bodyhombre/bodycabezatorsobrazos.png")
                                                                                                                                                                                                                                                                  : Object.keys(
                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                        .length ==
                                                                                                                                                                                                                                                                        4 &&
                                                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                                        "torso",
                                                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                                        "cabeza y cuello",
                                                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                                        "cadera",
                                                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                                        "brazo derecho",
                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                    ? require("../assets/bodyhombre/bodycabezatorsocaderabrazoder.png")
                                                                                                                                                                                                                                                                    : Object.keys(
                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                          .length ==
                                                                                                                                                                                                                                                                          4 &&
                                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                                          "torso",
                                                                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                                          "cabeza y cuello",
                                                                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                                          "cadera",
                                                                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                                          "brazo izquierdo",
                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                      ? require("../assets/bodyhombre/bodycabezatorsocaderabrazoizq.png")
                                                                                                                                                                                                                                                                      : Object.keys(
                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                            .length ==
                                                                                                                                                                                                                                                                            5 &&
                                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                                            "brazo izquierdo",
                                                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                                            "pierna derecha",
                                                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                                            "pierna izquierda",
                                                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                                            "brazo derecho",
                                                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                                            "torso",
                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                        ? require("../assets/bodyhombre/extremidadestorso.png")
                                                                                                                                                                                                                                                                        : Object.keys(
                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                              .length ==
                                                                                                                                                                                                                                                                              5 &&
                                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                                              "brazo izquierdo",
                                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                                              "pierna derecha",
                                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                                              "pierna izquierda",
                                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                                              "brazo derecho",
                                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                                              "cadera",
                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                          ? require("../assets/bodyhombre/extremidadescadera.png")
                                                                                                                                                                                                                                                                          : Object.keys(
                                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                .length ==
                                                                                                                                                                                                                                                                                5 &&
                                                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                                                "brazo izquierdo",
                                                                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                                                "cabeza y cuello",
                                                                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                                                "brazo derecho",
                                                                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                                                "pierna derecha",
                                                                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                                                "pierna izquierda",
                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                            ? require("../assets/bodyhombre/cabezabrazospiernas.png")
                                                                                                                                                                                                                                                                            : Object.keys(
                                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                  .length ==
                                                                                                                                                                                                                                                                                  5 &&
                                                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                                                  "torso",
                                                                                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                                                  "cabeza y cuello",
                                                                                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                                                  "brazo derecho",
                                                                                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                                                  "brazo izquierdo",
                                                                                                                                                                                                                                                                                ) &&
                                                                                                                                                                                                                                                                                Object.keys(
                                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                                                  "cadera",
                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                              ? require("../assets/bodyhombre/bodymenospiernas.png")
                                                                                                                                                                                                                                                                              : Object.keys(
                                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                    .length ==
                                                                                                                                                                                                                                                                                    5 &&
                                                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                                                    "torso",
                                                                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                                                    "cabeza y cuello",
                                                                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                                                    "cadera",
                                                                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                                                    "brazo derecho",
                                                                                                                                                                                                                                                                                  ) &&
                                                                                                                                                                                                                                                                                  Object.keys(
                                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                                                    "pierna izquierda",
                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                ? require("../assets/bodyhombre/bodycabezatorsocaderabrazodermanopiernaizq.png")
                                                                                                                                                                                                                                                                                : Object.keys(
                                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                      .length ==
                                                                                                                                                                                                                                                                                      5 &&
                                                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                                                      "torso",
                                                                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                                                      "cabeza y cuello",
                                                                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                                                      "cadera",
                                                                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                                                      "brazo derecho",
                                                                                                                                                                                                                                                                                    ) &&
                                                                                                                                                                                                                                                                                    Object.keys(
                                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                                                      "pierna derecha",
                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                  ? require("../assets/bodyhombre/bodycabezatorsocaderabrazoderqmanopiernader.png")
                                                                                                                                                                                                                                                                                  : Object.keys(
                                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                        .length ==
                                                                                                                                                                                                                                                                                        5 &&
                                                                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                                                        "torso",
                                                                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                                                        "cabeza y cuello",
                                                                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                                                        "cadera",
                                                                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                                                        "brazo izquierdo",
                                                                                                                                                                                                                                                                                      ) &&
                                                                                                                                                                                                                                                                                      Object.keys(
                                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                                                        "pierna derecha",
                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                    ? require("../assets/bodyhombre/bodycabezatorsocaderabrazoizqmanopiernader.png")
                                                                                                                                                                                                                                                                                    : Object.keys(
                                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                          .length ==
                                                                                                                                                                                                                                                                                          5 &&
                                                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                                                          "torso",
                                                                                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                                                          "cabeza y cuello",
                                                                                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                                                          "cadera",
                                                                                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                                                          "brazo izquierdo",
                                                                                                                                                                                                                                                                                        ) &&
                                                                                                                                                                                                                                                                                        Object.keys(
                                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                                                          "pierna izquierda",
                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                      ? require("../assets/bodyhombre/bodycabezatorsocaderabrazoizqmanopiernaizq.png")
                                                                                                                                                                                                                                                                                      : Object.keys(
                                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                            .length ==
                                                                                                                                                                                                                                                                                            6 &&
                                                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                                                            "torso",
                                                                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                                                            "cabeza y cuello",
                                                                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                                                            "brazo derecho",
                                                                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                                                            "brazo izquierdo",
                                                                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                                                            "cadera",
                                                                                                                                                                                                                                                                                          ) &&
                                                                                                                                                                                                                                                                                          Object.keys(
                                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                                                            "pierna izquierda",
                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                        ? require("../assets/bodyhombre/bodymenospiernader.png")
                                                                                                                                                                                                                                                                                        : Object.keys(
                                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                              .length ==
                                                                                                                                                                                                                                                                                              6 &&
                                                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                                                              "torso",
                                                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                                                              "cabeza y cuello",
                                                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                                                              "brazo derecho",
                                                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                                                              "brazo izquierdo",
                                                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                                                              "cadera",
                                                                                                                                                                                                                                                                                            ) &&
                                                                                                                                                                                                                                                                                            Object.keys(
                                                                                                                                                                                                                                                                                              paciente
                                                                                                                                                                                                                                                                                                .Scorad[
                                                                                                                                                                                                                                                                                                fecha
                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                .zonasAfectadas,
                                                                                                                                                                                                                                                                                            ).includes(
                                                                                                                                                                                                                                                                                              "pierna derecha",
                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                          ? require("../assets/bodyhombre/bodymenospiernaizq.png")
                                                                                                                                                                                                                                                                                          : Object.keys(
                                                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                .length ==
                                                                                                                                                                                                                                                                                                2 &&
                                                                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                                                                "pierna derecha",
                                                                                                                                                                                                                                                                                              ) &&
                                                                                                                                                                                                                                                                                              Object.keys(
                                                                                                                                                                                                                                                                                                paciente
                                                                                                                                                                                                                                                                                                  .Scorad[
                                                                                                                                                                                                                                                                                                  fecha
                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                  .zonasAfectadas,
                                                                                                                                                                                                                                                                                              ).includes(
                                                                                                                                                                                                                                                                                                "pierna izquierda",
                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                            ? require("../assets/bodyhombre/bodypiernas.png")
                                                                                                                                                                                                                                                                                            : Object.keys(
                                                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                  .length ==
                                                                                                                                                                                                                                                                                                  6 &&
                                                                                                                                                                                                                                                                                                !Object.keys(
                                                                                                                                                                                                                                                                                                  paciente
                                                                                                                                                                                                                                                                                                    .Scorad[
                                                                                                                                                                                                                                                                                                    fecha
                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                    .zonasAfectadas,
                                                                                                                                                                                                                                                                                                ).includes(
                                                                                                                                                                                                                                                                                                  "torso",
                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                              ? require("../assets/bodyhombre/bodymenostorso.png")
                                                                                                                                                                                                                                                                                              : Object.keys(
                                                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                    .length ==
                                                                                                                                                                                                                                                                                                    6 &&
                                                                                                                                                                                                                                                                                                  !Object.keys(
                                                                                                                                                                                                                                                                                                    paciente
                                                                                                                                                                                                                                                                                                      .Scorad[
                                                                                                                                                                                                                                                                                                      fecha
                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                      .zonasAfectadas,
                                                                                                                                                                                                                                                                                                  ).includes(
                                                                                                                                                                                                                                                                                                    "pierna izquierda",
                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                ? require("../assets/bodyhombre/bodymenospiernaizq.png")
                                                                                                                                                                                                                                                                                                : Object.keys(
                                                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                      .length ==
                                                                                                                                                                                                                                                                                                      6 &&
                                                                                                                                                                                                                                                                                                    !Object.keys(
                                                                                                                                                                                                                                                                                                      paciente
                                                                                                                                                                                                                                                                                                        .Scorad[
                                                                                                                                                                                                                                                                                                        fecha
                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                        .zonasAfectadas,
                                                                                                                                                                                                                                                                                                    ).includes(
                                                                                                                                                                                                                                                                                                      "pierna derecha",
                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                  ? require("../assets/bodyhombre/bodymenospiernader.png")
                                                                                                                                                                                                                                                                                                  : Object.keys(
                                                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                        .length ==
                                                                                                                                                                                                                                                                                                        6 &&
                                                                                                                                                                                                                                                                                                      !Object.keys(
                                                                                                                                                                                                                                                                                                        paciente
                                                                                                                                                                                                                                                                                                          .Scorad[
                                                                                                                                                                                                                                                                                                          fecha
                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                          .zonasAfectadas,
                                                                                                                                                                                                                                                                                                      ).includes(
                                                                                                                                                                                                                                                                                                        "cabeza y cuello",
                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                    ? require("../assets/bodyhombre/bodymenoscabeza.png")
                                                                                                                                                                                                                                                                                                    : Object.keys(
                                                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                          .length ==
                                                                                                                                                                                                                                                                                                          6 &&
                                                                                                                                                                                                                                                                                                        !Object.keys(
                                                                                                                                                                                                                                                                                                          paciente
                                                                                                                                                                                                                                                                                                            .Scorad[
                                                                                                                                                                                                                                                                                                            fecha
                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                            .zonasAfectadas,
                                                                                                                                                                                                                                                                                                        ).includes(
                                                                                                                                                                                                                                                                                                          "brazo derecho",
                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                      ? require("../assets/bodyhombre/bodymenosbrazoizq.png")
                                                                                                                                                                                                                                                                                                      : Object.keys(
                                                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                            .length ==
                                                                                                                                                                                                                                                                                                            6 &&
                                                                                                                                                                                                                                                                                                          !Object.keys(
                                                                                                                                                                                                                                                                                                            paciente
                                                                                                                                                                                                                                                                                                              .Scorad[
                                                                                                                                                                                                                                                                                                              fecha
                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                              .zonasAfectadas,
                                                                                                                                                                                                                                                                                                          ).includes(
                                                                                                                                                                                                                                                                                                            "brazo izquierdo",
                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                        ? require("../assets/bodyhombre/bodymenosbrazoder.png")
                                                                                                                                                                                                                                                                                                        : require("../assets/bodyhombre/bodytodo.png")
                            }
                          />

                          <img
                            style={{
                              width: "100px",
                              height: "335px",
                              maxWidth: "142 !important",
                            }}
                            src={
                              !Object.keys(
                                paciente.Scorad[fecha].zonasAfectadas2,
                              ) ||
                              !Object.keys(
                                paciente.Scorad[fecha].zonasAfectadas2,
                              ).length
                                ? require("../assets/bodyhombre/bodyback.png")
                                : !Object.keys(
                                      paciente.Scorad[fecha].zonasAfectadas2,
                                    ).includes("espalda") &&
                                    !Object.keys(
                                      paciente.Scorad[fecha].zonasAfectadas2,
                                    ).includes("pierna izquierda posterior") &&
                                    !Object.keys(
                                      paciente.Scorad[fecha].zonasAfectadas2,
                                    ).includes("pierna derecha posterior") &&
                                    !Object.keys(
                                      paciente.Scorad[fecha].zonasAfectadas2,
                                    ).includes("brazo izquierdo posterior") &&
                                    Object.keys(
                                      paciente.Scorad[fecha].zonasAfectadas2,
                                    ).includes("brazo derecho posterior") &&
                                    !Object.keys(
                                      paciente.Scorad[fecha].zonasAfectadas2,
                                    ).includes("cabeza y nuca posterior")
                                  ? require("../assets/bodyhombre/derechoposterior.png")
                                  : !Object.keys(
                                        paciente.Scorad[fecha].zonasAfectadas2,
                                      ).includes("espalda") &&
                                      !Object.keys(
                                        paciente.Scorad[fecha].zonasAfectadas2,
                                      ).includes(
                                        "pierna izquierda posterior",
                                      ) &&
                                      !Object.keys(
                                        paciente.Scorad[fecha].zonasAfectadas2,
                                      ).includes("pierna derecha posterior") &&
                                      Object.keys(
                                        paciente.Scorad[fecha].zonasAfectadas2,
                                      ).includes("brazo izquierdo posterior") &&
                                      !Object.keys(
                                        paciente.Scorad[fecha].zonasAfectadas2,
                                      ).includes("brazo derecho posterior") &&
                                      !Object.keys(
                                        paciente.Scorad[fecha].zonasAfectadas2,
                                      ).includes("cabeza y nuca posterior")
                                    ? require("../assets/bodyhombre/izqposterior.png")
                                    : !Object.keys(
                                          paciente.Scorad[fecha]
                                            .zonasAfectadas2,
                                        ).includes("espalda") &&
                                        !Object.keys(
                                          paciente.Scorad[fecha]
                                            .zonasAfectadas2,
                                        ).includes(
                                          "pierna izquierda posterior",
                                        ) &&
                                        !Object.keys(
                                          paciente.Scorad[fecha]
                                            .zonasAfectadas2,
                                        ).includes(
                                          "pierna derecha posterior",
                                        ) &&
                                        !Object.keys(
                                          paciente.Scorad[fecha]
                                            .zonasAfectadas2,
                                        ).includes(
                                          "brazo izquierdo posterior",
                                        ) &&
                                        !Object.keys(
                                          paciente.Scorad[fecha]
                                            .zonasAfectadas2,
                                        ).includes("brazo derecho posterior") &&
                                        Object.keys(
                                          paciente.Scorad[fecha]
                                            .zonasAfectadas2,
                                        ).includes("cabeza y nuca posterior")
                                      ? require("../assets/bodyhombre/bodynuca.png")
                                      : Object.keys(
                                            paciente.Scorad[fecha]
                                              .zonasAfectadas2,
                                          ).includes("espalda") &&
                                          !Object.keys(
                                            paciente.Scorad[fecha]
                                              .zonasAfectadas2,
                                          ).includes(
                                            "pierna izquierda posterior",
                                          ) &&
                                          !Object.keys(
                                            paciente.Scorad[fecha]
                                              .zonasAfectadas2,
                                          ).includes(
                                            "pierna derecha posterior",
                                          ) &&
                                          !Object.keys(
                                            paciente.Scorad[fecha]
                                              .zonasAfectadas2,
                                          ).includes(
                                            "brazo izquierdo posterior",
                                          ) &&
                                          !Object.keys(
                                            paciente.Scorad[fecha]
                                              .zonasAfectadas2,
                                          ).includes(
                                            "brazo derecho posterior",
                                          ) &&
                                          !Object.keys(
                                            paciente.Scorad[fecha]
                                              .zonasAfectadas2,
                                          ).includes("cabeza y nuca posterior")
                                        ? require("../assets/bodyhombre/bodyespalda.png")
                                        : !Object.keys(
                                              paciente.Scorad[fecha]
                                                .zonasAfectadas2,
                                            ).includes("espalda") &&
                                            !Object.keys(
                                              paciente.Scorad[fecha]
                                                .zonasAfectadas2,
                                            ).includes(
                                              "pierna izquierda posterior",
                                            ) &&
                                            Object.keys(
                                              paciente.Scorad[fecha]
                                                .zonasAfectadas2,
                                            ).includes(
                                              "pierna derecha posterior",
                                            ) &&
                                            !Object.keys(
                                              paciente.Scorad[fecha]
                                                .zonasAfectadas2,
                                            ).includes(
                                              "brazo izquierdo posterior",
                                            ) &&
                                            !Object.keys(
                                              paciente.Scorad[fecha]
                                                .zonasAfectadas2,
                                            ).includes(
                                              "brazo derecho posterior",
                                            ) &&
                                            !Object.keys(
                                              paciente.Scorad[fecha]
                                                .zonasAfectadas2,
                                            ).includes(
                                              "cabeza y nuca posterior",
                                            )
                                          ? require("../assets/bodyhombre/piernaderposterior.png")
                                          : !Object.keys(
                                                paciente.Scorad[fecha]
                                                  .zonasAfectadas2,
                                              ).includes("espalda") &&
                                              Object.keys(
                                                paciente.Scorad[fecha]
                                                  .zonasAfectadas2,
                                              ).includes(
                                                "pierna izquierda posterior",
                                              ) &&
                                              !Object.keys(
                                                paciente.Scorad[fecha]
                                                  .zonasAfectadas2,
                                              ).includes(
                                                "pierna derecha posterior",
                                              ) &&
                                              !Object.keys(
                                                paciente.Scorad[fecha]
                                                  .zonasAfectadas2,
                                              ).includes(
                                                "brazo izquierdo posterior",
                                              ) &&
                                              !Object.keys(
                                                paciente.Scorad[fecha]
                                                  .zonasAfectadas2,
                                              ).includes(
                                                "brazo derecho posterior",
                                              ) &&
                                              !Object.keys(
                                                paciente.Scorad[fecha]
                                                  .zonasAfectadas2,
                                              ).includes(
                                                "cabeza y nuca posterior",
                                              )
                                            ? require("../assets/bodyhombre/piernaizqposterior.png")
                                            : !Object.keys(
                                                  paciente.Scorad[fecha]
                                                    .zonasAfectadas2,
                                                ).includes("espalda") &&
                                                Object.keys(
                                                  paciente.Scorad[fecha]
                                                    .zonasAfectadas2,
                                                ).includes(
                                                  "pierna izquierda posterior",
                                                ) &&
                                                Object.keys(
                                                  paciente.Scorad[fecha]
                                                    .zonasAfectadas2,
                                                ).includes(
                                                  "pierna derecha posterior",
                                                ) &&
                                                !Object.keys(
                                                  paciente.Scorad[fecha]
                                                    .zonasAfectadas2,
                                                ).includes(
                                                  "brazo izquierdo posterior",
                                                ) &&
                                                !Object.keys(
                                                  paciente.Scorad[fecha]
                                                    .zonasAfectadas2,
                                                ).includes(
                                                  "brazo derecho posterior",
                                                ) &&
                                                !Object.keys(
                                                  paciente.Scorad[fecha]
                                                    .zonasAfectadas2,
                                                ).includes(
                                                  "cabeza y nuca posterior",
                                                )
                                              ? require("../assets/bodyhombre/piernasposterior.png")
                                              : !Object.keys(
                                                    paciente.Scorad[fecha]
                                                      .zonasAfectadas2,
                                                  ).includes("espalda") &&
                                                  Object.keys(
                                                    paciente.Scorad[fecha]
                                                      .zonasAfectadas2,
                                                  ).includes(
                                                    "pierna izquierda posterior",
                                                  ) &&
                                                  Object.keys(
                                                    paciente.Scorad[fecha]
                                                      .zonasAfectadas2,
                                                  ).includes(
                                                    "pierna derecha posterior",
                                                  ) &&
                                                  Object.keys(
                                                    paciente.Scorad[fecha]
                                                      .zonasAfectadas2,
                                                  ).includes(
                                                    "brazo izquierdo posterior",
                                                  ) &&
                                                  !Object.keys(
                                                    paciente.Scorad[fecha]
                                                      .zonasAfectadas2,
                                                  ).includes(
                                                    "brazo derecho posterior",
                                                  ) &&
                                                  !Object.keys(
                                                    paciente.Scorad[fecha]
                                                      .zonasAfectadas2,
                                                  ).includes(
                                                    "cabeza y nuca posterior",
                                                  )
                                                ? require("../assets/bodyhombre/piernasbrazoderposterior.png")
                                                : !Object.keys(
                                                      paciente.Scorad[fecha]
                                                        .zonasAfectadas2,
                                                    ).includes("espalda") &&
                                                    Object.keys(
                                                      paciente.Scorad[fecha]
                                                        .zonasAfectadas2,
                                                    ).includes(
                                                      "pierna izquierda posterior",
                                                    ) &&
                                                    Object.keys(
                                                      paciente.Scorad[fecha]
                                                        .zonasAfectadas2,
                                                    ).includes(
                                                      "pierna derecha posterior",
                                                    ) &&
                                                    !Object.keys(
                                                      paciente.Scorad[fecha]
                                                        .zonasAfectadas2,
                                                    ).includes(
                                                      "brazo izquierdo posterior",
                                                    ) &&
                                                    Object.keys(
                                                      paciente.Scorad[fecha]
                                                        .zonasAfectadas2,
                                                    ).includes(
                                                      "brazo derecho posterior",
                                                    ) &&
                                                    !Object.keys(
                                                      paciente.Scorad[fecha]
                                                        .zonasAfectadas2,
                                                    ).includes(
                                                      "cabeza y nuca posterior",
                                                    )
                                                  ? require("../assets/bodyhombre/piernasbrazoizqposterior.png")
                                                  : !Object.keys(
                                                        paciente.Scorad[fecha]
                                                          .zonasAfectadas2,
                                                      ).includes("espalda") &&
                                                      Object.keys(
                                                        paciente.Scorad[fecha]
                                                          .zonasAfectadas2,
                                                      ).includes(
                                                        "pierna izquierda posterior",
                                                      ) &&
                                                      Object.keys(
                                                        paciente.Scorad[fecha]
                                                          .zonasAfectadas2,
                                                      ).includes(
                                                        "pierna derecha posterior",
                                                      ) &&
                                                      !Object.keys(
                                                        paciente.Scorad[fecha]
                                                          .zonasAfectadas2,
                                                      ).includes(
                                                        "brazo izquierdo posterior",
                                                      ) &&
                                                      !Object.keys(
                                                        paciente.Scorad[fecha]
                                                          .zonasAfectadas2,
                                                      ).includes(
                                                        "brazo derecho posterior",
                                                      ) &&
                                                      Object.keys(
                                                        paciente.Scorad[fecha]
                                                          .zonasAfectadas2,
                                                      ).includes(
                                                        "cabeza y nuca posterior",
                                                      )
                                                    ? require("../assets/bodyhombre/piernasnucaposterior.png")
                                                    : Object.keys(
                                                          paciente.Scorad[fecha]
                                                            .zonasAfectadas2,
                                                        ).includes("espalda") &&
                                                        Object.keys(
                                                          paciente.Scorad[fecha]
                                                            .zonasAfectadas2,
                                                        ).includes(
                                                          "pierna izquierda posterior",
                                                        ) &&
                                                        Object.keys(
                                                          paciente.Scorad[fecha]
                                                            .zonasAfectadas2,
                                                        ).includes(
                                                          "pierna derecha posterior",
                                                        ) &&
                                                        !Object.keys(
                                                          paciente.Scorad[fecha]
                                                            .zonasAfectadas2,
                                                        ).includes(
                                                          "brazo izquierdo posterior",
                                                        ) &&
                                                        !Object.keys(
                                                          paciente.Scorad[fecha]
                                                            .zonasAfectadas2,
                                                        ).includes(
                                                          "brazo derecho posterior",
                                                        ) &&
                                                        !Object.keys(
                                                          paciente.Scorad[fecha]
                                                            .zonasAfectadas2,
                                                        ).includes(
                                                          "cabeza y nuca posterior",
                                                        )
                                                      ? require("../assets/bodyhombre/piernasespalda.png")
                                                      : !Object.keys(
                                                            paciente.Scorad[
                                                              fecha
                                                            ].zonasAfectadas2,
                                                          ).includes(
                                                            "espalda",
                                                          ) &&
                                                          Object.keys(
                                                            paciente.Scorad[
                                                              fecha
                                                            ].zonasAfectadas2,
                                                          ).includes(
                                                            "pierna izquierda posterior",
                                                          ) &&
                                                          Object.keys(
                                                            paciente.Scorad[
                                                              fecha
                                                            ].zonasAfectadas2,
                                                          ).includes(
                                                            "pierna derecha posterior",
                                                          ) &&
                                                          Object.keys(
                                                            paciente.Scorad[
                                                              fecha
                                                            ].zonasAfectadas2,
                                                          ).includes(
                                                            "brazo izquierdo posterior",
                                                          ) &&
                                                          Object.keys(
                                                            paciente.Scorad[
                                                              fecha
                                                            ].zonasAfectadas2,
                                                          ).includes(
                                                            "brazo derecho posterior",
                                                          ) &&
                                                          !Object.keys(
                                                            paciente.Scorad[
                                                              fecha
                                                            ].zonasAfectadas2,
                                                          ).includes(
                                                            "cabeza y nuca posterior",
                                                          )
                                                        ? require("../assets/bodyhombre/extremidadesposterior.png")
                                                        : !Object.keys(
                                                              paciente.Scorad[
                                                                fecha
                                                              ].zonasAfectadas2,
                                                            ).includes(
                                                              "espalda",
                                                            ) &&
                                                            Object.keys(
                                                              paciente.Scorad[
                                                                fecha
                                                              ].zonasAfectadas2,
                                                            ).includes(
                                                              "pierna izquierda posterior",
                                                            ) &&
                                                            Object.keys(
                                                              paciente.Scorad[
                                                                fecha
                                                              ].zonasAfectadas2,
                                                            ).includes(
                                                              "pierna derecha posterior",
                                                            ) &&
                                                            Object.keys(
                                                              paciente.Scorad[
                                                                fecha
                                                              ].zonasAfectadas2,
                                                            ).includes(
                                                              "brazo izquierdo posterior",
                                                            ) &&
                                                            Object.keys(
                                                              paciente.Scorad[
                                                                fecha
                                                              ].zonasAfectadas2,
                                                            ).includes(
                                                              "brazo derecho posterior",
                                                            ) &&
                                                            !Object.keys(
                                                              paciente.Scorad[
                                                                fecha
                                                              ].zonasAfectadas2,
                                                            ).includes(
                                                              "cabeza y nuca posterior",
                                                            )
                                                          ? require("../assets/bodyhombre/extremidadesposterior.png")
                                                          : !Object.keys(
                                                                paciente.Scorad[
                                                                  fecha
                                                                ]
                                                                  .zonasAfectadas2,
                                                              ).includes(
                                                                "espalda",
                                                              ) &&
                                                              Object.keys(
                                                                paciente.Scorad[
                                                                  fecha
                                                                ]
                                                                  .zonasAfectadas2,
                                                              ).includes(
                                                                "pierna izquierda posterior",
                                                              ) &&
                                                              !Object.keys(
                                                                paciente.Scorad[
                                                                  fecha
                                                                ]
                                                                  .zonasAfectadas2,
                                                              ).includes(
                                                                "pierna derecha posterior",
                                                              ) &&
                                                              !Object.keys(
                                                                paciente.Scorad[
                                                                  fecha
                                                                ]
                                                                  .zonasAfectadas2,
                                                              ).includes(
                                                                "brazo izquierdo posterior",
                                                              ) &&
                                                              Object.keys(
                                                                paciente.Scorad[
                                                                  fecha
                                                                ]
                                                                  .zonasAfectadas2,
                                                              ).includes(
                                                                "brazo derecho posterior",
                                                              ) &&
                                                              !Object.keys(
                                                                paciente.Scorad[
                                                                  fecha
                                                                ]
                                                                  .zonasAfectadas2,
                                                              ).includes(
                                                                "cabeza y nuca posterior",
                                                              )
                                                            ? require("../assets/bodyhombre/piernaizqbrazoder.png")
                                                            : !Object.keys(
                                                                  paciente
                                                                    .Scorad[
                                                                    fecha
                                                                  ]
                                                                    .zonasAfectadas2,
                                                                ).includes(
                                                                  "espalda",
                                                                ) &&
                                                                !Object.keys(
                                                                  paciente
                                                                    .Scorad[
                                                                    fecha
                                                                  ]
                                                                    .zonasAfectadas2,
                                                                ).includes(
                                                                  "pierna izquierda posterior",
                                                                ) &&
                                                                Object.keys(
                                                                  paciente
                                                                    .Scorad[
                                                                    fecha
                                                                  ]
                                                                    .zonasAfectadas2,
                                                                ).includes(
                                                                  "pierna derecha posterior",
                                                                ) &&
                                                                Object.keys(
                                                                  paciente
                                                                    .Scorad[
                                                                    fecha
                                                                  ]
                                                                    .zonasAfectadas2,
                                                                ).includes(
                                                                  "brazo izquierdo posterior",
                                                                ) &&
                                                                !Object.keys(
                                                                  paciente
                                                                    .Scorad[
                                                                    fecha
                                                                  ]
                                                                    .zonasAfectadas2,
                                                                ).includes(
                                                                  "brazo derecho posterior",
                                                                ) &&
                                                                !Object.keys(
                                                                  paciente
                                                                    .Scorad[
                                                                    fecha
                                                                  ]
                                                                    .zonasAfectadas2,
                                                                ).includes(
                                                                  "cabeza y nuca posterior",
                                                                )
                                                              ? require("../assets/bodyhombre/piernaderbrazoizq.png")
                                                              : Object.keys(
                                                                    paciente
                                                                      .Scorad[
                                                                      fecha
                                                                    ]
                                                                      .zonasAfectadas2,
                                                                  ).includes(
                                                                    "espalda",
                                                                  ) &&
                                                                  Object.keys(
                                                                    paciente
                                                                      .Scorad[
                                                                      fecha
                                                                    ]
                                                                      .zonasAfectadas2,
                                                                  ).includes(
                                                                    "pierna izquierda posterior",
                                                                  ) &&
                                                                  !Object.keys(
                                                                    paciente
                                                                      .Scorad[
                                                                      fecha
                                                                    ]
                                                                      .zonasAfectadas2,
                                                                  ).includes(
                                                                    "pierna derecha posterior",
                                                                  ) &&
                                                                  !Object.keys(
                                                                    paciente
                                                                      .Scorad[
                                                                      fecha
                                                                    ]
                                                                      .zonasAfectadas2,
                                                                  ).includes(
                                                                    "brazo izquierdo posterior",
                                                                  ) &&
                                                                  !Object.keys(
                                                                    paciente
                                                                      .Scorad[
                                                                      fecha
                                                                    ]
                                                                      .zonasAfectadas2,
                                                                  ).includes(
                                                                    "brazo derecho posterior",
                                                                  ) &&
                                                                  !Object.keys(
                                                                    paciente
                                                                      .Scorad[
                                                                      fecha
                                                                    ]
                                                                      .zonasAfectadas2,
                                                                  ).includes(
                                                                    "cabeza y nuca posterior",
                                                                  )
                                                                ? require("../assets/bodyhombre/espaldapiernaizq.png")
                                                                : Object.keys(
                                                                      paciente
                                                                        .Scorad[
                                                                        fecha
                                                                      ]
                                                                        .zonasAfectadas2,
                                                                    ).includes(
                                                                      "espalda",
                                                                    ) &&
                                                                    !Object.keys(
                                                                      paciente
                                                                        .Scorad[
                                                                        fecha
                                                                      ]
                                                                        .zonasAfectadas2,
                                                                    ).includes(
                                                                      "pierna izquierda posterior",
                                                                    ) &&
                                                                    Object.keys(
                                                                      paciente
                                                                        .Scorad[
                                                                        fecha
                                                                      ]
                                                                        .zonasAfectadas2,
                                                                    ).includes(
                                                                      "pierna derecha posterior",
                                                                    ) &&
                                                                    !Object.keys(
                                                                      paciente
                                                                        .Scorad[
                                                                        fecha
                                                                      ]
                                                                        .zonasAfectadas2,
                                                                    ).includes(
                                                                      "brazo izquierdo posterior",
                                                                    ) &&
                                                                    !Object.keys(
                                                                      paciente
                                                                        .Scorad[
                                                                        fecha
                                                                      ]
                                                                        .zonasAfectadas2,
                                                                    ).includes(
                                                                      "brazo derecho posterior",
                                                                    ) &&
                                                                    !Object.keys(
                                                                      paciente
                                                                        .Scorad[
                                                                        fecha
                                                                      ]
                                                                        .zonasAfectadas2,
                                                                    ).includes(
                                                                      "cabeza y nuca posterior",
                                                                    )
                                                                  ? require("../assets/bodyhombre/espaldapiernader.png")
                                                                  : Object.keys(
                                                                        paciente
                                                                          .Scorad[
                                                                          fecha
                                                                        ]
                                                                          .zonasAfectadas2,
                                                                      ).includes(
                                                                        "espalda",
                                                                      ) &&
                                                                      !Object.keys(
                                                                        paciente
                                                                          .Scorad[
                                                                          fecha
                                                                        ]
                                                                          .zonasAfectadas2,
                                                                      ).includes(
                                                                        "pierna izquierda posterior",
                                                                      ) &&
                                                                      !Object.keys(
                                                                        paciente
                                                                          .Scorad[
                                                                          fecha
                                                                        ]
                                                                          .zonasAfectadas2,
                                                                      ).includes(
                                                                        "pierna derecha posterior",
                                                                      ) &&
                                                                      Object.keys(
                                                                        paciente
                                                                          .Scorad[
                                                                          fecha
                                                                        ]
                                                                          .zonasAfectadas2,
                                                                      ).includes(
                                                                        "brazo izquierdo posterior",
                                                                      ) &&
                                                                      !Object.keys(
                                                                        paciente
                                                                          .Scorad[
                                                                          fecha
                                                                        ]
                                                                          .zonasAfectadas2,
                                                                      ).includes(
                                                                        "brazo derecho posterior",
                                                                      ) &&
                                                                      !Object.keys(
                                                                        paciente
                                                                          .Scorad[
                                                                          fecha
                                                                        ]
                                                                          .zonasAfectadas2,
                                                                      ).includes(
                                                                        "cabeza y nuca posterior",
                                                                      )
                                                                    ? require("../assets/bodyhombre/espaldabrazoizq.png")
                                                                    : Object.keys(
                                                                          paciente
                                                                            .Scorad[
                                                                            fecha
                                                                          ]
                                                                            .zonasAfectadas2,
                                                                        ).includes(
                                                                          "espalda",
                                                                        ) &&
                                                                        !Object.keys(
                                                                          paciente
                                                                            .Scorad[
                                                                            fecha
                                                                          ]
                                                                            .zonasAfectadas2,
                                                                        ).includes(
                                                                          "pierna izquierda posterior",
                                                                        ) &&
                                                                        !Object.keys(
                                                                          paciente
                                                                            .Scorad[
                                                                            fecha
                                                                          ]
                                                                            .zonasAfectadas2,
                                                                        ).includes(
                                                                          "pierna derecha posterior",
                                                                        ) &&
                                                                        !Object.keys(
                                                                          paciente
                                                                            .Scorad[
                                                                            fecha
                                                                          ]
                                                                            .zonasAfectadas2,
                                                                        ).includes(
                                                                          "brazo izquierdo posterior",
                                                                        ) &&
                                                                        Object.keys(
                                                                          paciente
                                                                            .Scorad[
                                                                            fecha
                                                                          ]
                                                                            .zonasAfectadas2,
                                                                        ).includes(
                                                                          "brazo derecho posterior",
                                                                        ) &&
                                                                        !Object.keys(
                                                                          paciente
                                                                            .Scorad[
                                                                            fecha
                                                                          ]
                                                                            .zonasAfectadas2,
                                                                        ).includes(
                                                                          "cabeza y nuca posterior",
                                                                        )
                                                                      ? require("../assets/bodyhombre/espaldabrazoder.png")
                                                                      : Object.keys(
                                                                            paciente
                                                                              .Scorad[
                                                                              fecha
                                                                            ]
                                                                              .zonasAfectadas2,
                                                                          ).includes(
                                                                            "espalda",
                                                                          ) &&
                                                                          !Object.keys(
                                                                            paciente
                                                                              .Scorad[
                                                                              fecha
                                                                            ]
                                                                              .zonasAfectadas2,
                                                                          ).includes(
                                                                            "pierna izquierda posterior",
                                                                          ) &&
                                                                          !Object.keys(
                                                                            paciente
                                                                              .Scorad[
                                                                              fecha
                                                                            ]
                                                                              .zonasAfectadas2,
                                                                          ).includes(
                                                                            "pierna derecha posterior",
                                                                          ) &&
                                                                          Object.keys(
                                                                            paciente
                                                                              .Scorad[
                                                                              fecha
                                                                            ]
                                                                              .zonasAfectadas2,
                                                                          ).includes(
                                                                            "brazo izquierdo posterior",
                                                                          ) &&
                                                                          Object.keys(
                                                                            paciente
                                                                              .Scorad[
                                                                              fecha
                                                                            ]
                                                                              .zonasAfectadas2,
                                                                          ).includes(
                                                                            "brazo derecho posterior",
                                                                          ) &&
                                                                          !Object.keys(
                                                                            paciente
                                                                              .Scorad[
                                                                              fecha
                                                                            ]
                                                                              .zonasAfectadas2,
                                                                          ).includes(
                                                                            "cabeza y nuca posterior",
                                                                          )
                                                                        ? require("../assets/bodyhombre/espaldabrazos.png")
                                                                        : Object.keys(
                                                                              paciente
                                                                                .Scorad[
                                                                                fecha
                                                                              ]
                                                                                .zonasAfectadas2,
                                                                            ).includes(
                                                                              "espalda",
                                                                            ) &&
                                                                            !Object.keys(
                                                                              paciente
                                                                                .Scorad[
                                                                                fecha
                                                                              ]
                                                                                .zonasAfectadas2,
                                                                            ).includes(
                                                                              "pierna izquierda posterior",
                                                                            ) &&
                                                                            !Object.keys(
                                                                              paciente
                                                                                .Scorad[
                                                                                fecha
                                                                              ]
                                                                                .zonasAfectadas2,
                                                                            ).includes(
                                                                              "pierna derecha posterior",
                                                                            ) &&
                                                                            Object.keys(
                                                                              paciente
                                                                                .Scorad[
                                                                                fecha
                                                                              ]
                                                                                .zonasAfectadas2,
                                                                            ).includes(
                                                                              "brazo izquierdo posterior",
                                                                            ) &&
                                                                            Object.keys(
                                                                              paciente
                                                                                .Scorad[
                                                                                fecha
                                                                              ]
                                                                                .zonasAfectadas2,
                                                                            ).includes(
                                                                              "brazo derecho posterior",
                                                                            ) &&
                                                                            Object.keys(
                                                                              paciente
                                                                                .Scorad[
                                                                                fecha
                                                                              ]
                                                                                .zonasAfectadas2,
                                                                            ).includes(
                                                                              "cabeza y nuca posterior",
                                                                            )
                                                                          ? require("../assets/bodyhombre/espaldabrazosnuca.png")
                                                                          : require("../assets/bodyhombre/bodyback.png")
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                ))}
            </Grid>
            <Button
              sx={{ margin: 2 }}
              variant="contained"
              onClick={() => {
                navigate(`/paciente/${paciente.id}/${idescala}`);
              }}
            >
              Nuevo Diagnóstico
            </Button>
          </Box>
        </Container>
        <Footer
          title="Footer"
          description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
        />
      </>
    );
  }

  if (idescala === "easi" && paciente && paciente.Easi) {
    return (
      <>
        <CssBaseline />
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: 12,
            width: "100%",
            backgroundColor: "transparent",
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h1 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.titulo}
          </h1>
        </Box>
        <Container maxWidth="lg">
          <h1
            style={{
              color: commonColors.morado,
              borderColor: commonColors.morado,
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
            }}
          >
            Diagnóstico EASI
          </h1>
          <div style={{ display: "flex" }}>
            <div className="h3-triangle">
              <h3 style={{ margin: 0 }}>
                {paciente.nombre} {paciente.apellido}
              </h3>
            </div>
            <div className="triangle" />
          </div>
          <Box
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              width: "100%",
            }}
            display="flex"
          >
            <Grid
              sx={{ alignItems: "center", justifyContent: "center" }}
              container
              spacing={1}
            >
              {paciente &&
                paciente.Easi &&
                Object.keys(paciente.Easi).map((fecha) => (
                  <Paper
                    sx={{
                      backgroundColor: commonColors.morado,
                      maxWidth: "800px",
                      width: "80%",
                      padding: "0",
                      marginBottom: 5,
                    }}
                    elevation={3}
                  >
                    <h4 style={{ color: "white", textAlign: "center" }}>
                      {paciente.Easi &&
                        paciente.Easi[fecha] &&
                        paciente.Easi[fecha]["updateAt"] &&
                        paciente.Easi[fecha]["updateAt"].toString()}
                    </h4>
                    <Box
                      sx={{
                        backgroundColor: "#EAE8F1",
                        maxWidth: "800px",
                        width: "100%",
                        padding: "0",
                      }}
                      display="block"
                    >
                      <Grid container display="flex">
                        <Grid
                          md={3}
                          sx={{ textAlign: "center", margin: "auto" }}
                          item
                        >
                          <img
                            src={
                              paciente.genero && paciente.genero === "f"
                                ? require("../assets/Mujer.png")
                                : require("../assets/Hombre.png")
                            }
                          />
                          <p>
                            {paciente.nombre} {paciente.apellido}
                          </p>
                        </Grid>
                        <Grid
                          sx={{
                            backgroundColor: "white",
                            padding: "20px 40px",
                          }}
                          md={9}
                          item
                        >
                          <h2 style={{ color: commonColors.morado }}>
                            Puntaje:{" "}
                            {paciente &&
                              paciente.Easi &&
                              paciente.Easi[fecha] &&
                              paciente.Easi[fecha] &&
                              paciente.Easi[fecha].easyScore}
                          </h2>
                          <h2 style={{ color: commonColors.morado }}>
                            Gravedad:{" "}
                            {(paciente && paciente.Easi === 0) ||
                            (paciente.Easi[fecha] &&
                              paciente.Easi[fecha] &&
                              paciente.Easi[fecha].easyScore &&
                              paciente.Easi[fecha].easyScore) < 8
                              ? "Leve"
                              : paciente &&
                                  paciente.Easi &&
                                  paciente.Easi[fecha] &&
                                  paciente.Easi[fecha].easyScore &&
                                  paciente.Easi[fecha].easyScore < 22
                                ? "Moderada"
                                : "Grave"}
                          </h2>
                          {
                            //JSON.stringify(paciente.Scorad[fecha])
                          }
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                ))}
            </Grid>
            <Button
              sx={{ margin: 2 }}
              variant="contained"
              onClick={() => {
                navigate(`/paciente/${paciente.id}/${idescala}`);
              }}
            >
              Nuevo Diagnóstico
            </Button>
          </Box>
        </Container>
        <Footer
          title="Footer"
          description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
        />
      </>
    );
  }

  if (paciente && paciente.Scorad)
    return (
      <>
        <CssBaseline />
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: 12,
            width: "100%",
            backgroundColor: "transparent",
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h1 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.titulo}
          </h1>
          <h2 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.subtitulo}
          </h2>
        </Box>
        <Container maxWidth="lg">
          <h1
            style={{
              color: commonColors.morado,
              borderColor: commonColors.morado,
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
            }}
          >
            Resultado
          </h1>
          <div style={{ display: "flex" }}>
            <div className="h3-triangle">
              <h3 style={{ margin: 0 }}>
                {paciente.nombre} {paciente.apellido}
              </h3>
            </div>
            <div className="triangle" />
          </div>
          <Box
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              width: "100%",
            }}
            display="flex"
          >
            <Grid
              sx={{ alignItems: "center", justifyContent: "center" }}
              container
              spacing={1}
            >
              <Paper
                sx={{
                  backgroundColor: commonColors.morado,
                  maxWidth: "800px",
                  width: "80%",
                  padding: "0",
                }}
                elevation={3}
              >
                <h4 style={{ color: "white", textAlign: "center" }}>
                  {paciente.Scorad.updateAt.toString()}
                </h4>
                <Box
                  sx={{
                    backgroundColor: "#EAE8F1",
                    maxWidth: "800px",
                    width: "100%",
                    padding: "0",
                  }}
                  display="block"
                >
                  <Grid container display="flex">
                    <Grid xs={3} sx={{ textAlign: "center" }} item>
                      <img
                        src={
                          paciente.genero && paciente.genero === "f"
                            ? require("../assets/Mujer.png")
                            : require("../assets/Hombre.png")
                        }
                      />
                      <p>
                        {paciente.nombre} {paciente.apellido}
                      </p>
                    </Grid>
                    <Grid
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      xs={9}
                      item
                    >
                      <h3 style={{ color: commonColors.morado }}>Puntaje: </h3>
                      <h3 style={{ color: commonColors.morado }}>Gravedad: </h3>
                      <div
                        onClick={() => navigate(`/escala/${idescala}/list`)}
                        style={{
                          backgroundColor: "#D2D1D1",
                          width: "100%",
                          padding: "8px 12px",
                          margin: "2px",
                          display: "flex",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        Ir a histórico
                        <img src={require("../assets/Ir-a-historico.png")} />
                      </div>
                      <div
                        onClick={() => navigate(`/escala/${idescala}/list`)}
                        style={{
                          backgroundColor: "#D2D1D1",
                          width: "100%",
                          padding: "8px 12px",
                          margin: "2px",
                          display: "flex",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        Descargar PDF
                        <img src={require("../assets/Descargar-PDF.png")} />
                      </div>
                      <div
                        onClick={() => navigate(`/escala/${idescala}/list`)}
                        style={{
                          backgroundColor: "#D2D1D1",
                          width: "100%",
                          padding: "8px 12px",
                          margin: "2px",
                          display: "flex",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        Enviar correo
                        <img src={require("../assets/Enviar-correo.png")} />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Button
              sx={{ margin: 2 }}
              variant="contained"
              onClick={() => {
                navigate(`/paciente/${paciente.id}/${idescala}`);
              }}
            >
              Nuevo Diagnóstico
            </Button>
          </Box>
        </Container>
        <Footer
          title="Footer"
          description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
        />
      </>
    );

  return (
    <>
      <CssBaseline />
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: 12,
          width: "100%",
          backgroundColor: "transparent",
          backgroundImage: `url(${Banner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <h1 style={{ color: "white", textAlign: "center" }}>
          {escala && escala.titulo}
        </h1>
        <h2 style={{ color: "white", textAlign: "center" }}>
          {escala && escala.subtitulo}
        </h2>
      </Box>
      <Container maxWidth="lg">
        <h1>Parece que el Diagnóstico que desea cargar aún no existe...</h1>
        <Button
          sx={{ margin: 2 }}
          variant="contained"
          onClick={() => {
            navigate(`/paciente/${paciente.id}/${idescala}`);
          }}
        >
          Nuevo Diagnóstico
        </Button>
      </Container>
    </>
  );
}

export default PacienteShow;
