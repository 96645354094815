import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Fondo from './assets/Fondo.png'
import Logo from './assets/Logo-Dermatopic.png'
import LogoCrear from './assets/Logo-Crear-Imagen.png'
import Toolbar from '@mui/material/Toolbar';
import {
  useNavigate,
} from "react-router-dom";

function Copyright() {
  const navigate = useNavigate()
  return (
    <Box sx={{ flexGrow: 1, paddingBottom: 4, justifyContent: 'space-between', alignItems: 'center', display: { xs: 'block', md: 'flex' } }}>
      <Box sx={{ flex: 1,justifyContent: 'flex-start', display: { xs: 'block', md: 'flex' } }}>
        <Typography variant="body2" color="text.secondary" align="center">
          {`Copyright © Dermatopic ${new Date().getFullYear()} - Todos los derechos reservados`}
        </Typography>
      </Box>
      <Box sx={{ flex: 1,justifyContent: 'flex-end', alignItems: 'center', display: { xs: 'block', md: 'flex' }}}>
         Desarrollado por: 
           <img onClick={() => navigate('/')} src={LogoCrear} style={{ maxWidth: 200, margin: 20, cursor:'pointer'}}/>
      </Box>
    </Box>
  );
}

function Footer(props) {
  const { description, title } = props;
  const navigate = useNavigate()
  const pages = [
    { title: 'Escalas', url: '#' },
    { title: 'Créditos', url: '#/creditos' },
    // { title: 'Lenguaje', url: '#' },
  ];
  return (
    <Box>
      <Box style={{backgroundColor: 'RGB(55,55,55)'}}>
        <Box component="footer" sx={{ 
            bgcolor: 'background.paper',
            backgroundColor: 'transparent',
            backgroundImage: `url(${Fondo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover',
            height: 141

          }}
        >
          <Container maxWidth="lg" sx={{display: { xs: 'block', md: 'flex' }}}>
            <Box sx={{ flex: 1,justifyContent: 'flex-end' }}>
              <img onClick={() => navigate('/')} src={Logo} style={{ maxWidth: 200, margin: { xs: 5, md: 20 }, cursor:'pointer'}}/>
            </Box>
            <Box sx={{ flex: 3,justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{color: 'white', fontSize: 10}}
                component="p"
              >
                {description}
              </Typography>
            </Box>
            <Box sx={{ flex: 1,justifyContent: 'flex-end' }} />
          </Container>
        </Box>
        <Box sx={{ flexGrow: 1, paddingTop: 4, paddingBottom: 4, justifyContent: 'center', display: { xs: 'flex', md: 'flex' } }}>
            {pages.map((section) => (
              <Link
                color="inherit"
                noWrap
                key={section.title}
                variant="body2"
                href={section.url}
                sx={{ pt: 0, pl:1, pr:1, flexShrink: 0, color: 'white', textDecoration: 'none' }}
              >
                {section.title}
              </Link>
            ))}
        </Box>
      </Box>
      <Copyright />
    </Box>
  );
}

export default Footer;