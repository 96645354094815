import logo from "../logo.svg";
import "../App.css";
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  DateInput,
  useNotify,
  useRedirect,
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ShowButton,
  Loading,
  useGetOne,
  useUpdate,
} from "react-admin";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Header from "../Header";
import HombreMap from "./HombreMap";
import ImgHistorico from "./ImgHistorico";
import HombreMapPosterior from "./HombreMapPosterior";
import { commonColors, escalas } from "../siteSettings";
import Banner from "../assets/Banner-Scorad.jpg";
import Next from "../assets/next.png";
import Imagen1 from "../assets/Que-es-Dermatitis.jpg";
import Imagen2 from "../assets/Tips-EASI.jpg";
import Imagen3 from "../assets/Tips-Scorad.jpg";
import Scorad from "../assets/PO-Scorad.png";
import EASI from "../assets/Escala-EASI.png";
import DLQI from "../assets/Escala-DLQI.png";
import ADCT from "../assets/Escala-ADCT.png";
import HADS from "../assets/Encuesta-HADS.png";
import POEM from "../assets/Escala-POEM.png";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import DialogTitle from "@mui/material/DialogTitle";
import SentimentSatisfiedAltOutlinedIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import { useNavigate, useParams, Link } from "react-router-dom";
// import MainFeaturedPost from '../MainFeaturedPost';
// import FeaturedPost from '../FeaturedPost';
// import Main from '../Main';
// import Sidebar from '../Sidebar';
import Footer from "../Footer";
const defaultTheme = createTheme();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function PacienteEdit(props) {
  const navigate = useNavigate();
  const { idescala, id } = useParams();
  const [resultado, setResultado] = React.useState();
  const [bodySelected, set_bodySelected] = React.useState(null);
  const [easy, set_easy] = React.useState({});
  const [easyTotal, set_easyTotal] = React.useState({});
  const [paso, setPaso] = React.useState(1);
  const [zonasAfectadas, set_zonasAfectadas] = React.useState({});
  const [zonasAfectadas2, set_zonasAfectadas2] = React.useState({});
  const [intensidad, set_intensidad] = React.useState({});
  const [trastornoValue, set_trastornoValue] = React.useState(0);
  const [encuestaValue, set_encuestaValue] = React.useState(0);
  const [totalScorad, set_totalScorad] = React.useState(0);
  const [currentKey, setCurrentKey] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [tipoDialog, setTipo] = React.useState(false);
  const [myform, setMyForm] = React.useState(false);
  const [picazon, set_picazon] = React.useState(0);
  const escala = idescala ? escalas.find((item) => item.id === idescala) : null;
  const Banner = escala
    ? require(`../assets/${escala.banner}`)
    : require(`../assets/Banner-2.jpg`);
  const settings = {
    bannerTitle:
      "Una herramienta práctica para la evaluación clinimétrica de pacientes con Dermatitis Atópica",
  };
  const handleClickOpen = (tipo) => {
    setTipo(tipo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = (data) => {
    notify(`Paciente creado con éxito`); // default message is 'ra.notification.created'
    navigate(`/escala/${idescala}/list`);
  };

  const calcular2 = (e, qty) => {
    e.preventDefault();
    // alert(e.target.title+': '+qty)
    // alert(e.target.title in zonasAfectadas)
    if (!(e.target.title in zonasAfectadas2)) {
      set_zonasAfectadas2({ ...zonasAfectadas2, [e.target.title]: qty });
    } else {
      let zonasAfectadasTemp = { ...zonasAfectadas2 };
      delete zonasAfectadasTemp[e.target.title];
      set_zonasAfectadas2({ ...zonasAfectadasTemp });
    }
    return false;
  };

  const calcular = (e, qty) => {
    e.preventDefault();
    // alert(e.target.title+': '+qty)
    // alert(e.target.title in zonasAfectadas)
    if (!(e.target.title in zonasAfectadas)) {
      set_zonasAfectadas({ ...zonasAfectadas, [e.target.title]: qty });
    } else {
      let zonasAfectadasTemp = { ...zonasAfectadas };
      delete zonasAfectadasTemp[e.target.title];
      set_zonasAfectadas({ ...zonasAfectadasTemp });
    }
    return false;
  };

  const { data: paciente, isLoading, error } = useGetOne("pacientes", { id });
  const [update, { isLoading2, error2 }] = useUpdate();
  if (isLoading || isLoading2) {
    return <Loading />;
  }
  if (error || error2) {
    return <p>ERROR</p>;
  }

  if (idescala === "easi")
    return (
      <>
        <CssBaseline />
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: 12,
            width: "100%",
            backgroundColor: "transparent",
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h1 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.titulo}
          </h1>
          <h2 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.subtitulo}
          </h2>
        </Box>
        {paso && paso === 1 ? (
          <Container maxWidth="lg">
            <h1
              style={{
                color: commonColors.morado,
                borderColor: commonColors.morado,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
              }}
            >
              Ingresar datos del paciente
            </h1>
            <div style={{ display: "flex" }}>
              <div className="h3-triangle">
                <h3 style={{ margin: 0 }}>
                  {paciente.nombre} {paciente.apellido}
                </h3>
              </div>
              <div className="triangle" />
            </div>
            <h3>Indica áreas afectadas: </h3>
            <Box
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                padding: 10,
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={
                      !bodySelected
                        ? require("../assets/easi-limpio.png")
                        : bodySelected === "Cabeza"
                          ? require("../assets/easi-cabeza.png")
                          : bodySelected === "Tronco"
                            ? require("../assets/easi-tronco.png")
                            : bodySelected === "Brazos"
                              ? require("../assets/easi-brazos.png")
                              : bodySelected === "Piernas"
                                ? require("../assets/easi-piernas.png")
                                : require("../assets/easi-limpio.png")
                    }
                    usemap="#image-map"
                  />

                  <map name="image-map">
                    <area
                      target=""
                      onClick={(e) => {
                        e.preventDefault();
                        set_bodySelected(e.target.title);
                        set_intensidad({});
                      }}
                      alt="Cabeza"
                      title="Cabeza"
                      href=""
                      coords="127,5,88,12,82,49,79,66,83,73,89,90,120,115,148,85,151,75,158,55,154,47,153,15,127,3"
                      shape="poly"
                    />
                    <area
                      target=""
                      onClick={(e) => {
                        e.preventDefault();
                        set_bodySelected(e.target.title);
                        set_intensidad({});
                      }}
                      alt="Brazo Izquierdo"
                      title="Brazos"
                      href=""
                      coords="32,144,48,220,43,270,38,290,40,324,24,400,31,413,28,447,18,434,16,445,36,459,30,476,6,460,1,442,2,295,2,228,7,202,14,161"
                      shape="poly"
                    />
                    <area
                      target=""
                      onClick={(e) => {
                        e.preventDefault();
                        set_bodySelected(e.target.title);
                        set_intensidad({});
                      }}
                      alt="Brazo derecho"
                      title="Brazos"
                      href=""
                      coords="206,144,187,223,192,268,198,283,199,336,211,402,204,417,206,445,219,435,221,446,204,456,202,480,225,467,235,441,236,293,233,216,225,200,221,155"
                      shape="poly"
                    />
                    <area
                      target=""
                      onClick={(e) => {
                        e.preventDefault();
                        set_bodySelected(e.target.title);
                        set_intensidad({});
                      }}
                      alt="Tronco"
                      title="Tronco"
                      href=""
                      coords="93,105,122,113,141,107,144,117,192,137,183,211,181,235,172,269,182,335,157,357,133,381,118,412,105,382,84,358,56,330,67,269,55,246,56,218,37,141,93,119"
                      shape="poly"
                    />
                    <area
                      target=""
                      onClick={(e) => {
                        e.preventDefault();
                        set_bodySelected(e.target.title);
                        set_intensidad({});
                      }}
                      alt="Pierna Izquierda"
                      title="Piernas"
                      href=""
                      coords="53,344,115,426,106,463,102,533,86,578,93,613,87,656,86,716,98,731,90,744,91,792,48,786,66,737,60,692,52,662,41,629,52,542,43,448"
                      shape="poly"
                    />
                    <area
                      target=""
                      onClick={(e) => {
                        e.preventDefault();
                        set_bodySelected(e.target.title);
                        set_intensidad({});
                      }}
                      alt="Pierna derecha"
                      title="Piernas"
                      href=""
                      coords="122,422,135,464,137,539,150,580,146,609,152,714,144,730,149,746,148,791,187,783,171,719,194,622,186,536,192,445,193,416,179,343"
                      shape="poly"
                    />
                  </map>
                </Grid>

                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Paper
                    sx={{
                      backgroundColor: commonColors.morado,
                      width: "100%",
                      padding: "0",
                      marginBottom: 10,
                    }}
                    elevation={3}
                  >
                    <h4 style={{ color: "white", textAlign: "center" }}></h4>
                    <Box
                      sx={{
                        backgroundColor: "#EAE8F1",
                        maxWidth: "800px",
                        width: "100%",
                        padding: "0",
                      }}
                      display="block"
                    >
                      <Grid container display="flex">
                        <Grid
                          sx={{
                            backgroundColor: "white",
                            padding: "20px 40px",
                          }}
                          item
                        >
                          <p>
                            Seleccione el % afectado de cada parte del cuerpo en
                            los apartados inferiores y a continuación la
                            intensidad de los diferentes signos detectados en el
                            paciente.
                          </p>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                  <Grid container spacing={5}>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#D2D1D1",
                          width: "100%",
                          padding: "14px",
                          margin: "2px",
                        }}
                      >
                        Eritema
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={0.5}>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({ ...intensidad, eritema: 0 });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.eritema == 0 ? "selected" : ""
                            }
                            src={require("../assets/Eritema-0.png")}
                          />
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({ ...intensidad, eritema: 1 });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.eritema == 1 ? "selected" : ""
                            }
                            src={require("../assets/Eritema-1.png")}
                          />
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({ ...intensidad, eritema: 2 });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.eritema == 2 ? "selected" : ""
                            }
                            src={require("../assets/Eritema-2.png")}
                          />
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({ ...intensidad, eritema: 3 });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.eritema == 3 ? "selected" : ""
                            }
                            src={require("../assets/Eritema-3.png")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#D2D1D1",
                          width: "100%",
                          padding: "14px",
                          margin: "2px",
                        }}
                      >
                        Edema
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={0.5}>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({
                              ...intensidad,
                              edemaElevacion: 0,
                            });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.edemaElevacion == 0 ? "selected" : ""
                            }
                            src={require("../assets/Edema-Elevacion-0.png")}
                          />
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({
                              ...intensidad,
                              edemaElevacion: 1,
                            });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.edemaElevacion == 1 ? "selected" : ""
                            }
                            src={require("../assets/Edema-Elevacion-1.png")}
                          />
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({
                              ...intensidad,
                              edemaElevacion: 2,
                            });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.edemaElevacion == 2 ? "selected" : ""
                            }
                            src={require("../assets/Edema-Elevacion-2.png")}
                          />
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({
                              ...intensidad,
                              edemaElevacion: 3,
                            });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.edemaElevacion == 3 ? "selected" : ""
                            }
                            src={require("../assets/Edema-Elevacion-3.png")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#D2D1D1",
                          width: "100%",
                          padding: "14px",
                          margin: "2px",
                        }}
                      >
                        Excoriaciones
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={0.5}>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({ ...intensidad, exoriaciones: 0 });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.exoriaciones == 0 ? "selected" : ""
                            }
                            src={require("../assets/Excoriaciones-0.png")}
                          />
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({ ...intensidad, exoriaciones: 1 });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.exoriaciones == 1 ? "selected" : ""
                            }
                            src={require("../assets/Excoriaciones-1.png")}
                          />
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({ ...intensidad, exoriaciones: 2 });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.exoriaciones == 2 ? "selected" : ""
                            }
                            src={require("../assets/Excoriaciones-2.png")}
                          />
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({ ...intensidad, exoriaciones: 3 });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.exoriaciones == 3 ? "selected" : ""
                            }
                            src={require("../assets/Excoriaciones-3.png")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#D2D1D1",
                          width: "100%",
                          padding: "14px",
                          margin: "2px",
                        }}
                      >
                        Liquenificación
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={0.5}>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({
                              ...intensidad,
                              liquenificacion: 0,
                            });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.liquenificacion == 0 ? "selected" : ""
                            }
                            src={require("../assets/Liquenificacion-0.png")}
                          />
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({
                              ...intensidad,
                              liquenificacion: 1,
                            });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.liquenificacion == 1 ? "selected" : ""
                            }
                            src={require("../assets/Liquenificacion-1.png")}
                          />
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({
                              ...intensidad,
                              liquenificacion: 2,
                            });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.liquenificacion == 2 ? "selected" : ""
                            }
                            src={require("../assets/Liquenificacion-2.png")}
                          />
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            set_intensidad({
                              ...intensidad,
                              liquenificacion: 3,
                            });
                          }}
                        >
                          <img
                            style={{ width: "100%" }}
                            className={
                              intensidad.liquenificacion == 3 ? "selected" : ""
                            }
                            src={require("../assets/Liquenificacion-3.png")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <Paper
                          className={
                            easy && easy[bodySelected] == 1 ? "selected" : ""
                          }
                          onClick={() =>
                            set_easy({ ...easy, [bodySelected]: 1 })
                          }
                          sx={{
                            cursor: "pointer",
                            border: "solid 1px black",
                            flex: 1,
                            borderRadius: 0,
                            textAlign: "center",
                            padding: "25px 0",
                          }}
                        >{`<10%`}</Paper>
                        <Paper
                          className={
                            easy && easy[bodySelected] === 2 ? "selected" : ""
                          }
                          onClick={() =>
                            set_easy({ ...easy, [bodySelected]: 2 })
                          }
                          sx={{
                            cursor: "pointer",
                            border: "solid 1px black",
                            flex: 1,
                            borderRadius: 0,
                            textAlign: "center",
                            padding: "25px 0",
                          }}
                        >
                          10-30%
                        </Paper>
                        <Paper
                          className={
                            easy && easy[bodySelected] === 3 ? "selected" : ""
                          }
                          onClick={() =>
                            set_easy({ ...easy, [bodySelected]: 3 })
                          }
                          sx={{
                            cursor: "pointer",
                            border: "solid 1px black",
                            flex: 1,
                            borderRadius: 0,
                            textAlign: "center",
                            padding: "25px 0",
                          }}
                        >
                          30-50%
                        </Paper>
                        <Paper
                          className={
                            easy && easy[bodySelected] === 4 ? "selected" : ""
                          }
                          onClick={() =>
                            set_easy({ ...easy, [bodySelected]: 4 })
                          }
                          sx={{
                            cursor: "pointer",
                            border: "solid 1px black",
                            flex: 1,
                            borderRadius: 0,
                            textAlign: "center",
                            padding: "25px 0",
                          }}
                        >
                          50-70%
                        </Paper>
                        <Paper
                          className={
                            easy && easy[bodySelected] === 5 ? "selected" : ""
                          }
                          onClick={() =>
                            set_easy({ ...easy, [bodySelected]: 5 })
                          }
                          sx={{
                            cursor: "pointer",
                            border: "solid 1px black",
                            flex: 1,
                            borderRadius: 0,
                            textAlign: "center",
                            padding: "25px 0",
                          }}
                        >
                          70-90%
                        </Paper>
                        <Paper
                          className={
                            easy && easy[bodySelected] === 6 ? "selected" : ""
                          }
                          onClick={() =>
                            set_easy({ ...easy, [bodySelected]: 6 })
                          }
                          sx={{
                            cursor: "pointer",
                            border: "solid 1px black",
                            flex: 1,
                            borderRadius: 0,
                            textAlign: "center",
                            padding: "25px 0",
                          }}
                        >{`>90%`}</Paper>
                      </Box>
                      <Button
                        sx={{ width: "80%", margin: 10 }}
                        variant="contained"
                        onClick={() => {
                          let age = null;
                          let multiplier =
                            bodySelected === "Cabeza"
                              ? 0.1
                              : bodySelected === "Tronco"
                                ? 0.3
                                : bodySelected === "Brazos"
                                  ? 0.2
                                  : bodySelected === "Piernas"
                                    ? 0.4
                                    : 0;
                          if (paciente.fecha_de_nacimiento) {
                            var dob = new Date(paciente.fecha_de_nacimiento);
                            var month_diff = Date.now() - dob.getTime();
                            var age_dt = new Date(month_diff);
                            var year = age_dt.getUTCFullYear();
                            age = Math.abs(year - 1970);
                            if (age < 8)
                              multiplier =
                                bodySelected === "Cabeza"
                                  ? 0.2
                                  : bodySelected === "Tronco"
                                    ? 0.3
                                    : bodySelected === "Brazos"
                                      ? 0.2
                                      : bodySelected === "Piernas"
                                        ? 0.3
                                        : 0;
                          }

                          if (easy[bodySelected] === undefined)
                            alert("Debe seleccionar el % de área afectada");

                          const areaScore = easy[bodySelected]
                            ? easy[bodySelected]
                            : 0;
                          console.log(multiplier);
                          console.log({
                            ...easyTotal,
                            [bodySelected]: {
                              intensidad,
                              score: areaScore,
                              areaScore:
                                Object.values(intensidad).reduce(
                                  (accumulator, currentValue) =>
                                    accumulator + currentValue,
                                  0,
                                ) *
                                areaScore *
                                multiplier,
                            },
                          });

                          set_easyTotal({
                            ...easyTotal,
                            [bodySelected]: {
                              intensidad,
                              score: areaScore,
                              areaScore:
                                Object.values(intensidad).reduce(
                                  (accumulator, currentValue) =>
                                    accumulator + currentValue,
                                  0,
                                ) *
                                areaScore *
                                multiplier,
                            },
                          });
                        }}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container sx={{ backgroundColor: "#877CAE" }}>
                  <Grid item xs={9}>
                    <Box
                      sx={{ width: "100%", padding: "20px 40px" }}
                      display="block"
                    >
                      <p style={{ color: "white" }}>
                        *No olvides evaluar todas las áreas anatómicas del
                        cuerpo para poder obtener el cálculo*
                      </p>
                      <Box display="flex">
                        <Box
                          style={{ color: "white" }}
                          display="block"
                          sx={{ flex: 1 }}
                        >
                          Cabeza
                          <p style={{ fontSize: 40 }}>
                            {easyTotal &&
                            easyTotal["Cabeza"] &&
                            easyTotal["Cabeza"]["areaScore"]
                              ? easyTotal["Cabeza"]["areaScore"].toFixed(2)
                              : 0}
                          </p>
                        </Box>
                        <Box
                          style={{ color: "white" }}
                          display="block"
                          sx={{ flex: 1 }}
                        >
                          Brazos
                          <p style={{ fontSize: 40 }}>
                            {easyTotal &&
                            easyTotal["Brazos"] &&
                            easyTotal["Brazos"]["areaScore"]
                              ? easyTotal["Brazos"]["areaScore"].toFixed(2)
                              : 0}
                          </p>
                        </Box>
                        <Box
                          style={{ color: "white" }}
                          display="block"
                          sx={{ flex: 1 }}
                        >
                          Tronco
                          <p style={{ fontSize: 40 }}>
                            {easyTotal &&
                            easyTotal["Tronco"] &&
                            easyTotal["Tronco"]["areaScore"]
                              ? easyTotal["Tronco"]["areaScore"].toFixed(2)
                              : 0}
                          </p>
                        </Box>
                        <Box
                          style={{ color: "white" }}
                          display="block"
                          sx={{ flex: 1 }}
                        >
                          Pierna
                          <p style={{ fontSize: 40 }}>
                            {easyTotal &&
                            easyTotal["Piernas"] &&
                            easyTotal["Piernas"]["areaScore"]
                              ? easyTotal["Piernas"]["areaScore"].toFixed(2)
                              : 0}
                          </p>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      sx={{
                        width: "100%",
                        padding: "20px",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      display="flex"
                    >
                      <Button
                        onClick={() => {
                          // console.log(easyTotal)
                          // return;

                          if (Object.values(easyTotal).length) {
                            set_easyTotal({
                              ...easyTotal,
                              easyScore: Math.ceil(
                                Object.values(easyTotal).reduce(
                                  (accumulator, currentValue) =>
                                    accumulator + currentValue.areaScore,
                                  0,
                                ),
                              ),
                            });
                            const hoy = new Date();
                            setCurrentKey(hoy.toISOString());
                            update("pacientes", {
                              id: paciente.id,
                              data: {
                                Easi: {
                                  ...paciente.Easi,
                                  [hoy.toISOString()]: {
                                    ...easyTotal,
                                    easyScore: Math.ceil(
                                      Object.values(easyTotal).reduce(
                                        (accumulator, currentValue) =>
                                          accumulator + currentValue.areaScore,
                                        0,
                                      ),
                                    ),
                                    updateAt: new Date(),
                                  },
                                },
                              },
                              previousData: paciente,
                            });

                            set_bodySelected(null);
                            set_intensidad({});
                            set_easy({});
                            set_easyTotal({});

                            setPaso(4);
                          } else alert("Debe completar el diagnóstico");
                        }}
                        sx={{
                          backgroundColor: "white",
                          color: commonColors.morado,
                        }}
                      >
                        Calcular
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
        ) : paso && currentKey && paso === 4 ? (
          <Container maxWidth="lg">
            <h1
              style={{
                color: commonColors.morado,
                borderColor: commonColors.morado,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
              }}
            >
              Diagnóstico EASI
            </h1>
            <div style={{ display: "flex" }}>
              <div className="h3-triangle">
                <h3 style={{ margin: 0 }}>
                  {paciente.nombre} {paciente.apellido}
                </h3>
              </div>
              <div className="triangle" />
            </div>
            <Box
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "40px",
                width: "100%",
              }}
              display="flex"
            >
              <Grid
                sx={{ alignItems: "center", justifyContent: "center" }}
                container
                spacing={1}
              >
                <Paper
                  sx={{
                    backgroundColor: commonColors.morado,
                    maxWidth: "800px",
                    width: "80%",
                    padding: "0",
                  }}
                  elevation={3}
                >
                  <h4 style={{ color: "white", textAlign: "center" }}>
                    {paciente.Easi && paciente.Easi[currentKey]
                      ? paciente.Easi[currentKey].updateAt.toString()
                      : ""}
                  </h4>
                  <Box
                    sx={{
                      backgroundColor: "#EAE8F1",
                      maxWidth: "800px",
                      width: "100%",
                      padding: "0",
                    }}
                    display="block"
                  >
                    <Grid container display="flex">
                      <Grid xs={3} sx={{ textAlign: "center" }} item>
                        <img
                          src={
                            paciente.genero && paciente.genero === "f"
                              ? require("../assets/Mujer.png")
                              : require("../assets/Hombre.png")
                          }
                        />
                        <p>
                          {paciente.nombre} {paciente.apellido}
                        </p>
                      </Grid>
                      <Grid
                        sx={{ backgroundColor: "white", padding: "20px 40px" }}
                        xs={9}
                        item
                      >
                        <h3 style={{ color: commonColors.morado }}>
                          Puntaje:{" "}
                          {paciente &&
                            paciente.Easi &&
                            paciente.Easi[currentKey] &&
                            paciente.Easi[currentKey].easyScore}
                        </h3>
                        <h3 style={{ color: commonColors.morado }}>
                          Gravedad:
                          {paciente &&
                          paciente.Easi &&
                          paciente.Easi[currentKey] &&
                          paciente.Easi[currentKey].easyScore !== undefined &&
                          parseFloat(paciente.Easi[currentKey].easyScore) < 25
                            ? "Leve"
                            : paciente &&
                                paciente.Easi &&
                                paciente.Easi[currentKey] &&
                                paciente.Easi[currentKey].easyScore &&
                                paciente.Easi[currentKey].easyScore < 50
                              ? "Moderada"
                              : "Grave"}
                        </h3>
                        <Link
                          onClick={() => handleClickOpen("paciente")}
                          style={{
                            backgroundColor: "#D2D1D1",
                            width: "100%",
                            padding: "5px 20px 5px 20px",
                            margin: "2px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          Ir a histórico{" "}
                          <img src={require("../assets/Ir-a-historico.png")} />
                        </Link>
                        <Link
                          onClick={() => {
                            var pri = document.getElementById(
                              "ifmcontentstoprint2",
                            ).contentWindow;
                            pri.document.open();
                            pri.document
                              .write(`<style type="text/css" media="print" />
                                  body {font-size: 8pt; font-family:'Arial','Times New Roman',Times,serif;}
                                  table { page-break-inside: avoid;page-break-after:auto }
                                  tr    { page-break-inside:avoid; page-break-after:auto }
                                  td    { page-break-inside:avoid; page-break-after:auto; font-size: 8pt !important; font-family:'Arial','Times New Roman',Times,serif; }
                                  thead { display:table-header-group }
                                  tfoot { display:table-footer-group }</style>`);
                            pri.document.write(
                              document.getElementById("toprint2").innerHTML,
                            );
                            pri.document.close();
                            pri.focus();
                            pri.print();
                          }}
                          style={{
                            backgroundColor: "#D2D1D1",
                            width: "100%",
                            padding: "5px 20px 5px 20px",
                            margin: "2px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          Descargar PDF{" "}
                          <img src={require("../assets/Descargar-PDF.png")} />
                        </Link>
                        <Link
                          style={{
                            backgroundColor: "#D2D1D1",
                            width: "100%",
                            padding: "5px 20px 5px 20px",
                            margin: "2px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          Enviar correo{" "}
                          <img src={require("../assets/Enviar-correo.png")} />
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Button
                sx={{ margin: 2 }}
                variant="contained"
                onClick={() => {
                  setPaso(1);
                  navigate(`/paciente/${paciente.id}/${idescala}`);
                }}
              >
                Nuevo Diagnóstico
              </Button>
            </Box>
            <iframe
              title="pdf"
              id="ifmcontentstoprint2"
              className="pdfIframe"
            ></iframe>
            <Box id="toprint2">
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  justifyContent: "space-between",
                  width: "100%",
                  backgroundColor: commonColors.morado,
                }}
              >
                <img src={require("../assets/Logo-Dermatopic.png")} />
                <img src={require("../assets/logoasoderm.jpg")} />
              </div>
              <Box>
                <h1
                  style={{
                    color: commonColors.morado,
                    borderColor: commonColors.morado,
                    borderBottomWidth: 2,
                    borderBottomStyle: "solid",
                  }}
                >
                  Reporte EASI
                </h1>
              </Box>
              <table style={{ border: "none", width: "100%" }}>
                <tr>
                  <td style={{ padding: 5 }}>Nombre</td>
                  <td>Apellido</td>
                  <td>Edad</td>
                </tr>
                <tr>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#D2D1D1",
                        width: "100%",
                        padding: "5px 20px 5px 20px",
                        margin: "2px",
                      }}
                    >
                      {paciente.nombre}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#D2D1D1",
                        width: "100%",
                        padding: "5px 20px 5px 20px",
                        margin: "2px",
                      }}
                    >
                      {paciente.apellido}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#D2D1D1",
                        width: "100%",
                        padding: "5px 20px 5px 20px",
                        margin: "2px",
                      }}
                    >
                      {
                        //getAge(paciente.fecha_de_nacimiento.toString())
                        paciente.fecha_de_nacimiento
                          ? getAge(paciente.fecha_de_nacimiento.split("T")[0])
                          : null
                      }
                    </div>
                  </td>
                </tr>
              </table>
              <table
                style={{
                  border: "none",
                  width: "100%",
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                <tr>
                  <td>
                    <img
                      width="15%"
                      src={require("../assets/reporte/easi-cabeza.png")}
                    />
                    <h3>
                      Cabeza:{" "}
                      {paciente &&
                      paciente.Easi &&
                      paciente.Easi[currentKey] &&
                      paciente.Easi[currentKey]["Cabeza"]["areaScore"]
                        ? paciente.Easi[currentKey]["Cabeza"][
                            "areaScore"
                          ].toFixed(2)
                        : `0`}
                    </h3>
                  </td>
                  <td>
                    <img
                      width="15%"
                      src={require("../assets/reporte/easi-brazos.png")}
                    />
                    <h3>
                      Brazos:{" "}
                      {paciente &&
                      paciente.Easi &&
                      paciente.Easi[currentKey] &&
                      paciente.Easi[currentKey]["Brazos"]["areaScore"]
                        ? paciente.Easi[currentKey]["Brazos"][
                            "areaScore"
                          ].toFixed(2)
                        : `0`}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      width="15%"
                      src={require("../assets/reporte/easi-piernas.png")}
                    />
                    <h3>
                      Piernas:{" "}
                      {paciente &&
                      paciente.Easi &&
                      paciente.Easi[currentKey] &&
                      paciente.Easi[currentKey]["Piernas"]["areaScore"]
                        ? paciente.Easi[currentKey]["Piernas"][
                            "areaScore"
                          ].toFixed(2)
                        : `0`}
                    </h3>
                  </td>
                  <td>
                    <img
                      width="15%"
                      src={require("../assets/reporte/easi-tronco.png")}
                    />
                    <h3>
                      Tronco:{" "}
                      {paciente &&
                      paciente.Easi &&
                      paciente.Easi[currentKey] &&
                      paciente.Easi[currentKey]["Tronco"]["areaScore"]
                        ? paciente.Easi[currentKey]["Tronco"][
                            "areaScore"
                          ].toFixed(2)
                        : `0`}
                    </h3>
                  </td>
                </tr>
              </table>

              <div
                style={{
                  width: "100%",
                  backgroundColor: commonColors.moradoClaro,
                  padding: 5,
                }}
              >
                <h2>
                  Puntaje: Puntaje:{" "}
                  {paciente &&
                    paciente.Easi &&
                    paciente.Easi[currentKey] &&
                    paciente.Easi[currentKey].easyScore}
                </h2>
                <h2>
                  Gravedad: Gravedad:{" "}
                  {paciente &&
                  paciente.Easi &&
                  paciente.Easi[currentKey] &&
                  paciente.Easi[currentKey].easyScore !== undefined &&
                  paciente.Easi[currentKey].easyScore < 8
                    ? "Leve"
                    : paciente &&
                        paciente.Easi &&
                        paciente.Easi[currentKey] &&
                        paciente.Easi[currentKey].easyScore &&
                        paciente.Easi[currentKey].easyScore < 22
                      ? "Moderada"
                      : "Grave"}
                </h2>
              </div>
            </Box>
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar color="secondary" sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Histórico
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box
                sx={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: { xs: "5px", md: "40px" },
                  width: "100%",
                }}
                display="flex"
              >
                <Grid
                  sx={{ alignItems: "center", justifyContent: "center" }}
                  container
                  spacing={1}
                >
                  {paciente &&
                    paciente.Easi &&
                    Object.keys(paciente.Easi).map((fecha) => (
                      <Paper
                        sx={{
                          backgroundColor: commonColors.morado,
                          maxWidth: "800px",
                          width: "80%",
                          padding: "0",
                          marginBottom: 5,
                        }}
                        elevation={3}
                      >
                        <h4 style={{ color: "white", textAlign: "center" }}>
                          {paciente.Easi &&
                            paciente.Easi[fecha] &&
                            paciente.Easi[fecha]["updateAt"] &&
                            paciente.Easi[fecha]["updateAt"].toString()}
                        </h4>
                        <Box
                          sx={{
                            backgroundColor: "#EAE8F1",
                            maxWidth: "800px",
                            width: "100%",
                            padding: "0",
                          }}
                          display="block"
                        >
                          <Grid container display="flex">
                            <Grid
                              md={3}
                              sx={{ textAlign: "center", margin: "auto" }}
                              item
                            >
                              <img
                                src={
                                  paciente.genero && paciente.genero === "f"
                                    ? require("../assets/Mujer.png")
                                    : require("../assets/Hombre.png")
                                }
                              />
                              <p>
                                {paciente.nombre} {paciente.apellido}
                              </p>
                            </Grid>
                            <Grid
                              sx={{
                                backgroundColor: "white",
                                padding: "20px 40px",
                              }}
                              md={9}
                              item
                            >
                              <h3 style={{ color: commonColors.morado }}>
                                Puntaje:{" "}
                                {paciente &&
                                  paciente.Easi &&
                                  paciente.Easi[fecha] &&
                                  paciente.Easi[fecha] &&
                                  paciente.Easi[fecha].easyScore}
                              </h3>
                              <h3 style={{ color: commonColors.morado }}>
                                Gravedad:{" "}
                                {paciente &&
                                paciente.Easi &&
                                paciente.Easi[fecha] &&
                                paciente.Easi[fecha] &&
                                paciente.Easi[fecha].easyScore !== undefined &&
                                paciente.Easi[fecha].easyScore < 25
                                  ? "Leve"
                                  : paciente &&
                                      paciente.Easi &&
                                      paciente.Easi[fecha] &&
                                      paciente.Easi[fecha].easyScore &&
                                      paciente.Easi[fecha].easyScore < 50
                                    ? "Moderada"
                                    : "Grave"}
                              </h3>
                              {
                                //JSON.stringify(paciente.Scorad[fecha])
                              }
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    ))}
                </Grid>
              </Box>
            </Dialog>
          </Container>
        ) : null}
        <Footer
          title="Footer"
          description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
        />
      </>
    );

  if (idescala === "scorad")
    return (
      <>
        <CssBaseline />
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: 12,
            width: "100%",
            backgroundColor: "transparent",
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h1 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.titulo}
          </h1>
          <h2 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.subtitulo}
          </h2>
        </Box>
        {paso && paso === 1 ? (
          <Container maxWidth="lg">
            <h1
              style={{
                color: commonColors.morado,
                borderColor: commonColors.morado,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
              }}
            >
              Ingresar datos del paciente
            </h1>
            <div style={{ display: "flex" }}>
              <div className="h3-triangle">
                <h3 style={{ margin: 0 }}>
                  {paciente.nombre} {paciente.apellido}
                </h3>
              </div>
              <div className="triangle" />
            </div>
            <h3>Indica áreas afectadas: </h3>

            <Box
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                padding: { xs: 0, md: 10 },
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Grid container spacing={2}>
                <Grid item md={2}>
                  {Object.keys(zonasAfectadas2)
                    .reverse()
                    .map((zona) => (
                      <p style={{ textTransform: "capitalize" }}>
                        {zona}:
                        <Slider
                          step={0.5}
                          marks={[
                            { value: 0, label: "0" },
                            {
                              value: parseInt(zonasAfectadas2[zona]),
                              label: zonasAfectadas2[zona] + "%",
                            },
                          ]}
                          max={
                            zona === "espalda"
                              ? 18
                              : zona === "pierna izquierda posterior"
                                ? 9
                                : zona === "pierna derecha posterior"
                                  ? 9
                                  : zona === "brazo derecho posterior"
                                    ? 4.5
                                    : zona === "brazo izquierdo posterior"
                                      ? 4.5
                                      : zona === "cabeza y nuca posterior"
                                        ? 4.5
                                        : 4.5
                          }
                          color="secondary"
                          size="small"
                          aria-label="Volume"
                          value={zonasAfectadas2[zona]}
                          onChange={(event, newValue) => {
                            set_zonasAfectadas2({
                              ...zonasAfectadas2,
                              [zona]: newValue,
                            });
                          }}
                        />
                      </p>
                    ))}
                  {Object.keys(zonasAfectadas)
                    .reverse()
                    .map((zona) => (
                      <p style={{ textTransform: "capitalize" }}>
                        {zona}:
                        <Slider
                          step={0.5}
                          marks={[
                            { value: 0, label: "0" },
                            {
                              value: parseInt(zonasAfectadas[zona]),
                              label: zonasAfectadas[zona] + "%",
                            },
                          ]}
                          max={
                            zona === "cabeza y cuello"
                              ? 4.5
                              : zona === "brazo izquierdo"
                                ? 4.5
                                : zona === "brazo derecho"
                                  ? 4.5
                                  : zona === "pierna derecha"
                                    ? 9
                                    : zona === "pierna izquierda"
                                      ? 9
                                      : zona === "cadera"
                                        ? 1
                                        : zona
                                          ? 18
                                          : 4.5
                          }
                          color="secondary"
                          size="small"
                          aria-label="Volume"
                          value={zonasAfectadas[zona]}
                          onChange={(event, newValue) => {
                            set_zonasAfectadas({
                              ...zonasAfectadas,
                              [zona]: newValue,
                            });
                          }}
                        />
                      </p>
                    ))}
                </Grid>
                <Grid
                  item
                  md={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h3 style={{ margin: 0 }}>Frente</h3>
                  <HombreMap
                    zonasAfectadas={zonasAfectadas}
                    calcular={calcular}
                    dir={
                      paciente.genero && paciente.genero === "f"
                        ? "bodymujer"
                        : "bodyhombre"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h3 style={{ margin: 0 }}>Espalda</h3>
                  <HombreMapPosterior
                    zonasAfectadas2={zonasAfectadas2}
                    calcular2={calcular2}
                    dir={
                      paciente.genero && paciente.genero === "f"
                        ? "bodymujer"
                        : "bodyhombre"
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setPaso(2);
                    }}
                    href=""
                    style={{
                      cursor: "pointer",
                      border: "none",
                      width: "97px",
                      height: "100px",
                      backgroundColor: "transparent",
                      backgroundImage: `url(${Next})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        ) : paso && paso === 2 ? (
          <Container maxWidth="lg">
            <h1
              style={{
                color: commonColors.morado,
                borderColor: commonColors.morado,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
              }}
            >
              Ingresar datos del paciente
            </h1>
            <div style={{ display: "flex" }}>
              <div className="h3-triangle">
                <h3 style={{ margin: 0 }}>
                  {paciente.nombre} {paciente.apellido}
                </h3>
              </div>
              <div className="triangle" />
            </div>
            <h3>Indica la intencidad: </h3>
            <Box
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                padding: { xs: 0, md: 10 },
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Grid container spacing={5}>
                <Grid
                  item
                  md={3}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#D2D1D1",
                      width: "100%",
                      padding: "14px",
                      margin: "2px",
                    }}
                  >
                    Xerosis
                  </div>
                </Grid>
                <Grid
                  item
                  md={9}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={0.5}>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, xerosis: 0 });
                      }}
                    >
                      <img
                        className={intensidad.xerosis == 0 ? "selected" : ""}
                        src={require("../assets/Xerosis-0.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, xerosis: 1 });
                      }}
                    >
                      <img
                        className={intensidad.xerosis == 1 ? "selected" : ""}
                        src={require("../assets/Xerosis-1.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, xerosis: 2 });
                      }}
                    >
                      <img
                        className={intensidad.xerosis == 2 ? "selected" : ""}
                        src={require("../assets/Xerosis-2.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, xerosis: 3 });
                      }}
                    >
                      <img
                        className={intensidad.xerosis == 3 ? "selected" : ""}
                        src={require("../assets/Xerosis-3.png")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#D2D1D1",
                      width: "100%",
                      padding: "14px",
                      margin: "2px",
                    }}
                  >
                    Eritema
                  </div>
                </Grid>
                <Grid
                  item
                  md={9}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={0.5}>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, eritema: 0 });
                      }}
                    >
                      <img
                        className={intensidad.eritema == 0 ? "selected" : ""}
                        src={require("../assets/Eritema-0.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, eritema: 1 });
                      }}
                    >
                      <img
                        className={intensidad.eritema == 1 ? "selected" : ""}
                        src={require("../assets/Eritema-1.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, eritema: 2 });
                      }}
                    >
                      <img
                        className={intensidad.eritema == 2 ? "selected" : ""}
                        src={require("../assets/Eritema-2.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, eritema: 3 });
                      }}
                    >
                      <img
                        className={intensidad.eritema == 3 ? "selected" : ""}
                        src={require("../assets/Eritema-3.png")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#D2D1D1",
                      width: "100%",
                      padding: "14px",
                      margin: "2px",
                    }}
                  >
                    Edema / Elevación
                  </div>
                </Grid>
                <Grid
                  item
                  md={9}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={0.5}>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, edemaElevacion: 0 });
                      }}
                    >
                      <img
                        className={
                          intensidad.edemaElevacion == 0 ? "selected" : ""
                        }
                        src={require("../assets/Edema-Elevacion-0.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, edemaElevacion: 1 });
                      }}
                    >
                      <img
                        className={
                          intensidad.edemaElevacion == 1 ? "selected" : ""
                        }
                        src={require("../assets/Edema-Elevacion-1.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, edemaElevacion: 2 });
                      }}
                    >
                      <img
                        className={
                          intensidad.edemaElevacion == 2 ? "selected" : ""
                        }
                        src={require("../assets/Edema-Elevacion-2.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, edemaElevacion: 3 });
                      }}
                    >
                      <img
                        className={
                          intensidad.edemaElevacion == 3 ? "selected" : ""
                        }
                        src={require("../assets/Edema-Elevacion-3.png")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#D2D1D1",
                      width: "100%",
                      padding: "14px",
                      margin: "2px",
                    }}
                  >
                    Exudados / Costras
                  </div>
                </Grid>
                <Grid
                  item
                  md={9}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={0.5}>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, exudadosCostras: 0 });
                      }}
                    >
                      <img
                        className={
                          intensidad.exudadosCostras == 0 ? "selected" : ""
                        }
                        src={require("../assets/Exudados-Costras-0.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, exudadosCostras: 1 });
                      }}
                    >
                      <img
                        className={
                          intensidad.exudadosCostras == 1 ? "selected" : ""
                        }
                        src={require("../assets/Exudados-Costras-1.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, exudadosCostras: 2 });
                      }}
                    >
                      <img
                        className={
                          intensidad.exudadosCostras == 2 ? "selected" : ""
                        }
                        src={require("../assets/Exudados-Costras-2.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, exudadosCostras: 3 });
                      }}
                    >
                      <img
                        className={
                          intensidad.exudadosCostras == 3 ? "selected" : ""
                        }
                        src={require("../assets/Exudados-Costras-3.png")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#D2D1D1",
                      width: "100%",
                      padding: "14px",
                      margin: "2px",
                    }}
                  >
                    Excoriaciones
                  </div>
                </Grid>
                <Grid
                  item
                  md={9}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={0.5}>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, exoriaciones: 0 });
                      }}
                    >
                      <img
                        className={
                          intensidad.exoriaciones == 0 ? "selected" : ""
                        }
                        src={require("../assets/Excoriaciones-0.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, exoriaciones: 1 });
                      }}
                    >
                      <img
                        className={
                          intensidad.exoriaciones == 1 ? "selected" : ""
                        }
                        src={require("../assets/Excoriaciones-1.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, exoriaciones: 2 });
                      }}
                    >
                      <img
                        className={
                          intensidad.exoriaciones == 2 ? "selected" : ""
                        }
                        src={require("../assets/Excoriaciones-2.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, exoriaciones: 3 });
                      }}
                    >
                      <img
                        className={
                          intensidad.exoriaciones == 3 ? "selected" : ""
                        }
                        src={require("../assets/Excoriaciones-3.png")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#D2D1D1",
                      width: "100%",
                      padding: "14px",
                      margin: "2px",
                    }}
                  >
                    Liquenificación
                  </div>
                </Grid>
                <Grid
                  item
                  md={9}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={0.5}>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, liquenificacion: 0 });
                      }}
                    >
                      <img
                        className={
                          intensidad.liquenificacion == 0 ? "selected" : ""
                        }
                        src={require("../assets/Liquenificacion-0.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, liquenificacion: 1 });
                      }}
                    >
                      <img
                        className={
                          intensidad.liquenificacion == 1 ? "selected" : ""
                        }
                        src={require("../assets/Liquenificacion-1.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, liquenificacion: 2 });
                      }}
                    >
                      <img
                        className={
                          intensidad.liquenificacion == 2 ? "selected" : ""
                        }
                        src={require("../assets/Liquenificacion-2.png")}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        set_intensidad({ ...intensidad, liquenificacion: 3 });
                      }}
                    >
                      <img
                        className={
                          intensidad.liquenificacion == 3 ? "selected" : ""
                        }
                        src={require("../assets/Liquenificacion-3.png")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setPaso(3);
                    }}
                    href=""
                    style={{
                      cursor: "pointer",
                      border: "none",
                      width: "97px",
                      height: "100px",
                      backgroundColor: "transparent",
                      backgroundImage: `url(${Next})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        ) : paso && paso === 3 ? (
          <Container maxWidth="lg">
            <h1
              style={{
                color: commonColors.morado,
                borderColor: commonColors.morado,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
              }}
            >
              Ingresar datos del paciente
            </h1>
            <div style={{ display: "flex" }}>
              <div className="h3-triangle">
                <h3 style={{ margin: 0 }}>
                  {paciente.nombre} {paciente.apellido}
                </h3>
              </div>
              <div className="triangle" />
            </div>
            <h3>Sintomas subjetivos: </h3>
            <Box
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: { xs: "5px", md: "40px" },
                width: "100%",
              }}
              display="flex"
            >
              <Grid
                sx={{ alignItems: "center", justifyContent: "center" }}
                container
                spacing={1}
              >
                <Box
                  style={{
                    backgroundColor: "#EAE8F1",
                    maxWidth: "600px",
                    width: "80%",
                    padding: "10px",
                  }}
                  display="block"
                >
                  <p>Trastorno del sueño: {trastornoValue}</p>
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ mb: 1 }}
                    alignItems="center"
                  >
                    <SentimentDissatisfiedOutlinedIcon
                      sx={{ fontSize: 40 }}
                      color="secondary"
                    />
                    <Slider
                      marks={
                        true /*[{value: 0, label: '0'},{value: 10, label: '10'}]*/
                      }
                      max={10}
                      color="secondary"
                      size="small"
                      aria-label="Volume"
                      value={trastornoValue}
                      onChange={(event, newValue) => {
                        set_trastornoValue(newValue);
                      }}
                    />
                    <SentimentSatisfiedAltOutlinedIcon
                      sx={{ fontSize: 40 }}
                      color="primary"
                    />
                  </Stack>
                  <p>Picazón: {picazon}</p>
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ mb: 1 }}
                    alignItems="center"
                  >
                    <SentimentDissatisfiedOutlinedIcon
                      sx={{ fontSize: 40 }}
                      color="secondary"
                    />
                    <Slider
                      marks={
                        true /*[{value: 0, label: '0'},{value: 10, label: '10'}]*/
                      }
                      max={10}
                      color="secondary"
                      size="small"
                      aria-label="Volume"
                      value={picazon}
                      onChange={(event, newValue) => {
                        set_picazon(newValue);
                      }}
                    />
                    <SentimentSatisfiedAltOutlinedIcon
                      sx={{ fontSize: 40 }}
                      color="primary"
                    />
                  </Stack>
                </Box>
              </Grid>
              <Button
                sx={{ margin: 2 }}
                variant="contained"
                onClick={() => {
                  //Calcular
                  const totalZonas = Object.values({
                    ...zonasAfectadas,
                    ...zonasAfectadas2,
                  }).reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0,
                  );
                  const totalIntensidad = Object.values(intensidad).reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0,
                  );
                  const subtotal =
                    totalZonas / 5 +
                    (totalIntensidad * 7) / 2 +
                    trastornoValue +
                    picazon;

                  set_totalScorad(subtotal);
                  //Lo guardo en el dataprovider
                  const hoy = new Date();
                  setCurrentKey(hoy.toISOString());
                  update("pacientes", {
                    id: paciente.id,
                    data: {
                      Scorad: {
                        ...paciente.Scorad,
                        [hoy.toISOString()]: {
                          total: subtotal,
                          zonasAfectadas: zonasAfectadas,
                          zonasAfectadas2: zonasAfectadas2,
                          intensidad: intensidad,
                          trastornoValue: trastornoValue,
                          picazon: picazon,
                          updateAt: new Date(),
                        },
                      },
                    },
                    previousData: paciente,
                  });
                  setPaso(4);
                }}
              >
                Calcular
              </Button>
            </Box>
          </Container>
        ) : paso && currentKey && paso === 4 ? (
          <Container maxWidth="lg">
            <h1
              style={{
                color: commonColors.morado,
                borderColor: commonColors.morado,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
              }}
            >
              Diagnóstico PO SCORAD
            </h1>
            <div style={{ display: "flex", marginBottom: 10 }}>
              <div className="h3-triangle">
                <h3 style={{ margin: 0 }}>
                  {paciente.nombre} {paciente.apellido}
                </h3>
              </div>
              <div className="triangle" />
            </div>
            <Box
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: { xs: "5px", md: "40px" },
                width: "100%",
              }}
              display="flex"
            >
              <Grid
                sx={{ alignItems: "center", justifyContent: "center" }}
                container
                spacing={1}
              >
                <Paper
                  sx={{
                    backgroundColor: commonColors.morado,
                    maxWidth: "800px",
                    width: "80%",
                    padding: "0",
                  }}
                  elevation={3}
                >
                  <h4 style={{ color: "white", textAlign: "center" }}>
                    {paciente.Scorad &&
                      paciente.Scorad[currentKey] &&
                      paciente.Scorad[currentKey]["updateAt"] &&
                      paciente.Scorad[currentKey]["updateAt"].toString()}
                  </h4>
                  <Box
                    sx={{
                      backgroundColor: "#EAE8F1",
                      maxWidth: "800px",
                      width: "100%",
                      padding: "0",
                    }}
                    display="block"
                  >
                    <Grid container display="flex">
                      <Grid
                        md={3}
                        sx={{ textAlign: "center", margin: "auto" }}
                        item
                      >
                        <img
                          src={
                            paciente.genero && paciente.genero === "f"
                              ? require("../assets/Mujer.png")
                              : require("../assets/Hombre.png")
                          }
                        />
                        <p>
                          {paciente.nombre} {paciente.apellido}
                        </p>
                      </Grid>
                      <Grid
                        sx={{ backgroundColor: "white", padding: "20px 40px" }}
                        md={9}
                        item
                      >
                        <h3 style={{ color: commonColors.morado }}>
                          Puntaje:{" "}
                          {paciente.Scorad &&
                            paciente.Scorad[currentKey] &&
                            paciente.Scorad[currentKey].total}
                        </h3>
                        <h3 style={{ color: commonColors.morado }}>
                          Gravedad:{" "}
                          {paciente.Scorad &&
                          paciente.Scorad[currentKey] &&
                          paciente.Scorad[currentKey].total !== undefined &&
                          paciente.Scorad[currentKey].total < 25
                            ? "Leve"
                            : paciente &&
                                paciente.Scorad &&
                                paciente.Scorad[currentKey] &&
                                paciente.Scorad[currentKey].total > 25 &&
                                paciente.Scorad[currentKey].total < 50
                              ? "Moderada"
                              : "Grave"}
                        </h3>
                        <Link
                          onClick={() => handleClickOpen("paciente")}
                          style={{
                            backgroundColor: "#D2D1D1",
                            width: "100%",
                            padding: "5px 20px 5px 20px",
                            margin: "2px",
                            display: "flex",
                            justifyContent: "space-between",
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          Ir a histórico{" "}
                          <img src={require("../assets/Ir-a-historico.png")} />
                        </Link>
                        <Link
                          onClick={() => {
                            var pri =
                              document.getElementById(
                                "ifmcontentstoprint",
                              ).contentWindow;
                            pri.document.open();
                            pri.document
                              .write(`<style type="text/css" media="print" />
                                  body {font-size: 8pt; font-family:'Arial','Times New Roman',Times,serif;}
                                  table { page-break-inside: avoid;page-break-after:auto }
                                  tr    { page-break-inside:avoid; page-break-after:auto }
                                  td    { page-break-inside:avoid; page-break-after:auto; font-size: 8pt !important; font-family:'Arial','Times New Roman',Times,serif; }
                                  thead { display:table-header-group }
                                  tfoot { display:table-footer-group }</style>`);
                            pri.document.write(
                              document.getElementById("toprint").innerHTML,
                            );
                            pri.document.close();
                            pri.focus();
                            pri.print();
                          }}
                          style={{
                            backgroundColor: "#D2D1D1",
                            width: "100%",
                            padding: "5px 20px 5px 20px",
                            margin: "2px",
                            display: "flex",
                            justifyContent: "space-between",
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          Descargar PDF{" "}
                          <img src={require("../assets/Descargar-PDF.png")} />
                        </Link>
                        <Link
                          style={{
                            backgroundColor: "#D2D1D1",
                            width: "100%",
                            padding: "5px 20px 5px 20px",
                            margin: "2px",
                            display: "flex",
                            justifyContent: "space-between",
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          Enviar correo{" "}
                          <img src={require("../assets/Enviar-correo.png")} />
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Button
                sx={{ margin: 2 }}
                variant="contained"
                onClick={() => {
                  setPaso(1);
                  navigate(`/paciente/${paciente.id}/${idescala}`);
                }}
              >
                Nuevo Diagnóstico
              </Button>
            </Box>
            <iframe
              title="pdf"
              id="ifmcontentstoprint"
              className="pdfIframe"
            ></iframe>
            <Box id="toprint">
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  justifyContent: "space-between",
                  width: "100%",
                  backgroundColor: commonColors.morado,
                }}
              >
                <img src={require("../assets/Logo-Dermatopic.png")} />
                <img src={require("../assets/logoasoderm.jpg")} />
              </div>
              <Box>
                <h1
                  style={{
                    color: commonColors.morado,
                    borderColor: commonColors.morado,
                    borderBottomWidth: 2,
                    borderBottomStyle: "solid",
                  }}
                >
                  Reporte PO SCORAD
                </h1>
              </Box>
              <table style={{ border: "none", width: "100%" }}>
                <tr>
                  <td style={{ padding: 5 }}>Nombre</td>
                  <td>Apellido</td>
                  <td>Edad</td>
                </tr>
                <tr>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#D2D1D1",
                        width: "100%",
                        padding: "5px 20px 5px 20px",
                        margin: "2px",
                      }}
                    >
                      {paciente.nombre}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#D2D1D1",
                        width: "100%",
                        padding: "5px 20px 5px 20px",
                        margin: "2px",
                      }}
                    >
                      {paciente.apellido}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#D2D1D1",
                        width: "100%",
                        padding: "5px 20px 5px 20px",
                        margin: "2px",
                      }}
                    >
                      {
                        //getAge(paciente.fecha_de_nacimiento.toString())
                        paciente.fecha_de_nacimiento
                          ? getAge(paciente.fecha_de_nacimiento.split("T")[0])
                          : null
                      }
                    </div>
                  </td>
                </tr>
              </table>
              {paciente && paciente.Scorad && paciente.Scorad[currentKey] && (
                <div style={{ textAlign: "center" }}>
                  <ImgHistorico zona={paciente.Scorad[currentKey]} />
                </div>
              )}
              <div
                style={{
                  width: "100%",
                  backgroundColor: commonColors.moradoClaro,
                  padding: 5,
                }}
              >
                <h2>
                  Puntaje:{" "}
                  {paciente.Scorad &&
                    paciente.Scorad[currentKey] &&
                    paciente.Scorad[currentKey].total}
                </h2>
                <h2>
                  Gravedad:{" "}
                  {paciente.Scorad &&
                  paciente.Scorad[currentKey] &&
                  paciente.Scorad[currentKey].total !== undefined &&
                  paciente.Scorad[currentKey].total < 25
                    ? "Leve"
                    : paciente &&
                        paciente.Scorad &&
                        paciente.Scorad[currentKey] &&
                        paciente.Scorad[currentKey].total > 25 &&
                        paciente.Scorad[currentKey].total < 50
                      ? "Moderada"
                      : "Grave"}
                </h2>
              </div>
            </Box>
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar color="secondary" sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Histórico
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box
                sx={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: { xs: "5px", md: "40px" },
                  width: "100%",
                }}
                display="flex"
              >
                <Grid
                  sx={{ alignItems: "center", justifyContent: "center" }}
                  container
                  spacing={1}
                >
                  {paciente &&
                    paciente.Scorad &&
                    Object.keys(paciente.Scorad).map((fecha) => (
                      <Paper
                        sx={{
                          backgroundColor: commonColors.morado,
                          maxWidth: "800px",
                          width: "80%",
                          padding: "0",
                          marginBottom: 5,
                        }}
                        elevation={3}
                      >
                        <h4 style={{ color: "white", textAlign: "center" }}>
                          {paciente.Scorad &&
                            paciente.Scorad[fecha] &&
                            paciente.Scorad[fecha]["updateAt"] &&
                            paciente.Scorad[fecha]["updateAt"].toString()}
                        </h4>
                        <Box
                          sx={{
                            backgroundColor: "#EAE8F1",
                            maxWidth: "800px",
                            width: "100%",
                            padding: "0",
                          }}
                          display="block"
                        >
                          <Grid container display="flex">
                            <Grid
                              md={3}
                              sx={{ textAlign: "center", margin: "auto" }}
                              item
                            >
                              <img
                                src={
                                  paciente.genero && paciente.genero === "f"
                                    ? require("../assets/Mujer.png")
                                    : require("../assets/Hombre.png")
                                }
                              />
                              <p>
                                {paciente.nombre} {paciente.apellido}
                              </p>
                            </Grid>
                            <Grid
                              sx={{
                                backgroundColor: "white",
                                padding: "20px 40px",
                              }}
                              md={9}
                              item
                            >
                              <h3 style={{ color: commonColors.morado }}>
                                Puntaje:{" "}
                                {paciente.Scorad &&
                                  paciente.Scorad[fecha] &&
                                  paciente.Scorad[fecha].total}
                              </h3>
                              <h3 style={{ color: commonColors.morado }}>
                                Gravedad:{" "}
                                {paciente.Scorad &&
                                paciente.Scorad[fecha] &&
                                paciente.Scorad[fecha].total !== undefined &&
                                paciente.Scorad[fecha].total < 25
                                  ? "Leve"
                                  : paciente &&
                                      paciente.Scorad &&
                                      paciente.Scorad[fecha] &&
                                      paciente.Scorad[fecha].total > 25 &&
                                      paciente.Scorad[fecha].total < 50
                                    ? "Moderada"
                                    : "Grave"}
                              </h3>
                              {
                                // JSON.stringify(paciente.Scorad[fecha])
                              }
                              <ImgHistorico zona={paciente.Scorad[fecha]} />
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    ))}
                </Grid>
              </Box>
            </Dialog>
          </Container>
        ) : null}
        <Footer
          title="Footer"
          description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
        />
      </>
    );

  if (idescala === "hads")
    return (
      <>
        <Dialog onClose={() => setOpen(false)} open={open}>
          <DialogTitle>Puntaje</DialogTitle>
          <Box
            display="flex"
            sx={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 25px 50px",
              minWidth: 200,
              width: "100%",
            }}
          >
            <h1>{encuestaValue}</h1>
            <p>
              Nivel de ansiedad y depresión: &nbsp;
              <strong>
                {encuestaValue < 8 && `No significativo`}
                {encuestaValue > 7 &&
                  encuestaValue < 11 &&
                  `Clínicamente significativo`}
                {encuestaValue > 10 && `Clínicamente Severo`}
              </strong>
            </p>
            <Button
              sx={{ width: 100, margin: "auto" }}
              variant="contained"
              onClick={() => setOpen(false)}
            >
              Cerrar
            </Button>
          </Box>
        </Dialog>
        <CssBaseline />
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: 12,
            width: "100%",
            backgroundColor: "transparent",
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h1 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.titulo}
          </h1>
          <h2 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.subtitulo}
          </h2>
        </Box>
        <Container maxWidth="lg">
          <p>
            El objetivo de este cuestionario consiste en determinar qué efecto
            ha tenido su problema de la piel en su vida. Por favor seleccione la
            opción que corresponda a cada pregunta.
          </p>
          <Box
            component="form"
            onSubmit={(event) => {
              event.preventDefault();
              const fdata = new FormData(event.currentTarget);
              const form = fdata ? Array.from(fdata.entries()) : null;
              const hoy = new Date();
              // console.log(hoy.toISOString())
              update("pacientes", {
                id: paciente.id,
                data: {
                  Hads: {
                    ...paciente.Hads,
                    [hoy.toISOString()]: form,
                  },
                },
                previousData: paciente,
              });
              setMyForm(form);
              let values = {};
              let total = 0;
              for (const [name, value] of form) {
                values[name] = value;
                total += parseInt(value);
              }
              set_encuestaValue(total);
              setMyForm(values);
              setOpen(true);
            }}
            noValidate
            sx={{ mt: 1 }}
          >
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          1. Me siento tenso(a) o nervioso(a):
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="1"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nunca"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="De vez en cuando"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Gran parte del día"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Todo el día"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          2. Sigo disfrutando de las cosas como siempre:
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="2"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Igual que antes"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="No tanto como antes"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Solamente un poco"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Ya no disfruto como antes"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>

            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          3. Siento una especie de temor como si algo malo fuera
                          a suceder:
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="3"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No siento nada de eso"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Sí, pero no me preocupa"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Sí, pero no muy intenso"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Sí y muy intenso"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          4. Soy capaz de reírme y ver el lado gracioso de las
                          cosas:
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="4"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Igual que siempre"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Actualmente, algo menos"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Actualmente, mucho menos"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Actualmente, nada"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          5. Tengo la cabeza llena de preocupaciones:
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="5"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nunca"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="De vez en cuando"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Gran parte del día"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Todo el día"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          6. Me siento lento (a) y torpe:
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="6"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nunca"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="De vez en cuando"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Gran parte del día"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Todo el día"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>

            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          7. Soy capaz de permanecer sentado(a) tranquilo(a) y
                          relajado(a):
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="7"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Siempre"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Frecuentemente"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Raras veces"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Nunca"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          8. Me siento alegre:
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="8"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Siempre"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Frecuentemente"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Raras veces"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Nunca"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          9. Experimento una desagradable sensación de “nervios
                          y hormigueos” en el estómago:
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="9"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Siempre"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Frecuentemente"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Raras veces"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Nunca"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          10. He perdido el interés por mi aspecto personal:
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="10"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Completamente"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Frecuentemente"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Rara vez"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Nunca"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          11. Me siento inquieto(a) como si no pudiera parar de
                          moverme:
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="11"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Siempre"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Frecuentemente"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Raras veces"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Nunca"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          12. Espero las cosas con ilusión:
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="12"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Siempre"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Frecuentemente"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Rara vez"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Nunca"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          13. Experimento de repente sensaciones de gran
                          angustia o temor:
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="13"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Siempre"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Frecuentemente"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Raras veces"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Nunca"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          14. Soy capaz de disfrutar con un buen libro o con un
                          buen programa de radio o televisión:
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="14"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Siempre"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Frecuentemente"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Rara vez"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Nunca"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Button type="submit">
                <img src={require("../assets/Calculadora.png")} />
              </Button>
            </Box>
          </Box>
        </Container>
      </>
    );

  if (idescala === "dlqi")
    return (
      <>
        <Dialog onClose={() => setOpen(false)} open={open}>
          <DialogTitle>Puntaje</DialogTitle>
          <Box
            display="flex"
            sx={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 25px 50px",
              minWidth: 200,
              width: "100%",
            }}
          >
            <h1>{encuestaValue}</h1>
            <p>
              Impacto en la calidad de vida: &nbsp;
              <strong>
                {encuestaValue < 3 && `No impacta`}
                {encuestaValue > 2 && encuestaValue < 8 && `Leve`}
                {encuestaValue > 7 && encuestaValue < 17 && `Moderado`}
                {encuestaValue > 16 && encuestaValue < 25 && `Grave`}
                {encuestaValue > 26 && `Muy Grave`}
              </strong>
            </p>
            {/*<p><strong>A: </strong>{Object.values(myform).filter(item => item == '0').reduce((accumulator, currentValue) => accumulator + 1 , 0)}</p>
        <p><strong>B: </strong>{Object.values(myform).filter(item => item == '1').reduce((accumulator, currentValue) => accumulator + 2 , 0)}</p>
        <p><strong>C: </strong>{Object.values(myform).filter(item => item == '2').reduce((accumulator, currentValue) => accumulator + 3 , 0)}</p>
        <p><strong>D: </strong>{Object.values(myform).filter(item => item == '3').reduce((accumulator, currentValue) => accumulator + 4 , 0)}</p>*/}
            <Button
              sx={{ width: 100, margin: "auto" }}
              variant="contained"
              onClick={() => setOpen(false)}
            >
              Cerrar
            </Button>
          </Box>
        </Dialog>
        <CssBaseline />
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: 12,
            width: "100%",
            backgroundColor: "transparent",
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h1 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.titulo}
          </h1>
          <h2 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.subtitulo}
          </h2>
        </Box>
        <Container maxWidth="lg">
          <h1>Escala DLQI : para mayores de 19 años</h1>
          <p>
            Seleccione en qué medida, su problema de piel ha afectado a su vida
            durante la última semana. Marque la casilla correspondiente para
            cada pregunta.
          </p>
          <Box
            component="form"
            onSubmit={(event) => {
              event.preventDefault();
              const fdata = new FormData(event.currentTarget);
              const form = fdata ? Array.from(fdata.entries()) : null;
              const hoy = new Date();
              // console.log(hoy.toISOString())
              update("pacientes", {
                id: paciente.id,
                data: {
                  Dlqui: {
                    ...paciente.Dlqui,
                    [hoy.toISOString()]: form,
                  },
                },
                previousData: paciente,
              });
              setMyForm(form);
              let values = {};
              let total = 0;
              for (const [name, value] of form) {
                values[name] = value;
                total += parseInt(value);
              }
              set_encuestaValue(total);
              setMyForm(values);
              setOpen(true);
            }}
            noValidate
            sx={{ mt: 1 }}
          >
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          1. Durante los últimos 7 días, ¿ha sentido picor,
                          dolor o escozor en la piel?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="1"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Bastante"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          2. Durante los últimos 7 días, ¿se ha sentido
                          incómodo/a o cohibido/a debido a sus problemas de
                          piel?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="2"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Bastante"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>

            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          3. Durante los últimos 7 días, ¿le han molestado sus
                          problemas de piel para hacer la compra u ocuparse de
                          la casa (o del jardín)?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="3"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Bastante"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          4. Durante los últimos 7 días, ¿han influido sus
                          problemas de piel en la elección de la ropa que lleva?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="4"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Bastante"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          5. Durante los últimos 7 días, ¿han influido sus
                          problemas de piel en cualquier actividad social o
                          recreativa?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="5"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Bastante"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          6. Durante los últimos 7 días, ¿ha tenido dificultades
                          para hacer deporte debido a sus problemas de piel?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="6"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Bastante"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>

            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          7. Durante los últimos 7 días, ¿sus problemas de piel
                          le han impedido totalmente trabajar o estudiar?
                          <br />
                          Si la respuesta es “No”: Durante los últimos 7 días,
                          ¿le han molestado sus problemas de piel en su trabajo
                          o en sus estudios?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="7"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Bastante"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          8. Durante los últimos 7 días, ¿sus problemas de piel
                          le han ocasionado dificultades con su pareja, amigos
                          íntimos o familiares?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="8"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Bastante"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          9. Durante los últimos 7 días, ¿le han molestado sus
                          problemas de piel en su vida sexual?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="9"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Bastante"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          10. Durante los últimos 7 días, ¿el tratamiento de su
                          piel le ha ocasionado problemas, por ejemplo
                          ocupándole demasiado tiempo o ensuciando su domicilio?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="10"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Bastante"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Button type="submit">
                <img src={require("../assets/Calculadora.png")} />
              </Button>
            </Box>
          </Box>
        </Container>
      </>
    );

  if (idescala === "poem")
    return (
      <>
        <Dialog onClose={() => setOpen(false)} open={open}>
          <DialogTitle>Puntaje</DialogTitle>
          <Box
            display="flex"
            sx={{
              flexDirection: "column",
              padding: "0 25px 50px",
              minWidth: 200,
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1>{encuestaValue}</h1>
            <p>
              Nivel de Eccema Atópico: &nbsp;
              <strong>
                {encuestaValue < 3 && `Aclarado`}
                {encuestaValue > 2 && encuestaValue < 8 && `Leve`}
                {encuestaValue > 7 && encuestaValue < 17 && `Moderado`}
                {encuestaValue > 16 && encuestaValue < 25 && `Grave`}
                {encuestaValue > 26 && `Muy Grave`}
              </strong>
            </p>
            {/*<p><strong>A: </strong>{Object.values(myform).filter(item => item == '0').reduce((accumulator, currentValue) => accumulator + 1 , 0)}</p>
        <p><strong>B: </strong>{Object.values(myform).filter(item => item == '1').reduce((accumulator, currentValue) => accumulator + 2 , 0)}</p>
        <p><strong>C: </strong>{Object.values(myform).filter(item => item == '2').reduce((accumulator, currentValue) => accumulator + 3 , 0)}</p>
        <p><strong>D: </strong>{Object.values(myform).filter(item => item == '3').reduce((accumulator, currentValue) => accumulator + 4 , 0)}</p>*/}
            <Button
              sx={{ width: 100, margin: "auto" }}
              variant="contained"
              onClick={() => setOpen(false)}
            >
              Cerrar
            </Button>
          </Box>
        </Dialog>
        <CssBaseline />
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: 12,
            width: "100%",
            backgroundColor: "transparent",
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h1 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.titulo}
          </h1>
          <h2 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.subtitulo}
          </h2>
        </Box>
        <Container maxWidth="lg">
          <h1>Encuesta POEM</h1>
          <p>
            Seleccione una respuesta para cada una de las siete preguntas sobre
            su eczema, que aparecen a continuación.
          </p>
          <Box
            component="form"
            onSubmit={(event) => {
              event.preventDefault();
              const fdata = new FormData(event.currentTarget);
              const form = fdata ? Array.from(fdata.entries()) : null;
              const hoy = new Date();
              let values = {};
              let total = 0;
              for (const [name, value] of form) {
                values[name] = value;
                total += parseInt(value);
              }

              // console.log(values)

              set_encuestaValue(total);
              setMyForm(form);
              setMyForm(values);
              setOpen(true);
              // return;

              update("pacientes", {
                id: paciente.id,
                data: {
                  Poem: {
                    ...paciente.Poem,
                    [hoy.toISOString()]: form,
                  },
                },
                previousData: paciente,
              });
            }}
            noValidate
            sx={{ mt: 1 }}
          >
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          1. Durante la última semana, ¿cuántos días ha tenido
                          comezón (picazón) en la piel a causa de su eczema?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="1"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Ningún día"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1 a 2 días"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="3 a 4 días"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="5 a 6 días"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Todos los días"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          2. Durante la última semana, ¿cuántas noches se ha
                          interrumpido su sueño a causa de su eczema?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="2"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Ningún día"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1 a 2 días"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="3 a 4 días"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="5 a 6 días"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Todos los días"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>

            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          3. Durante la última semana, ¿cuántos días le ha
                          sangrado la piel a causa de su eczema?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="3"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Ningún día"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1 a 2 días"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="3 a 4 días"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="5 a 6 días"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Todos los días"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          4. Durante la última semana, ¿cuántos días le ha
                          supurado o exudado líquido transparente de la piel a
                          causa de su eczema?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="4"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Ningún día"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1 a 2 días"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="3 a 4 días"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="5 a 6 días"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Todos los días"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          5. Durante la última semana, ¿cuántos días ha tenido
                          la piel agrietada a causa de su eczema?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="5"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Ningún día"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1 a 2 días"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="3 a 4 días"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="5 a 6 días"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Todos los días"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          6. Durante la última semana, ¿cuántos días ha tenido
                          descamación de la piel a causa de su eczema?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="6"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Ningún día"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1 a 2 días"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="3 a 4 días"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="5 a 6 días"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Todos los días"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>

            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          7. Durante la última semana, ¿cuántos días ha sentido
                          a piel reseca o áspera a causa de su eczema?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="7"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Ningún día"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1 a 2 días"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="3 a 4 días"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="5 a 6 días"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Todos los días"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Button type="submit">
                <img src={require("../assets/Calculadora.png")} />
              </Button>
            </Box>
          </Box>
        </Container>
      </>
    );

  if (idescala === "adct")
    return (
      <>
        <Dialog onClose={() => setOpen(false)} open={open}>
          <DialogTitle>Puntaje</DialogTitle>
          <Box
            display="flex"
            sx={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 25px 50px",
              minWidth: 200,
              width: "100%",
            }}
          >
            <h1>{encuestaValue}</h1>
            <p>
              Nivel de Dermatitis Atópico: &nbsp;
              <strong>
                {/*encuestaValue < 3 && `Limpio`}
                {encuestaValue > 2 && encuestaValue < 8 && `Leve`}
                {encuestaValue > 7 && encuestaValue < 17 && `Moderado`}
                {encuestaValue > 16 && encuestaValue < 25 && `Grave`}
                {encuestaValue > 26 && `Muy Grave`*/}
                {encuestaValue < 7 ? `Controlado` : `No Controlado`}
              </strong>
            </p>
            <Button
              sx={{ width: 100, margin: "auto" }}
              variant="contained"
              onClick={() => setOpen(false)}
            >
              Cerrar
            </Button>
          </Box>
        </Dialog>
        <CssBaseline />
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: 12,
            width: "100%",
            backgroundColor: "transparent",
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h1 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.titulo}
          </h1>
          <h2 style={{ color: "white", textAlign: "center" }}>
            {escala && escala.subtitulo}
          </h2>
        </Box>
        <Container maxWidth="lg">
          <h1>Encuesta ADCT</h1>
          <p>
            Seleccione una respuesta para cada una de las siete preguntas sobre
            su eczema, que aparecen a continuación.
          </p>
          <Box
            component="form"
            onSubmit={(event) => {
              event.preventDefault();
              const fdata = new FormData(event.currentTarget);
              const form = fdata ? Array.from(fdata.entries()) : null;
              const hoy = new Date();
              // console.log(hoy.toISOString())
              update("pacientes", {
                id: paciente.id,
                data: {
                  ADCT: {
                    ...paciente.ADCT,
                    [hoy.toISOString()]: form,
                  },
                },
                previousData: paciente,
              });
              setMyForm(form);
              let values = {};
              let total = 0;
              for (const [name, value] of form) {
                values[name] = value;
                total += parseInt(value);
              }
              set_encuestaValue(total);
              setMyForm(values);
              setOpen(true);
            }}
            noValidate
            sx={{ mt: 1 }}
          >
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          1. ¿Cómo calificarías los síntomas relacionados con tu
                          eccema (por ejemplo, picor, sequedad de piel o
                          sarpullido) durante la última semana?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="1"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Ninguno"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Leves"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Moderados"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Graves"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Muy Graves"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          2. ¿Cuántos días ha tenido episodios intensos de picor
                          debido a su eccema durante la última semana?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="2"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Ningún día"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1 a 2 días"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="3 a 4 días"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="5 a 6 días"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Todos los días"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>

            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          3. ¿Cuánto le ha molestado su eccema durante la última
                          semana?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="3"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Moderadamente"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Extremadamente"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          4. ¿Cuántas noches ha tenido problemas para conciliar
                          el sueño o permanecer dormido/a debido a su eccema
                          durante la última semana?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="4"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Ninguna"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1 a 2 noches"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="3 a 4 noches"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="5 a 6 noches"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Todas las noches"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box sx={{ flex: 1 }} mr={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#88226D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          5. ¿Cuánto ha afectado el eccema a sus actividades
                          diarias durante la última semana?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="5"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Moderadamente"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Extremadamente"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ flex: 1 }} ml={2}>
                <Paper
                  sx={{
                    width: "100%",
                    padding: "0",
                    marginBottom: 2,
                    borderRadius: 5,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      backgroundColor: "#E23E2D",
                      width: "100%",
                      padding: "20px 0 0",
                      borderRadius: 5,
                    }}
                    display="block"
                  >
                    <Box
                      sx={{ backgroundColor: "white", padding: "20px 40px" }}
                      item
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          6. ¿Cuánto ha afectado el eccema a su estado anímico o
                          emociones durante la última semana?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="6"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Nada"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Un poco"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Moderadamente"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Mucho"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Extremadamente"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                padding: "0 20px",
                width: "100%",
              }}
              display={{ xs: "block", md: "flex" }}
            >
              <Button type="submit">
                <img src={require("../assets/Calculadora.png")} />
              </Button>
            </Box>
          </Box>
        </Container>
      </>
    );
}

export default PacienteEdit;
