import logo from './logo.svg';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { Create, SimpleForm, TextInput, RadioButtonGroupInput, DateInput, useNotify, useRedirect, List, Datagrid, TextField,
          DateField, FunctionField, SimpleList, CreateButton, DeleteButton, DeleteWithConfirmButton } from 'react-admin';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import { Typography } from '@mui/material';
import {commonColors, escalas} from './siteSettings'
import Banner from './assets/Banner-Scorad.jpg'
import Imagen1 from './assets/Que-es-Dermatitis.jpg'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import { useMediaQuery } from '@mui/material';
// import MainFeaturedPost from './MainFeaturedPost';
// import FeaturedPost from './FeaturedPost';
// import Main from './Main';
// import Sidebar from './Sidebar';
import Footer from './Footer';
const defaultTheme = createTheme();

function Escala() {
  const navigate = useNavigate();
  const { idescala } = useParams();
  const escala = idescala ? escalas.find(item=> item.id === idescala) : null;
  const Banner = escala ? require(`./assets/${escala.banner}`) : require(`./assets/Banner-2.jpg`)
  const settings = {
    bannerTitle: "Una herramienta práctica para la evaluación clinimétrica de pacientes con Dermatitis Atópica"
  }
  return (
  	<>
      <CssBaseline />
      <Header />
      <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: { xs: 2, md: 12 },
            width: '100%',
            backgroundColor: 'transparent',
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover'
          }}
      >
        <h1 style={{color: 'white', textAlign: 'center'}}>{escala && escala.titulo}</h1>
        <h2 style={{color: 'white', textAlign: 'center'}}>{escala && escala.subtitulo}</h2>
      </Box>
        <Box sx={{ 
            flexDirection: 'row',
            justifyContent: 'center',
            padding: { xs: 2, md: 10 },
            width: '100%',
          }}
        display={{ xs: 'block', md: 'flex' }}
      >
        <Box 
          onClick={()=>navigate(`/escala/${idescala}/create`)}
          sx={{flex: 1, textAlign: 'center', cursor: 'pointer'}} mr={1}>
          <img src={require('./assets/Ingresar-paciente.jpg')}/>
        </Box>
        <Box 
          onClick={()=>navigate(`/escala/${idescala}/list`)}
          sx={{flex: 1, textAlign: 'center', cursor: 'pointer'}} mr={1}>
          <img src={require('./assets/Seleccionar-paciente.jpg')}/>
        </Box>
      </Box>
      <Footer
        title="Footer"
        description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
      />
    </>
  );
}

export function EscalaCreate (props) {
  const navigate = useNavigate();
  const { idescala } = useParams();
  const escala = idescala ? escalas.find(item=> item.id === idescala) : null;
  const Banner = escala ? require(`./assets/${escala.banner}`) : require(`./assets/Banner-2.jpg`)
  const settings = {
    bannerTitle: "Una herramienta práctica para la evaluación clinimétrica de pacientes con Dermatitis Atópica"
  }
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = (data) => {
        notify(`Paciente creado con éxito`); // default message is 'ra.notification.created'
        navigate(`/escala/${idescala}/list`)
    }

  return (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: { xs: 2, md: 12 },
            width: '100%',
            backgroundColor: 'transparent',
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'contain'
          }}
      >
        <h1 style={{color: 'white', textAlign: 'center'}}>{escala && escala.titulo}</h1>
        <h2 style={{color: 'white', textAlign: 'center'}}>{escala && escala.subtitulo}</h2>
      </Box>
      <Container maxWidth="lg">
        <h1 style={{color: commonColors.morado}}>Ingresar datos del paciente</h1>
        <Box sx={{ 
              flexDirection: 'column',
              justifyContent: 'center',
              padding: { xs: 2, md: 10 },
              width: '100%',
            }}
          display={{ xs: 'block', md: 'flex' }}
        >
          <Create resource="pacientes" mutationOptions={{ onSuccess }}>
              <SimpleForm>
                  <TextInput source="nombre" fullWidth />
                  <TextInput source="apellido" fullWidth />
                  <RadioButtonGroupInput source="genero" choices={[
                      { id: 'm', name: 'Masculino' },
                      { id: 'f', name: 'Femenino' },
                  ]} />
                  <DateInput source="fecha_de_nacimiento" />
              </SimpleForm>
          </Create>
        </Box>
      </Container>
      <Footer
        title="Footer"
        description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
      />
    </>
  );
}

export function EscalaList (props) {
  const navigate = useNavigate();
  const { idescala } = useParams();
  const escala = idescala ? escalas.find(item=> item.id === idescala) : null;
  const Banner = escala ? require(`./assets/${escala.banner}`) : require(`./assets/Banner-2.jpg`)
  const settings = {
    bannerTitle: "Una herramienta práctica para la evaluación clinimétrica de pacientes con Dermatitis Atópica"
  }
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = (data) => {
        notify(`Paciente creado con éxito`); // default message is 'ra.notification.created'
        navigate(`/escala/${idescala}/list`)
    }

  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const Empty = () => (
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            No haz creado pacientes en este dispositivo
        </Typography>
        <Typography variant="body1">
            Crea un paciente
        </Typography>
        <Button onClick={()=>navigate(`/escala/${idescala}/create`)} >CREAR</Button>
    </Box>
);

  return (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: { xs: 2, md: 12 },
            width: '100%',
            backgroundColor: 'transparent',
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'contain'
          }}
      >
        <h1 style={{color: 'white', textAlign: 'center'}}>{escala && escala.titulo}</h1>
        <h2 style={{color: 'white', textAlign: 'center'}}>{escala && escala.subtitulo}</h2>
      </Box>
      <Container maxWidth="lg">
        <h1 style={{color: commonColors.morado}}>Histórico / Diagnóstico</h1>
        <Box sx={{ 
              flexDirection: 'column',
              justifyContent: 'center',
              padding: { xs: 2, md: 10 },
              width: '100%',
            }}
          display={{ xs: 'block', md: 'flex' }}
        >
          <List resource="pacientes" exporter={false} empty={<Empty />}>
            {isSmall ? (
              <SimpleList
                linkType={false}
                  primaryText={record => `${record.nombre} ${record.apellido}`}
                  secondaryText={record => `${record.fecha_de_nacimiento}`}
                  tertiaryText={record => (<Box display="block">
                    <Button onClick={()=> navigate(`/paciente/${record.id}/${idescala}`)}>Diagnóstico</Button>
                    <Button onClick={()=> navigate(`/paciente/${record.id}/show/${idescala}`)}>Resultado</Button>
                  </Box>)}
              />
            ) : (
                <Datagrid bulkActionButtons={false}>
                  <TextField source="nombre" />
                  <TextField source="apellido" />
                  <DateField source="fecha_de_nacimiento" />
                  <FunctionField label="Nuevo diagnóstico" render={record => <Button onClick={()=> navigate(`/paciente/${record.id}/${idescala}`)}>Realizar Diagnóstico</Button>} />
                  <FunctionField label="Ver resultado" render={record => <Button onClick={()=> navigate(`/paciente/${record.id}/show/${idescala}`)}> Resultado</Button>} />
                  <DeleteWithConfirmButton
                    confirmContent="Estas seguiro de borrar al paciente?"
                  />
                </Datagrid>
            )}     
          </List>
        </Box>
      </Container>
      <Footer
        title="Footer"
        description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
      />
    </>
  );
}

export default Escala;
