function HombreMapPosterior({zonasAfectadas2, calcular2, dir}) {
	return (
		<>
			<img src={!Object.keys(zonasAfectadas2) || !Object.keys(zonasAfectadas2).length ? 
                            require(`../assets/${dir}/bodyback.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/brazosposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/derechoposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/derechonucaposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/izqposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/izqnucaposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/bodynuca.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/bodynucabrazos.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/bodynucabrazospiernader.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/bodynucabrazospiernaizq.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/posteriorcabezaespalda.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/posteriorcabezaespaldabrazoder.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/posteriorcabezaespaldabrazoizq.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/posteriorcabezaespaldapiernaizq.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/posteriormenosbrazos.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/posteriorcabezaespaldapiernader.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/bodyespalda.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernaderposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernadernucaposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernaizqposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernaizqnucaposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernaizqnucabrazoizqposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernaizqnucabrazoder.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernasposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernasbrazoderposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernasbrazoizqposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernasnucaposterior.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernasespalda.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/extremidadesposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/extremidadesposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernaizqbrazoder.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernaizqbrazoizq.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernaizqbrazos.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernaderbrazoizq.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernaderbrazoder.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernaderbrazos.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldapiernaizq.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldapiernader.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldabrazoizq.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldapiernaizqbrazoizq.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldapiernaderbrazoder.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldapiernaderbrazoizq.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldapiernaderbrazoizqbrazoder.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldapiernaizqbrazoderbrazoizq.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/menosnucaposterior.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/posterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/posteriormenosespalda.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/posteriormenosbrazoizq.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/posteriormenosbrazoder.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldabrazoder.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldapiernaizqbrazoder.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldabrazos.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldabrazosnuca.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldabrazosnucapiernaizq.png`)
                            : Object.keys(zonasAfectadas2).includes('espalda') && !Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/espaldabrazosnucapiernader.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernasnucabrazoizqposterior.png`)
                            : !Object.keys(zonasAfectadas2).includes('espalda') && Object.keys(zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require(`../assets/${dir}/piernasnucabrazoderposterior.png`)
                            :require(`../assets/${dir}/bodyback.png`)} usemap="#image-map2"/>

                  <map name="image-map2">
                      <area className="bodyParts" href="" alt="espalda" title="espalda" onClick={(e)=>{calcular2(e, 18)}} coords="58,147,165,146,162,296,114,331,63,300" shape="poly"/>
                      <area className="bodyParts" href="" alt="pierna izquierda posterior" title="pierna izquierda posterior" onClick={(e)=>{calcular2(e, 9)}} coords="59,306,47,395,50,501,58,627,86,626,98,502,106,397,106,369,104,335" shape="poly"/>
                      <area className="bodyParts" href="" alt="pierna derecha posterior" title="pierna derecha posterior" onClick={(e)=>{calcular2(e, 9)}} coords="118,338,159,305,174,367,165,631,139,632,116,376,117,373" shape="poly"/>
                      <area className="bodyParts" href="" alt="brazo izquierdo posterior" title="brazo izquierdo posterior" onClick={(e)=>{calcular2(e, 4.5)}} coords="46,130,30,144,27,178,22,205,25,240,17,277,16,335,30,334,46,243,55,186" shape="poly"/>
                      <area className="bodyParts" href="" alt="brazo derecho posterior" title="brazo derecho posterior" onClick={(e)=>{calcular2(e, 4.5)}} coords="169,127,190,144,197,180,201,202,197,242,209,274,208,335,193,333,176,243,166,183" shape="poly"/>
                      <area className="bodyParts" href="" alt="cabeza y nuca posterior" title="cabeza y nuca posterior" onClick={(e)=>{calcular2(e, 4.5)}} coords="89,20,81,48,87,83,100,101,126,100,141,78,142,63,138,32,126,13" shape="poly"/>
                  </map>
		</>
	)
}

export default HombreMapPosterior