function HombreMap ({zonasAfectadas, calcular, dir}) {
    return (
        <>
            <img style={{width: '284px', height: '728px', maxWidth: '284px !important'}} src={!Object.keys(zonasAfectadas) || !Object.keys(zonasAfectadas).length ? 
                            require(`../assets/${dir}/body.png`)
                            : Object.keys(zonasAfectadas).join() === 'torso' ?
                            require(`../assets/${dir}/bodytorso.png`)
                            : Object.keys(zonasAfectadas).join() === 'cabeza y cuello' ?
                            require(`../assets/${dir}/bodycabeza.png`)
                            : Object.keys(zonasAfectadas).join() === 'cadera' ?
                            require(`../assets/${dir}/bodycadera.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/bodycaderapiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/bodycaderapiernader.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/bodycaderapiernas.png`)
                            : Object.keys(zonasAfectadas).join() === 'brazo izquierdo' ?
                            require(`../assets/${dir}/bodybrazoizq.png`)
                            : Object.keys(zonasAfectadas).join() === 'brazo derecho' ?
                            require(`../assets/${dir}/bodybrazoder.png`)
                            : Object.keys(zonasAfectadas).join() === 'pierna izquierda' ?
                            require(`../assets/${dir}/bodypiernader.png`)
                            : Object.keys(zonasAfectadas).join() === 'pierna derecha' ?
                            require(`../assets/${dir}/bodypiernaizq.png`)
                            : Object.keys(zonasAfectadas).join() === 'hombros' ?
                            require(`../assets/${dir}/bodyhombros.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') ?
                            require(`../assets/${dir}/bodycabezatorso.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo') ?
                            require(`../assets/${dir}/cabezabrazoizq.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo')  && Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/cabezabrazoizqpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo')  && Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/cabezabrazoizqpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo')  && Object.keys(zonasAfectadas).includes('pierna derecha') && Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/cabezabrazoizqpiernas.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/cabezabrazoder.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho')  && Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/cabezabrazoderpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho')  && Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/cabezabrazoderpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho')  && Object.keys(zonasAfectadas).includes('pierna derecha') && Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/cabezabrazoderpiernas.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodycabezapiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodycabezapiernader.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('pierna derecha')&& Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodycabezapiernas.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') ?
                            require(`../assets/${dir}/bodycabezacadera.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/bodycabezacaderapiernader.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/bodycabezacaderapiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/bodycabezacaderapiernas.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho')?
                            require(`../assets/${dir}/bodycabezacaderabrazoder.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo')?
                            require(`../assets/${dir}/bodycabezacaderabrazoizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('brazo derecho')?
                            require(`../assets/${dir}/bodycabezacaderabrazoizqbrazoder.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/bodycabezacaderabrazoizqpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho')&& Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/bodycabezacaderabrazoderpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/bodycabezacaderabrazoizqpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho')&& Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/bodycabezacaderabrazoderpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho')&& Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/bodycabezacaderabrazoderpiernas.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/bodycabezacaderabrazoizqpiernas.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/bodycabezacaderabrazoizqbrazoderpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/bodycabezacaderabrazoizqbrazoderpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodybrazoderpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodybrazoderpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('cabeza y cuello') ?
                            require(`../assets/${dir}/cabezabrazoder.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cabeza y cuello') ?
                            require(`../assets/${dir}/cabezabrazoizq.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodybrazoizqpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodybrazoizqpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cadera') ?
                            require(`../assets/${dir}/pelvisbrazoizq.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/pelvisbrazoizqpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/pelvisbrazoizqpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/pelvisbrazoizqpiernas.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha')&& Object.keys(zonasAfectadas).includes('torso')?
                            require(`../assets/${dir}/pelvisbrazoizqpiernastorso.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('pierna derecha') && Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/bodybrazoizqpiernas.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna derecha') && Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/bodybrazoderpiernas.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/pelvisbrazoderpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/pelvisbrazoderpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/pelvisbrazoderpiernas.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha')&& Object.keys(zonasAfectadas).includes('torso')?
                            require(`../assets/${dir}/pelvisbrazoderpiernastorso.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('cadera') ?
                            require(`../assets/${dir}/pelvisbrazoder.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera') ?
                            require(`../assets/${dir}/torsocadera.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/torsocaderabrazoder.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('brazo derecho')&& Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/torsocaderabrazoderpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('brazo derecho')&& Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/torsocaderabrazoderpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('brazo izquierdo') ?
                            require(`../assets/${dir}/torsocaderabrazoizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/torsocaderabrazoizqpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/torsocaderabrazoizqpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/torsocaderabrazos.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zonasAfectadas).includes('brazo derecho')&& Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/torsocaderabrazospiernader.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zonasAfectadas).includes('brazo derecho')&& Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/torsocaderabrazospiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 6 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zonasAfectadas).includes('brazo derecho')&& Object.keys(zonasAfectadas).includes('pierna izquierda') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/torsocaderabrazospiernas.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/torsocaderapiernader.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/torsocaderapiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/torsocaderapiernas.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('torso') ?
                            require(`../assets/${dir}/torsobrazoder.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('pierna derecha') && Object.keys(zonasAfectadas).includes('torso') ?
                            require(`../assets/${dir}/torsopiernader.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('pierna izquierda') && Object.keys(zonasAfectadas).includes('torso') ?
                            require(`../assets/${dir}/torsopiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha') && Object.keys(zonasAfectadas).includes('torso') ?
                            require(`../assets/${dir}/torsopiernas.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('torso')&& Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/torsobrazoderpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('torso')&& Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/torsobrazoderpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('torso')&& Object.keys(zonasAfectadas).includes('pierna derecha')&& Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/torsobrazoderpiernas.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('torso') ?
                            require(`../assets/${dir}/torsobrazoizq.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/torsobrazoizqpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/torsobrazoizqpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('pierna derecha')&& Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/torsobrazoizqpiernas.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/brazos.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/cabezabrazos.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/brazostorso.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/brazostorsopiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna derecha')?
                            require(`../assets/${dir}/brazostorsopiernader.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna derecha')&& Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/brazostorsopiernas.png`)
                            : Object.keys(zonasAfectadas).length == 6 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna derecha')&& Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('cabeza y cuello')?
                            require(`../assets/${dir}/menoscadera.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/brazospelvis.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/brazospelvispiernader.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/brazospelvispiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/brazospelvispiernas.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('pierna izquierda') && Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/brazospiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('pierna derecha') && Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/brazospiernader.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('cadera') ?
                            require(`../assets/${dir}/bodycabezatorsocadera.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodycabezatorsocaderapiernader.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodycabezatorsocaderapiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('cadera')&& Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodycabezatorsocaderapiernas.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo') ?
                            require(`../assets/${dir}/bodycabezatorsobrazoizq.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodycabezatorsopiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodycabezatorsopiernader.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('pierna derecha')&& Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodycabezatorsopiernas.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodycabezatorsobrazoizqpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodycabezatorsobrazoizqpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zonasAfectadas).includes('pierna derecha')&& Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodycabezatorsobrazoizqpiernas.png`)
                            : Object.keys(zonasAfectadas).length == 3 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/bodycabezatorsobrazoder.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodycabezatorsobrazoderpiernader.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodycabezatorsobrazoderpiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna izquierda')&& Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodycabezatorsobrazoderpiernas.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('pierna derecha') && Object.keys(zonasAfectadas).includes('pierna izquierda') && Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/extremidades.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/cabezabrazospiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/cabezabrazospiernader.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('brazo izquierdo') ?
                            require(`../assets/${dir}/bodycabezatorsobrazos.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/bodycabezatorsocaderabrazoder.png`)
                            : Object.keys(zonasAfectadas).length == 4 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('brazo izquierdo') ?
                            require(`../assets/${dir}/bodycabezatorsocaderabrazoizq.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('pierna derecha') && Object.keys(zonasAfectadas).includes('pierna izquierda') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('torso') ?
                            require(`../assets/${dir}/extremidadestorso.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('pierna derecha') && Object.keys(zonasAfectadas).includes('pierna izquierda') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('cadera') ?
                            require(`../assets/${dir}/extremidadescadera.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna derecha') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/cabezabrazospiernas.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cadera') ?
                            require(`../assets/${dir}/bodymenospiernas.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodycabezatorsocaderabrazodermanopiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodycabezatorsocaderabrazoderqmanopiernader.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodycabezatorsocaderabrazoizqmanopiernader.png`)
                            : Object.keys(zonasAfectadas).length == 5 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodycabezatorsocaderabrazoizqmanopiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 6 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodymenospiernader.png`)
                            : Object.keys(zonasAfectadas).length == 6 && Object.keys(zonasAfectadas).includes('torso') && Object.keys(zonasAfectadas).includes('cabeza y cuello') && Object.keys(zonasAfectadas).includes('brazo derecho') && Object.keys(zonasAfectadas).includes('brazo izquierdo') && Object.keys(zonasAfectadas).includes('cadera') && Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodymenospiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 2 && Object.keys(zonasAfectadas).includes('pierna derecha') && Object.keys(zonasAfectadas).includes('pierna izquierda')?
                            require(`../assets/${dir}/bodypiernas.png`)
                            : Object.keys(zonasAfectadas).length == 6 && !Object.keys(zonasAfectadas).includes('torso') ?
                            require(`../assets/${dir}/bodymenostorso.png`)
                            : Object.keys(zonasAfectadas).length == 6 && !Object.keys(zonasAfectadas).includes('pierna izquierda') ?
                            require(`../assets/${dir}/bodymenospiernaizq.png`)
                            : Object.keys(zonasAfectadas).length == 6 && !Object.keys(zonasAfectadas).includes('pierna derecha') ?
                            require(`../assets/${dir}/bodymenospiernader.png`)
                            : Object.keys(zonasAfectadas).length == 6 && !Object.keys(zonasAfectadas).includes('cabeza y cuello') ?
                            require(`../assets/${dir}/bodymenoscabeza.png`)
                            : Object.keys(zonasAfectadas).length == 6 && !Object.keys(zonasAfectadas).includes('brazo derecho') ?
                            require(`../assets/${dir}/bodymenosbrazoizq.png`)
                            : Object.keys(zonasAfectadas).length == 6 && !Object.keys(zonasAfectadas).includes('brazo izquierdo') ?
                            require(`../assets/${dir}/bodymenosbrazoder.png`)
                            : 
                            require(`../assets/${dir}/bodytodo.png`)
                          } usemap="#image-map" />

                  <map name="image-map">
                      <area className="bodyParts" href="" alt="cabeza y cuello" title="cabeza y cuello" onClick={(e)=>{calcular(e, 4.5)}} coords="118,46,107,78,125,115,124,128,95,142,139,158,185,144,163,122,174,93,174,45,143,31" shape="poly"/>
                      {/*<area href="" alt="cuello" title="cuello" onClick={(e)=>{calcular(e, 4.5)}} coords="127,119,123,133,99,143,131,157,158,156,187,147,161,132,161,119,145,122" shape="poly"/>*/}
                      <area className="bodyParts" href="" alt="hombro izquierdo" title="brazo izquierdo" onClick={(e)=>{calcular(e, 4.5)}} coords="87,152,65,163,57,202,87,171,110,163" shape="poly"/>
                      <area className="bodyParts" href="" alt="hombro derecho" title="brazo derecho" onClick={(e)=>{calcular(e, 4.5)}} coords="176,162,198,152,226,164,229,203,206,178,192,168" shape="poly"/>
                      <area className="bodyParts" href="" alt="brazo izquierto" title="brazo izquierdo" onClick={(e)=>{calcular(e, 4.5)}} coords="86,179,60,204,58,260,81,262,76,240" shape="poly"/>
                      <area className="bodyParts" href="" alt="brazo derecho" title="brazo derecho" onClick={(e)=>{calcular(e, 4.5)}} coords="204,183,231,203,230,263,210,261,212,236" shape="poly"/>
                      <area className="bodyParts" href="" alt="torso" title="torso" onClick={(e)=>{calcular(e, 18)}} coords="90,174,145,168,200,174,192,278,172,291,114,293,92,277" shape="poly"/>
                      <area className="bodyParts" href="" alt="cadera" title="cadera" onClick={(e)=>{calcular(e, 1)}} coords="93,282,88,324,122,376,143,383,163,379,199,330,194,283,177,295,113,296" shape="poly"/>
                      <area className="bodyParts" href="" alt="pierna izquierda" title="pierna izquierda" onClick={(e)=>{calcular(e, 9)}} coords="85,340,82,488,84,569,101,645,109,642,124,571,127,491,133,398,109,380" shape="poly" />
                      <area className="bodyParts" href="" alt="pierna derecha" title="pierna derecha" onClick={(e)=>{calcular(e, 9)}} coords="150,398,175,381,201,338,206,499,201,560,189,649,175,647,165,566" shape="poly" />
                      <area className="bodyParts" href="" alt="antebrazo izquierdo" title="antebrazo izquierdo" onClick={(e)=>{calcular(e, 4.5)}} coords="56,266,62,274,78,265,62,354,47,351,49,294" shape="poly"/>
                      <area className="bodyParts" href="" alt="antebrazo derecho" title="antebrazo derecho" onClick={(e)=>{calcular(e, 4.5)}} coords="233,265,226,271,211,266,224,352,236,355,240,293" shape="poly"/>
                      <area className="bodyParts" href="" alt="manoder" title="manoder" onClick={(e)=>{calcular(e, 1)}} coords="50,365,62,365,73,391,64,384,61,406,49,394" shape="poly"/>
                      <area className="bodyParts" href="" alt="manoizq" title="manoizq" onClick={(e)=>{calcular(e, 1)}} coords="225,365,216,376,216,390,225,386,224,407,240,391,236,366" shape="poly"/>
                  </map>
        </>
    )
}

export default HombreMap