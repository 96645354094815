import logo from './logo.svg';
import './App.css';
import * as React from "react";
import { Admin, Resource, ListGuesser, defaultTheme, AppBar, Layout, Menu, CustomRoutes, useTranslate } from "react-admin";
import Inicio from './Home.jsx'
import Escala, {EscalaCreate, EscalaList} from './Escala.jsx'
import {Diagnostico, TipsScorad, TipsEasi, Introduccion, Sintomas, Bibliografia, Creditos} from './Dermatitis.jsx'
import PacienteEdit from './pacientes/PacienteEdit.jsx';
import PacienteShow from './pacientes/PacienteShow.jsx';
import { Route } from "react-router-dom";
import localForageDataProvider from 'ra-data-local-forage';
import indigo from "@mui/material/colors/indigo";
import pink from "@mui/material/colors/pink";
import red from "@mui/material/colors/red";

const myTheme = {
    ...defaultTheme,
    palette: {
        primary: {main:"#FB8500", contrastText: '#fff',},
        secondary: {
            light: '#573384',
            main: '#573384',
            dark: '#573384',
            contrastText: '#fff',
        },
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Arial", "sans-serif"].join(","),
    },
    components: {
        ...defaultTheme.components,
        MuiTextField: {
            defaultProps: {
                variant: "outlined",
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: "outlined",
            },
        },
    },
};

function App() {
  const [dataProvider, setDataProvider] = React.useState(null);

  React.useEffect(() => {
    async function startDataProvider() {
      const localForageProvider = await localForageDataProvider();
      setDataProvider(localForageProvider);
    }

    if (dataProvider === null) {
      startDataProvider();
    }
  }, [dataProvider]);

  // hide the admin until the data provider is ready
  if (!dataProvider) return <p>Loading...</p>;

  return (
    <Admin dataProvider={dataProvider} theme={myTheme}>
      {/*<Resource name="posts" list={ListGuesser}/>*/}
      {/*<Resource name="pacientes" {...pacientes} noLayout/>*/}
      <CustomRoutes noLayout>
            <Route path="/" element={<Inicio />} noLayout/>
            <Route path="/dermatitis-diagnostico" element={<Diagnostico />} noLayout/>
            <Route path="/introduccion" element={<Introduccion />} noLayout/>
            <Route path="/sintomas" element={<Sintomas />} noLayout/>
            <Route path="/creditos" element={<Creditos />} noLayout/>
            <Route path="/diagnostico" element={<Diagnostico />} noLayout/>
            <Route path="/tratamiento" element={<Diagnostico />} noLayout/>
            <Route path="/bibliografia" element={<Bibliografia />} noLayout/>
            <Route path="/clinimetria" element={<Diagnostico />} noLayout/>
            <Route path="/tips-scorad" element={<TipsScorad />} noLayout/>
            <Route path="/tips-easi" element={<TipsEasi />} noLayout/>
            <Route path="/escala/:idescala" element={<Escala />} noLayout/>
            <Route path="/escala/:idescala/create" element={<EscalaCreate />} noLayout/>
            <Route path="/escala/:idescala/list" element={<EscalaList />} noLayout/>
            <Route path="/paciente/:id/:idescala" element={<PacienteEdit />} noLayout/>
            <Route path="/paciente/:id/show/:idescala" element={<PacienteShow />} noLayout/>
        </CustomRoutes>
    </Admin>
  );
}

export default App;
