import logo from './logo.svg';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import {commonColors} from './siteSettings'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  Link,
  useNavigate,
} from "react-router-dom";
// import MainFeaturedPost from './MainFeaturedPost';
// import FeaturedPost from './FeaturedPost';
// import Main from './Main';
// import Sidebar from './Sidebar';
import Footer from './Footer';
import Banner from './assets/Banner.jpg'
import Imagen1 from './assets/Que-es-Dermatitis.jpg'
import Imagen2 from './assets/Tips-EASI.jpg'
import Imagen3 from './assets/Tips-Scorad.jpg'
import Scorad from './assets/PO-Scorad.png'
import EASI from './assets/Escala-EASI.png'
import DLQI from './assets/Escala-DLQI.png'
import ADCT from './assets/Escala-ADCT.png'
import HADS from './assets/Encuesta-HADS.png'
import POEM from './assets/Escala-POEM.png'
import { useMediaQuery } from '@mui/material';
const defaultTheme = createTheme();

function Inicio() {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const settings = {
    bannerTitle: "Una herramienta práctica para la evaluación clinimétrica de pacientes con Dermatitis Atópica"
  }
  return (
  	<ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Header />
      <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: { xs: 2, md: 12 },
            paddingTop: { xs: 5, md: 20 },
            paddingBottom: { xs: 5, md: 20 },
            width: '100%',
            backgroundColor: 'transparent',
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover'
          }}
      >
        <h1 style={{color: 'white', textAlign: 'center'}}>{settings.bannerTitle}</h1>
      </Box>
      <Box sx={{ 
            flexDirection: 'row',
            justifyContent: 'center',
            padding: { xs: 2, md: 10 },
            width: '100%',
          }}
        display={{ xs: 'block', md: 'flex' }}
      >
        <Box sx={{flex: 1, textAlign: 'center'}} mr={1} mb={1}>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box 
              onClick={() => navigate('/escala/scorad')}
              sx={{
                maxWidth: '40%',
                flex: 1,
                height: 160,
                backgroundImage: `url(${Scorad})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize:'contain',
                backgroundPosition: 'center',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'flex-end',
                display: 'flex'
            }}>
              <div style={{position: 'relative', bottom: -40, backgroundColor: 'white', padding: 5, height: 90, width: 90, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>PO. Scorad</div>
            </Box>
            <Box
              onClick={() => navigate('/escala/easi')}
              sx={{
                maxWidth: '40%',
                flex: 1,
                height: 160,
                backgroundImage: `url(${EASI})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize:'contain',
                backgroundPosition: 'center',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'flex-end',
                display: 'flex'
            }}>
              <div style={{position: 'relative', bottom: -40, backgroundColor: 'white', padding: 5, height: 90, width: 90, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Escala EASI</div>
            </Box>
          </Box>
          <Box sx={{
              flex: 1,
              backgroundColor: commonColors.moradoClaro,
              justifyContent: 'center',
              height: '120px',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'flex-end',
              marginTop: '-30px'
            }}>
            <p style={{color: commonColors.moradoMedio, fontWeight: 'bold'}}>Escalas desde la perspectiva del Médico</p>
          </Box>
        </Box>
        <Box sx={{flex: 2, textAlign: 'center'}} ml={1} mb={1}>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box 
              onClick={() => navigate('/escala/dlqi')}
              sx={{
                maxWidth: '20%',
                flex: 1,
                height: 160,
                backgroundImage: `url(${DLQI})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize:'contain',
                backgroundPosition: 'center',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'flex-end',
                display: 'flex'
            }}>
              <div style={{position: 'relative', bottom: -40, backgroundColor: 'white', padding: 5, height: 90, width: 90, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Escala DLQI</div>
            </Box>
            <Box 
              onClick={() => navigate('/escala/poem')}
              sx={{
                maxWidth: '20%',
                flex: 1,
                height: 160,
                backgroundImage: `url(${POEM})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize:'contain',
                backgroundPosition: 'center',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'flex-end',
                display: 'flex'
            }}>
              <div style={{position: 'relative', bottom: -40, backgroundColor: 'white', padding: 5, height: 90, width: 90, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Escala POEM</div>
            </Box>
            <Box
              onClick={() => navigate('/escala/hads')}
              sx={{
                maxWidth: '20%',
                flex: 1,
                height: 160,
                backgroundImage: `url(${HADS})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize:'contain',
                backgroundPosition: 'center',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'flex-end',
                display: 'flex'
            }}>
              <div style={{position: 'relative', bottom: -40, backgroundColor: 'white', padding: 5, height: 90, width: 90, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Escala HADS</div>
            </Box>
            <Box
              onClick={() => navigate('/escala/adct')}
              sx={{
                maxWidth: '20%',
                flex: 1,
                height: 160,
                backgroundImage: `url(${ADCT})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize:'contain',
                backgroundPosition: 'center',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'flex-end',
                display: 'flex'
            }}>
              <div style={{position: 'relative', bottom: -40, backgroundColor: 'white', padding: 5, height: 90, width: 90, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Escala ADCT</div>
            </Box>
          </Box>
          <Box  sx={{
              flex: 1,
              backgroundColor: commonColors.moradoClaro,
              justifyContent: 'center',
              height: '120px',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'flex-end',
              marginTop: '-30px'
            }}>
            <p style={{color: commonColors.moradoMedio, fontWeight: 'bold'}}>Escalas desde la perspectiva del Paciente</p>
          </Box>
        </Box>
      </Box>
      <Box 
        display={{ xs: 'block', md: 'flex' }}
        sx={{ 
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: { xs: 2, md: 10 },
          width: '100%',
          backgroundColor: commonColors.moradoClaro
        }}
      >
        <Box sx={{flex: 1, backgroundColor: commonColors.moradoClaro, textAlign: 'right'}}>
          <img src={Imagen1} />
        </Box>
        <Box sx={{flex: 2, backgroundColor: commonColors.moradoClaro, textAlign: 'left'}}>
          <div style={{color: 'white', backgroundColor: commonColors.morado, padding: { xs: 2, md: 10 }, paddingLeft: { xs: 5, md: 50 }, paddingRight: { xs: 5, md: 50 }, fontSize: 30, fontWeight: 'bold', width: '100%', maxWidth: '600px'}}>Qué es Dermatitis Atópica?</div>
          <ul style={{
            margin: { xs: 0, md: 60 },
            marginTop: 30, listStyle: 'none'}}>
            <li><Link style={{color: '#161615', textDecoration: 'none'}} to="/introduccion">- Introducción y Epidemología</Link></li>
            <li><Link style={{color: '#161615', textDecoration: 'none'}} to="/sintomas">- Signos y Síntomas</Link></li>
            <li><Link style={{color: '#161615', textDecoration: 'none'}} to="/diagnostico">- Diagnóstico</Link></li>
            <li><Link style={{color: '#161615', textDecoration: 'none'}} to="/tratamiento">- Tratamiento</Link></li>
            <li><Link style={{color: '#161615', textDecoration: 'none'}} to="/bibliografia">- Bibliografía</Link></li>
            <li><Link style={{color: '#161615', textDecoration: 'none'}} to="/clinimetria">- Clinimetría</Link></li>
          </ul>
          <Button onClick={()=>navigate('/dermatitis-diagnostico')} sx={{marginLeft: 10, color: 'white', borderRadius: 8, textTransform: 'capitalize', backgroundColor: commonColors.morado}} variant="contained" siza="small">
            Conoce más
          </Button>
        </Box>
      </Box>
      <Box sx={{ 
            flexDirection: 'column',
            justifyContent: 'center',
            padding: { xs: 2, md: 10 },
            width: '100%',
          }}
        display={{ xs: 'block', md: 'flex' }}
      >
        <h1 style={{color: commonColors.morado, textAlign: 'center'}}>Tips para tener en cuenta</h1>
        <Box sx={{ 
              flexDirection: 'row',
              justifyContent: 'center',
              // padding: 10,
              width: '100%',
            }}
          display={{ xs: 'block', md: 'flex' }}
        >
          <Box sx={{flex: 1, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems:'flex-end'}}>
            <div class="container" style={{cursor: 'pointer'}} onClick={()=> navigate('/tips-scorad')}>
              <img src={Imagen3} />
              <span class="btn">TIPS<br/>SCORAD</span>
            </div>
          </Box>
          <Box sx={{flex: 1, textAlign: 'center', paddingTop: 20, display: 'flex', flexDirection: 'column', alignItems:'flex-end'}} >
            <div class="container" style={{cursor: 'pointer'}} onClick={()=> navigate('/tips-easi')}>
              <img src={Imagen2} />
              <span class="btn2">TIPS<br/>EASI</span>
            </div>
          </Box>
        </Box>
      </Box>
      <Footer
        title="Footer"
        description="Con respecto al texto, Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso únicamente con fines de ilustración, enseñanza y como apoyo a los profesionales de la salud. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias"
      />
    </ThemeProvider>
  );
}

export default Inicio;
