export default function ImgHistorico ({zona}) {

	return (
		<>
			<img style={{width: '142px', height: '364px', maxWidth: '284px !important'}} src={!Object.keys(zona.zonasAfectadas) || !Object.keys(zona.zonasAfectadas).length ? 
                            require('../assets/bodyhombre/body.png')
                            : Object.keys(zona.zonasAfectadas).join() === 'torso' ?
                            require('../assets/bodyhombre/bodytorso.png')
                            : Object.keys(zona.zonasAfectadas).join() === 'cabeza y cuello' ?
                            require('../assets/bodyhombre/bodycabeza.png')
                            : Object.keys(zona.zonasAfectadas).join() === 'cadera' ?
                            require('../assets/bodyhombre/bodycadera.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/bodycaderapiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/bodycaderapiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/bodycaderapiernas.png')
                            : Object.keys(zona.zonasAfectadas).join() === 'brazo izquierdo' ?
                            require('../assets/bodyhombre/bodybrazoizq.png')
                            : Object.keys(zona.zonasAfectadas).join() === 'brazo derecho' ?
                            require('../assets/bodyhombre/bodybrazoder.png')
                            : Object.keys(zona.zonasAfectadas).join() === 'pierna izquierda' ?
                            require('../assets/bodyhombre/bodypiernader.png')
                            : Object.keys(zona.zonasAfectadas).join() === 'pierna derecha' ?
                            require('../assets/bodyhombre/bodypiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).join() === 'hombros' ?
                            require('../assets/bodyhombre/bodyhombros.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') ?
                            require('../assets/bodyhombre/bodycabezatorso.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') ?
                            require('../assets/bodyhombre/cabezabrazoizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')  && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/cabezabrazoizqpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')  && Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/cabezabrazoizqpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')  && Object.keys(zona.zonasAfectadas).includes('pierna derecha') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/cabezabrazoizqpiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/cabezabrazoder.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho')  && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/cabezabrazoderpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho')  && Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/cabezabrazoderpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho')  && Object.keys(zona.zonasAfectadas).includes('pierna derecha') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/cabezabrazoderpiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodycabezapiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodycabezapiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('pierna derecha')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodycabezapiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') ?
                            require('../assets/bodyhombre/bodycabezacadera.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/bodycabezacaderapiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/bodycabezacaderapiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/bodycabezacaderapiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho')?
                            require('../assets/bodyhombre/bodycabezacaderabrazoder.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')?
                            require('../assets/bodyhombre/bodycabezacaderabrazoizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('brazo derecho')?
                            require('../assets/bodyhombre/bodycabezacaderabrazoizqbrazoder.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/bodycabezacaderabrazoizqpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/bodycabezacaderabrazoderpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/bodycabezacaderabrazoizqpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/bodycabezacaderabrazoderpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/bodycabezacaderabrazoderpiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/bodycabezacaderabrazoizqpiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/bodycabezacaderabrazoizqbrazoderpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/bodycabezacaderabrazoizqbrazoderpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodybrazoderpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodybrazoderpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') ?
                            require('../assets/bodyhombre/cabezabrazoder.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') ?
                            require('../assets/bodyhombre/cabezabrazoizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodybrazoizqpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodybrazoizqpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cadera') ?
                            require('../assets/bodyhombre/pelvisbrazoizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/pelvisbrazoizqpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/pelvisbrazoizqpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/pelvisbrazoizqpiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')&& Object.keys(zona.zonasAfectadas).includes('torso')?
                            require('../assets/bodyhombre/pelvisbrazoizqpiernastorso.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/bodybrazoizqpiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/bodybrazoderpiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/pelvisbrazoderpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/pelvisbrazoderpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/pelvisbrazoderpiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')&& Object.keys(zona.zonasAfectadas).includes('torso')?
                            require('../assets/bodyhombre/pelvisbrazoderpiernastorso.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('cadera') ?
                            require('../assets/bodyhombre/pelvisbrazoder.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera') ?
                            require('../assets/bodyhombre/torsocadera.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/torsocaderabrazoder.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('brazo derecho')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/torsocaderabrazoderpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('brazo derecho')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/torsocaderabrazoderpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') ?
                            require('../assets/bodyhombre/torsocaderabrazoizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/torsocaderabrazoizqpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/torsocaderabrazoizqpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/torsocaderabrazos.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zona.zonasAfectadas).includes('brazo derecho')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/torsocaderabrazospiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zona.zonasAfectadas).includes('brazo derecho')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/torsocaderabrazospiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 6 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zona.zonasAfectadas).includes('brazo derecho')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/torsocaderabrazospiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/torsocaderapiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/torsocaderapiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/torsocaderapiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('torso') ?
                            require('../assets/bodyhombre/torsobrazoder.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('pierna derecha') && Object.keys(zona.zonasAfectadas).includes('torso') ?
                            require('../assets/bodyhombre/torsopiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') && Object.keys(zona.zonasAfectadas).includes('torso') ?
                            require('../assets/bodyhombre/torsopiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha') && Object.keys(zona.zonasAfectadas).includes('torso') ?
                            require('../assets/bodyhombre/torsopiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('torso')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/torsobrazoderpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('torso')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/torsobrazoderpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('torso')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/torsobrazoderpiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('torso') ?
                            require('../assets/bodyhombre/torsobrazoizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/torsobrazoizqpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/torsobrazoizqpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('pierna derecha')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/torsobrazoizqpiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/brazos.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/cabezabrazos.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/brazostorso.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/brazostorsopiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna derecha')?
                            require('../assets/bodyhombre/brazostorsopiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna derecha')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/brazostorsopiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 6 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna derecha')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('cabeza y cuello')?
                            require('../assets/bodyhombre/menoscadera.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/brazospelvis.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/brazospelvispiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/brazospelvispiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/brazospelvispiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/brazospiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/brazospiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('cadera') ?
                            require('../assets/bodyhombre/bodycabezatorsocadera.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodycabezatorsocaderapiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodycabezatorsocaderapiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('cadera')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodycabezatorsocaderapiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') ?
                            require('../assets/bodyhombre/bodycabezatorsobrazoizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodycabezatorsopiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodycabezatorsopiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('pierna derecha')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodycabezatorsopiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodycabezatorsobrazoizqpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodycabezatorsobrazoizqpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha')&& Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodycabezatorsobrazoizqpiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 3 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/bodycabezatorsobrazoder.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodycabezatorsobrazoderpiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodycabezatorsobrazoderpiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')&& Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodycabezatorsobrazoderpiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/extremidades.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/cabezabrazospiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/cabezabrazospiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') ?
                            require('../assets/bodyhombre/bodycabezatorsobrazos.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/bodycabezatorsocaderabrazoder.png')
                            : Object.keys(zona.zonasAfectadas).length == 4 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') ?
                            require('../assets/bodyhombre/bodycabezatorsocaderabrazoizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('torso') ?
                            require('../assets/bodyhombre/extremidadestorso.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('cadera') ?
                            require('../assets/bodyhombre/extremidadescadera.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/cabezabrazospiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cadera') ?
                            require('../assets/bodyhombre/bodymenospiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodycabezatorsocaderabrazodermanopiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodycabezatorsocaderabrazoderqmanopiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodycabezatorsocaderabrazoizqmanopiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 5 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodycabezatorsocaderabrazoizqmanopiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 6 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodymenospiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 6 && Object.keys(zona.zonasAfectadas).includes('torso') && Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') && Object.keys(zona.zonasAfectadas).includes('brazo derecho') && Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') && Object.keys(zona.zonasAfectadas).includes('cadera') && Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodymenospiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 2 && Object.keys(zona.zonasAfectadas).includes('pierna derecha') && Object.keys(zona.zonasAfectadas).includes('pierna izquierda')?
                            require('../assets/bodyhombre/bodypiernas.png')
                            : Object.keys(zona.zonasAfectadas).length == 6 && !Object.keys(zona.zonasAfectadas).includes('torso') ?
                            require('../assets/bodyhombre/bodymenostorso.png')
                            : Object.keys(zona.zonasAfectadas).length == 6 && !Object.keys(zona.zonasAfectadas).includes('pierna izquierda') ?
                            require('../assets/bodyhombre/bodymenospiernaizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 6 && !Object.keys(zona.zonasAfectadas).includes('pierna derecha') ?
                            require('../assets/bodyhombre/bodymenospiernader.png')
                            : Object.keys(zona.zonasAfectadas).length == 6 && !Object.keys(zona.zonasAfectadas).includes('cabeza y cuello') ?
                            require('../assets/bodyhombre/bodymenoscabeza.png')
                            : Object.keys(zona.zonasAfectadas).length == 6 && !Object.keys(zona.zonasAfectadas).includes('brazo derecho') ?
                            require('../assets/bodyhombre/bodymenosbrazoizq.png')
                            : Object.keys(zona.zonasAfectadas).length == 6 && !Object.keys(zona.zonasAfectadas).includes('brazo izquierdo') ?
                            require('../assets/bodyhombre/bodymenosbrazoder.png')
                            : 
                            require('../assets/bodyhombre/bodytodo.png')
                          } />

                          <img style={{width: '100px', height: '335px', maxWidth: '142 !important'}} src={!Object.keys(zona.zonasAfectadas2) || !Object.keys(zona.zonasAfectadas2).length ? 
                            require('../assets/bodyhombre/bodyback.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && !Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/derechoposterior.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && !Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/izqposterior.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && !Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/bodynuca.png')
                            : Object.keys(zona.zonasAfectadas2).includes('espalda') && !Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/bodyespalda.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && !Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/piernaderposterior.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/piernaizqposterior.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/piernasposterior.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/piernasbrazoderposterior.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/piernasbrazoizqposterior.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/piernasnucaposterior.png')
                            : Object.keys(zona.zonasAfectadas2).includes('espalda') && Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/piernasespalda.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/extremidadesposterior.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/extremidadesposterior.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/piernaizqbrazoder.png')
                            : !Object.keys(zona.zonasAfectadas2).includes('espalda') && !Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/piernaderbrazoizq.png')
                            : Object.keys(zona.zonasAfectadas2).includes('espalda') && Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/espaldapiernaizq.png')
                            : Object.keys(zona.zonasAfectadas2).includes('espalda') && !Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/espaldapiernader.png')
                            : Object.keys(zona.zonasAfectadas2).includes('espalda') && !Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/espaldabrazoizq.png')
                            : Object.keys(zona.zonasAfectadas2).includes('espalda') && !Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && !Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/espaldabrazoder.png')
                            : Object.keys(zona.zonasAfectadas2).includes('espalda') && !Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && !Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/espaldabrazos.png')
                            : Object.keys(zona.zonasAfectadas2).includes('espalda') && !Object.keys(zona.zonasAfectadas2).includes('pierna izquierda posterior') && !Object.keys(zona.zonasAfectadas2).includes('pierna derecha posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo izquierdo posterior') && Object.keys(zona.zonasAfectadas2).includes('brazo derecho posterior') && Object.keys(zona.zonasAfectadas2).includes('cabeza y nuca posterior')?
                            require('../assets/bodyhombre/espaldabrazosnuca.png')
                            :require('../assets/bodyhombre/bodyback.png')}/>
		</>
	)
}