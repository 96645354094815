export const commonColors = {
	morado: "#573384",
	moradoClaro: "RGB(230, 224, 237)",
	moradoMedio: "RGB(138, 126, 139)",
	anaranjado: 'RGB(204, 98, 38)'
};


export const escalas = [
	{
		id: "scorad",
		titulo: "PO Scorad",
		banner: "Banner-Nuevo-01.png"
	},
	{
		id: "easi",
		titulo: "EASI",
		banner: "Banner-Nuevo-02.png"
	},
	{
		id: "dlqi",
		titulo: "DLQI",
		subtitulo: "Índice de calidad de vida dermatológico",
		banner: "Escala-DLQI.jpg"
	},
	{
		id: "poem",
		titulo: "POEM",
		subtitulo: "Medición del eczema orientado al paciente",
		banner: "Escala-POEM.jpg"
	},
	{
		id: "hads",
		titulo: "HADS",
		subtitulo: "Escala de ansiedad y depresión hospitalaria",
		banner: "Encuesta-HADS.jpg"
	},
	{
		id: "adct",
		titulo: "ADCT",
		subtitulo: "Herramienta de control de la Dermatitis Atópica",
		banner: "Banner-2.jpg"
	},
];