import logo from './logo.svg';
import './App.css';
import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Create, SimpleForm, TextInput, RadioButtonGroupInput, DateInput, useNotify, useRedirect, List, Datagrid, TextField,
        DateField, EditButton, ShowButton, Loading, useGetOne, useUpdate } from 'react-admin';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import {commonColors, escalas} from './siteSettings'
import Banner from './assets/Banner-dermatitis.png'
import BannerIntro from './assets/Banner-Introduccion.jpg'
import BannerSintomas from './assets/Banner-sintomas.jpg'
import BannerBibliografia from './assets/Banner-bibliografia.jpg'
import Next from './assets/next.png'
import Imagen1 from './assets/Que-es-Dermatitis.jpg'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  useNavigate,
} from "react-router-dom";
import Footer from './Footer';


export const Bibliografia = () => (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: { xs: 2, md: 12 },
            width: '100%',
            backgroundColor: 'transparent',
            backgroundImage: `url(${BannerBibliografia})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover'
          }}
      >
        <h1 style={{color: 'white', textAlign: 'center'}}>Dermatitis Bibliografía</h1>
      </Box>
      <Container maxWidth="lg">
            <p>
                &nbsp;
            </p>
            <p>
                &nbsp;
            </p>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>1.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                      Kim BE, Leung DYM. Significance of skin barrier dysfunction in atopic dermatitis. Allergy Asthma Immunol Res. 2018;10(3):207-15.
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>2.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>Torres T, Ferreira EO, Gonçalo M, Mendes-Bastos P, Selores M, Filipe P. Acta Med Port. 2019;32(9):606-13.</p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>3.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>Stefanovic N, Flohr C, Irvine AD. The exposome in atopic dermatitis. Allergy. 2019 Jun 13. doi: 10.1111/all.13946</p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>4.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>Morizane S. The role of kallikrein-related peptidases in atopic dermatitis. Acta Med Okayama. 2019;73(1):1-6.</p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>5.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>Czarnowicki T, He H, Krueger JG, Guttman-Yassky E. Atopic dermatitis endotypes and implications for targeted therapeutics. J Allergy Clin Immunol. 2019;143(1):1-11.</p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>6.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>Rerknimitr P, Otsuka A, Nakashima C,  Kabashima K . The etiopathogenesis of atopic dermatitis: barrier disruption, immunological derangement and pruritus. Inflamm Regen. 2017;37:14.</p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>7.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>Egawa G,  Kabashima K . Barrier dysfunction in the skin allergy. Allergol Int. 2018;67(1):3-11.</p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>8.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>Cork MJ, Danby SG, Vasilopoulos Y, Hadgraft J, Lane ME, Moustafa M, et al. Epidermal barrier dysfunction in atopic dermatitis. J Invest Dermatol. 2009;129(8):1892-908.</p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>9.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>Elias PM, Wakefield JS. Mechanisms of abnormal lamellar body secretion and the dysfunctional skin barrier in patients with atopic dermatitis. J Allergy Clin Immunol. 2014;134(4):781-791.e1.</p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>10.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                      De Benedetto A,  Rafaels NM , McGirt LY, Ivanov AI,   Georas SN , Cheadle C, et al. Tight junction defects in patients with atopic dermatitis. J Allergy Clin Immunol. 2011;127(3):773-86.e1-7.
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>11.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                    Yuki T, Komiya A,  Kusaka A . Impaired tight junctions obstruct stratum corneum formation by altering polar lipid and profilaggrin processing. J Dermatol Sci. 2013;69(2):148-58.
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>12.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                      Pelc J,  Czarnecka-Operacz M ,  Adamski Z . Structure and function of the epidermal barrier in patients with atopic dermatitis - treatment options. Partone. Postepy Dermatol Alergol. 2018;35(1):1-5.
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>13.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                    Tham EH, Leung DY. Mechanisms by which atopic dermatitis predisposes to food allergy and the atopic march. Allergy Asthma Immunol Res. 2019;11(1):4-15.
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>14.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                      Baurecht H,  Rühlemann MC , Rodríguez E. Epidermal lipid composition, barrier integrity and eczematous inflammation are associated with skin microbiome configuration. J Allergy Clin Immunol. 2018;141(5):1668-1676.e16.
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>15.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                    Lipozencić J, Wolf R. Atopic dermatitis: an update and review of the literature. Dermatol Clin. 2007;25:605-12.
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>16.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                      Rothe MJ,Grant-Kels JM. Diagnostic criteria for atopic dermatitis. Lancet. 1996:348:769-70.
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>17.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                    Eichenfield LF, Hanifin JM, Luger TA, Stevens SR, Pride HB. Consensus conference on pediatric atopic dermatitis. J Am Acad Dermatol. 2003;49:1088-95.
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>18.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                      Hospital Universitario Centro Dermatológico Federico Lleras Acosta E.S.E. Perfil Epidemiológico. [Internet], 2018. [citado 27 de agosto de 2019]  Recuperado a partir de: http://www.dermatologia.gov.co/comunicaciones/boletines_publicaciones/perfil_epidemiológico
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>19.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                    National Institute for Health and Clinical Excellence (NICE). Atopic eczema in children: management of atopic eczema in children from birth up to the age of 12 years. [Internet], 2007. [citado 27 de agosto de 2019] Recuperado a partir de: https://www.nice.org.uk/CG057
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>20.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                      Consensus Report of the European Task Force on Atopic Dermatitis: Severity scaring of atopic dermatitis: The SCORAD index. Dermatology.1993;186:23-31.
                  </p>
                </Grid>
            </Grid>
             <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>21.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                    Picone Z, Larralde de Luna M, Maccario MF, Papale MR. Consenso Nacional de Dermatitis Atópica en la Infancia. Comité SAP. Arch Argent Pediatr. 1999;97:285-8.
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>22.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                      Leyden JJ, Marples R, Kligman AM. Staphylococcus aureus in the lesions of atopic dermatitis. Br J Dermatol. 1974;90:525-30.
                  </p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>23.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>
                    https://revistasocolderma.org/sites/default/files/guia_de_practica_clinica_dermatitis_atopica_oct_2018.pdf
                  </p>
                </Grid>
            </Grid>
        </Container>
      <Footer
        title="Footer"
        description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
      />
    </>
  );

export const Diagnostico = () => (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: { xs: 2, md: 12 },
            width: '100%',
            backgroundColor: 'transparent',
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover'
          }}
      >
        <h1 style={{color: 'white', textAlign: 'center'}}>Diagnóstico</h1>
      </Box>
      <Container maxWidth="lg">
            <p>
                Nuestra Guía de Práctica Clínica (GPC) para el diagnóstico y tratamiento de la dermatitis atópica en Colombia, desarrollada por la Asociación Colombiana de Dermatología y Cirugía Dermatológica (AsoColDerma), el Centro Dermatológico Federico Lleras Acosta y el Instituto de Evaluación Tecnológica en Salud (IETS) incluye las siguientes recomendaciones respecto al diagnóstico:
            </p>
            <p>
                El diagnóstico de la dermatitis atópica es clínico.
            </p>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>1.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>Se recomienda realizar el diagnóstico de dermatitis atópica cuando un niño o adulto presente prurito en la piel y tres o más de las siguientes características:</p>
                  <ul>
                    <li>
                            Aparición de signos y síntomas antes de los 2 años (este criterio no aplica en niños menores de 4 años).
                    </li>
                    <li>
                            Dermatitis evidente en áreas de flexión, como pliegues ante-cubitales (pliegue del codo) o fosas poplíteas (detrás de las rodillas).
                    </li>
                    <li>
                            En niños menores de 24 meses dermatitis visible en mejillas o áreas extensoras, o en ambas localizaciones.
                    </li>
                    <li>
                            Antecedentes personales de dermatitis en áreas de flexión (o en niños menores de 18 meses dermatitis en mejillas o áreas extensoras, o en ambas localizaciones).
                    </li>
                    <li>
                            Historia personal de xerosis (piel seca) en los últimos 12 meses.
                    </li>
                    <li>
                            Antecedentes personales de asma o rinitis alérgica (o antecedentes de atopia en un familiar de primer grado si el niño es menor de 4 años).
                    </li>
                  </ul>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>2.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>En pacientes adultos se deben considerar los mismos criterios diagnósticos que en los niños. En caso de duda o requerirse una biopsia de piel, se deben considerar los siguientes diagnósticos diferenciales:</p>
                  <ul>
                    <li>
                      Escabiosis
                    </li>
                    <li>
                      Dermatitis seborreica
                    </li>
                    <li>
                      Dermatitis de contacto (irritante o alérgica)
                    </li>
                    <li>
                      Ictiosis
                    </li>
                    <li>
                      Linfoma cutáneo de células T
                    </li>
                    <li>
                      Psoriasis
                    </li>
                    <li>
                      Enfermedades por inmunodeficiencia primaria
                    </li>
                    <li>
                      Eritrodermia de otras causas
                    </li>
                  </ul>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#372579', padding: '20px', color: 'white'}}>3.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>Se sugiere considerar la indicación de pruebas de parche o IgE alérgeno-específica en pacientes con dermatitis atópica que tienen una enfermedad persistente / recalcitrante, o antecedentes / hallazgos en el examen físico consistentes con dermatitis alérgica de contacto.</p>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  <p style={{fontSize: 30, fontWeight: 900}}>
                    <span style={{backgroundColor: '#88226D', padding: '20px', color: 'white'}}>4.</span>
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p>En niños menores de 5 años, con dermatitis atópica moderada a grave, se sugiere considerar su evaluación para alergia alimentaria a leche, huevo, maní, trigo y soya, o al alimento específico al que presenta la reacción, si se cumple al menos uno (1) de los siguientes:</p>
                  <ul>
                    <li>Escabiosis</li>
                    <li>Dermatitis seborreica</li>
                  </ul>
                </Grid>
            </Grid>
        </Container>
      <Footer
        title="Footer"
        description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
      />
    </>
  );

export const Introduccion = () => (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: { xs: 2, md: 12 },
            width: '100%',
            backgroundColor: 'transparent',
            backgroundImage: `url(${BannerIntro})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover'
          }}
      >
        <h1 style={{color: 'white', textAlign: 'center'}}>Introducción y epidemiología</h1>
      </Box>
      <Container maxWidth="lg">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        <p>
            <ul style={{listStyle: 'square'}}>
                <li>Enfermedad inflamatoria crónica de la piel caracterizada por disfunción de la barrera cutánea.</li>
                <li>Más común en pediatría, afecta al 15 - 20 % de la población infantil y al 5 % de la población adulta.</li>
                <li>Predisposición genética: historia familiar de atopia en el 50 % de los pacientes.</li>
                <li>Generalmente inicia durante la niñez o infancia temprana, el 90 % de los casos inician con síntomas antes de los 5 años de edad.</li>
                <li>Se caracteriza por hiperreactividad cutánea y lesiones de eczema en diferentes áreas anatómicas, cuya expresión clínica depende de mecanismos individuales o endotipos que determinan que la enfermedad se manifieste de una u otra forma, y que tanto la gravedad como la historia natural sean variables.</li>
                <li>Enfermedad muy heterogénea, resultado de la disregulación del sistema inmune, con predominio de la acción directa de los linfocitos Th2 sobre la producción de IL-4 e IgE, que promueven, amplifican y cronifican la inflamación.</li>
                <li>En la DA, el defecto de la barrera cutánea con incremento en la pérdida trans-epidérmica de agua se favorece por:</li>
            </ul>
        </p>
            <Grid container spacing={1}>
                <Grid item md={1}>
                  
                </Grid>
                <Grid sx={{borderColor: '#372579', borderStyle: 'solid'}} item xs={11}>
                  <ul style={{listStyle: 'none', paddingLeft: 0}}>
                    <li style={{margin: '0 20px 20px 0', padding: '0 20px 20px 0'}}>
                        <span style={{backgroundColor: '#88226D', padding: '5px 20px', color: 'white', margin: 10}}>1.</span>Disminución en la producción de factores de hidratación natural, por alteración genética o adquirida de la filagrina y disminución de las proteínas de la envoltura cornificada (loricrina e involucrina).
                    </li>
                    <li style={{margin: '0 20px 20px 0', padding: '0 20px 20px 0'}}>
                        <span style={{backgroundColor: '#88226D', padding: '5px 20px', color: 'white', margin: 10}}>2.</span>
                            Disminución cualitativa y cuantitativa de los lípidos inter-corneocitarios (ceramidas y ácidos grasos, principalmente ácido linoleico), con la subsecuente alteración estructural de la bicapa lipídica.
                    </li>
                    <li style={{margin: '0 20px 20px 0', padding: '0 20px 20px 0'}}>
                        <span style={{backgroundColor: '#88226D', padding: '5px 20px', color: 'white', margin: 10}}>3.</span>
                            Defectos de las uniones estrechas, con marcada disminución en la expresión del gen de la claudina 1.
                    </li>
                    <li style={{margin: '0 20px 20px 0', padding: '0 20px 20px 0'}}>
                        <span style={{backgroundColor: '#88226D', padding: '5px 20px', color: 'white', margin: 10}}>4.</span>
                            Aumento en la actividad de las proteasas y disminución de los inhibidores de proteasas, debido a factores genéticos o adquiridos.
                    </li>
                    <li style={{margin: '0 20px 20px 0', padding: '0 20px 20px 0'}}>
                        <span style={{backgroundColor: '#88226D', padding: '5px 20px', color: 'white', margin: 10}}>5.</span>
                            Cambio en el pH cutáneo y disbiosis en el microbioma.
                    </li>
                  </ul>
                </Grid>
            </Grid>
            <p>
                Todos los factores mencionados contribuyen a la disfunción de la barrera cutánea, aumentan la pérdida trans-epidérmica de agua y facilitan la sensibilización epicutánea a microbios, irritantes, alérgenos y nanopartículas, entre otros factores, que inician, amplifican y mantienen la respuesta inflamatoria.
            </p>
            <p>
                Los pacientes con dermatitis atópica son más susceptibles a las infecciones virales y bacterianas por:
            </p>
            <ul style={{listStyle: 'square'}}>
                <li>Aumento de la adherencia de Staphylococcus aureus a la superficie cutánea, con disminución en la producción de péptidos antimicrobianos, lo que explica las altas tasas de colonización y sobreinfección por esta bacteria.</li>
                <li>Alteración en el funcionamiento de las células T, lo que explica la predisposición para sufrir molusco contagioso, eczema herpético y verrugas virales.</li>
            </ul>
            <p>&nbsp;</p>
        </Container>
      <Footer
        title="Footer"
        description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
      />
    </>
  );

export const Sintomas = () => (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: { xs: 2, md: 12 },
            width: '100%',
            backgroundColor: 'transparent',
            backgroundImage: `url(${BannerSintomas})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover'
          }}
      >
        <h1 style={{color: 'white', textAlign: 'center'}}>Signos y síntomas</h1>
      </Box>
      <Container maxWidth="lg">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        <p>
            <ul style={{listStyle: 'square'}}>
                <li>Se caracteriza por los siguientes hallazgos esenciales: xerosis, topografía típica según la edad, prurito, y curso crónico y recidivante.</li>
                <li>La apariencia y topografía de las lesiones varía según la edad de los pacientes y la raza.</li>
            </ul>
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p style={{backgroundColor: '#372579', color: 'white', padding: 10}}>
            Tabla 1. Localización anatómica de las lesiones en pacientes de 0-2 años
        </p>

            <Grid container spacing={1}>
                <Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} item xs={4}>
                  <span style={{backgroundColor: '#372579', color: 'white', padding: '5px 30px', fontSize: 30, fontWeight: 900, borderRadius: 17, marginBottom: 20}}>0-2 años</span>
                  <img src={require('./assets/0-2.jpg')} />
                </Grid>
                <Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}} item xs={8}>
                  <h2 style={{color: '#372579', fontWeight: 'bold', textAlign: 'center', width: '100%'}}>Distribución Anatómica</h2>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>1.</span>Cuero Cabelludo
                  </p>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>2.</span>Cara (mejillas, frente y lóbulos de las orejas)
                  </p>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>3.</span>Dedos de las manos
                  </p>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>4.</span>Tronco
                  </p>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>5.</span>Zonas extendoras de las ectremidades
                  </p>
                </Grid>
            </Grid>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p style={{backgroundColor: '#372579', color: 'white', padding: 10}}>
                Tabla 2. Localización anatómica de las lesiones en pacientes de 2-11 años
            </p>
            <Grid container spacing={1}>
                <Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} item xs={4}>
                  <span style={{backgroundColor: '#372579', color: 'white', padding: '5px 30px', fontSize: 30, fontWeight: 900, borderRadius: 17, marginBottom: 20}}>2-11 años</span>
                  <img src={require('./assets/2-11.jpg')} />
                </Grid>
                <Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}} item xs={8}>
                  <h2 style={{color: '#372579', fontWeight: 'bold', textAlign: 'center', width: '100%'}}>Distribución Anatómica</h2>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>1.</span>Región Periotal
                  </p>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>2.</span>Nuca y cuello
                  </p>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>3.</span>Plieges flexores
                  </p>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>4.</span>Dorso de manos y pies
                  </p>
                </Grid>
            </Grid>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p style={{backgroundColor: '#372579', color: 'white', padding: 10}}>
                Tabla 3. Localización anatómica de las lesiones en pacientes de 12-18 años
            </p>
            <Grid container spacing={1}>
                <Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} item xs={4}>
                  <span style={{backgroundColor: '#372579', color: 'white', padding: '5px 30px', fontSize: 30, fontWeight: 900, borderRadius: 17, marginBottom: 20}}>12-18 años</span>
                  <img src={require('./assets/12-18.jpg')} />
                </Grid>
                <Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}} item xs={8}>
                  <h2 style={{color: '#372579', fontWeight: 'bold', textAlign: 'center', width: '100%'}}>Distribución Anatómica</h2>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>1.</span>Cara (mejillas, región pero-oral)
                  </p>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>2.</span>Nuca y cuello
                  </p>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>3.</span>Pliegues flexores (codos. muñecas, detrás de la rodilla, tobillos)
                  </p>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>4.</span>Dorso de manos y pies
                  </p>
                  <p>
                      <span style={{backgroundColor: '#88226D', padding: '5px 10px 5px 60px', color: 'white', margin: 10, fontSize: 20, fontWeight: 'bold'}}>5.</span>Superficies extensoras de brazos y muslos
                  </p>
                </Grid>
            </Grid>
            <p>&nbsp;</p>
        </Container>
      <Footer
        title="Footer"
        description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
      />
    </>
  );


export const TipsScorad = () => (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: { xs: 2, md: 12 },
            width: '100%',
            backgroundColor: 'transparent',
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover'
          }}
      >
        <h1 style={{color: 'white', textAlign: 'center'}}>Tips Scorad</h1>
      </Box>
      <Container maxWidth="lg">
            <p>
                SCORAD: índice de severidad para la dermatitis atópica
            </p>
            <p style={{backgroundColor: 'RGB(55, 37, 121, 0.3)', padding: '20px'}}>
                Consentimiento informado para toma de fotografías.
            </p>
            <p style={{backgroundColor: 'RGB(55, 37, 121, 0.3)', padding: '20px'}}>
                Liste los seis ítems de evaluación:<br/><br/>
                - Eritema<br/>
                - Edema / elevación<br/>
                - Exudados<br/>
                - Excoriaciones<br/>
                - Liquenificación<br/>
                - Sequedad<br/>
            </p>
            <p style={{backgroundColor: 'RGB(136, 34, 109, 0.3)', padding: '20px'}}>
                Adecuada calificación de los signos clínicos: cada ítem debe tener una calificación de 0-3.<br/><br/>
                O = Ausente<br/>
                1 = Leve<br/>
                2 = Moderado<br/>
                3 = Severo<br/>
            </p>
            <p style={{backgroundColor: 'RGB(55, 37, 121, 0.3)', padding: '20px', display: 'flex', flexDirection: 'column'}}>
            {/*<p style={{backgroundColor: '#EE713B', padding: '20px'}}>*/}
                Ubique el área anatómica para la clasificación:<br/><br/>
                - Debe ser representativa (intensidad media) para cada ítem, según lo determine el observador.<br/>
                - Excluya el sitio más afectado.<br/>
                - Se puede elegir el mismo sitio para evaluar 2 o más elementos.<br/>
                <img src={require('./assets/tipsscorad1.png')} style={{alignSelf: 'center', marginTop: 10, width: '70%', maxWidth: 700}} />
                <span style={{fontSize: 10, fontWeight: 'bold'}}>
                  <i>Tomado de: Guías para el Diagnóstico y Tratamiento de la Dermatitis Atópica 2019. Asociación Argentina de Alergia e Inmunología Versión en castellano del sistema SCORAD. Adaptada y modificada de Budiño Torres S, et al. Asociación Asturiana de Pediatría de Atención Primaria. Disponible en: http://bit.ly/2mdestd (consultado en enero de 2024)</i>
                </span>
            </p>
            <p style={{backgroundColor: 'RGB(136, 34, 109, 0.3)', padding: '20px'}}>
            {/*<p style={{backgroundColor: '#F99836', padding: '20px'}}>*/}
                Determine la intensidad de los signos:<br/><br/>
                - Sequedad: se debe evaluar la sequedad en áreas no involucradas por lesiones agudas o liquenificadas y sin la aplicación previa de emolientes / humectantes. La palpación también es importante para evaluar la textura de la piel.<br/>
                - Eritema: hace referencia al enrojecimiento de la piel.<br/>
                - El edema/elevación o infiltración palpable de la piel, que puede ocurrir tanto en lesiones eritematosas agudas, excoriadas, como en las lesiones crónicas durante los brotes.<br/>
                - Exudación/costras: las lesiones exudativas resultantes del edema epidérmico y la vesiculación.<br/>
                - Lesiones de rascado: este ítem es un marcador de prurito, más visible en lesiones no liquenificadas. Se tienen en cuenta el número y la intensidad de las costras.<br/>
                - Liquenificación: engrosamiento epidérmico de las lesiones crónicas. Los surcos de la piel están muy acentuados y el color es grisáceo o parduzco. Las lesiones de prurigo se incluyen en este ítem.<br/>
            </p>
            <p style={{backgroundColor: 'RGB(55, 37, 121, 0.3)', padding: '20px', display: 'flex', alignItems: 'center'}}>
            {/*<p style={{backgroundColor: '#D78C76', padding: '20px'}}>*/}
                <img src={require('./assets/tipsscorad2.png')} style={{alignSelf: 'center', margin: 10, width: 100}} />
                Indique a los pacientes, en la escala de 10 del formulario de evaluación, el punto correspondiente al valor promedio de la intensidad del prurito e impacto en el sueño durante las últimas 3 noches/días, y asegúrese de que el paciente (generalmente más de 7 años) o sus padres sean capaces de responder correctamente.
            </p>
            <p style={{display: 'flex', justifyContent: 'center'}}>
              <img src={require('./assets/tipsscorad3.png')} style={{alignSelf: 'center', margin: 10, width: 600}} />
            </p>
             <p style={{backgroundColor: 'RGB(136, 34, 109, 0.3)', padding: '20px'}}>
            {/*<p style={{backgroundColor: '#EFC7B8', padding: '20px'}}>*/}
                Analice el resultado y determine la severidad de la dermatitis atópica.
            </p>
            <Box sx={{ width: '100%', display: { xs: 'block', md: 'flex' }, alignItems: 'stretch', marginBottom: 5}}>
              <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Box sx={{backgroundColor: commonColors.morado, color: '#FFFFFF', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>
                  Escala  
                </Box>
                <Box sx={{backgroundColor: commonColors.moradoClaro, color: '#000000', textAlign: 'center', padding: '50px', fontWeight: 'bold', flex: 1}}>
                  SCORAD  
                </Box>
              </Box>
              <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Box sx={{backgroundColor: commonColors.morado, color: '#FFFFFF', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>
                  Puntaje máximo
                </Box>
                <Box sx={{backgroundColor: commonColors.moradoClaro, color: '#000000', textAlign: 'center', padding: '50px', fontWeight: 'bold', flex: 1}}>
                  103  
                </Box>
              </Box>
              <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Box sx={{backgroundColor: commonColors.morado, color: '#FFFFFF', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>
                  Interpretación
                </Box>
                <Box sx={{backgroundColor: commonColors.moradoClaro, color: '#000000', textAlign: 'center', padding: '50px', fontWeight: 'bold', flex: 1}}>
                  ≤ 25 : Leve <br/>
                  25-50 : Moderada<br/>
                  ≥ 50 : Severa  
                </Box>
              </Box>
            </Box>
        </Container>
      <Footer
        title="Footer"
        description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
      />
    </>
  );

export const TipsEasi = () => (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: { xs: 2, md: 12 },
            width: '100%',
            backgroundColor: 'transparent',
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover'
          }}
      >
        <h1 style={{color: 'white', textAlign: 'center'}}>Tips EASI</h1>
      </Box>
      <Container maxWidth="lg">
            <p>
                EASI: área de eccema e índice de severidad
            </p>
            <div style={{backgroundColor: 'RGB(55, 37, 121, 0.3)', padding: '20px', display: 'flex', justifyContent: 'space-between'}}>
                <p>
                    Cuatro regiones del cuerpo son consideradas, por separado. Seleccione las regiones afectadas:<br/><br/>
                  1 Cabeza y cuello<br/>
                  2 Tronco (incluida área genital)<br/>
                  3 Extremidades superiores<br/>
                  4 Extremidades inferiores (incluida la región glútea)<br/>
                </p>
                <img src={require('./assets/tipseasy.png')} style={{alignSelf: 'center', margin: 10, width: 200}} />
            </div>

            <p>
              Recuerde: hay dos formas del sistema de puntuación EASI, según la edad del paciente. Es diferente para menores de 8 años.
            </p>
            <p style={{backgroundColor: 'RGB(136, 34, 109, 0.3)', padding: '20px'}}>
                La extensión del eccema debe ser evaluada:<br/><br/>
                - Cada región corporal tiene una participación del 100%<br/>
                - Para cada región, asigne un puntaje de calificación de 0 - 6 basándose en el porcentaje de piel afectada<br/>
            </p>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img src={require('./assets/tipseasy2.png')} style={{alignSelf: 'center', margin: 10}} />
            </div>
            {/*<p style={{backgroundColor: '#EE713B', padding: '20px'}}>*/}
            <p style={{backgroundColor: 'RGB(55, 37, 121, 0.3)', padding: '20px'}}>
                Adecuada calificación de los signos clínicos. Cada ítem debe tener una calificación de 0 - 3:<br/><br/>
                O = Ausente<br/>
                1 = Leve<br/>
                2 = Moderado 3 = Severo<br/>
            </p>
            {/*<p style={{backgroundColor: '#F99836', padding: '20px'}}>*/}
            <p style={{backgroundColor: 'RGB(136, 34, 109, 0.3)', padding: '20px'}}>
                Utilice puntajes PROMEDIO para evaluar la gravedad de la enfermedad con cada uno de los cuatro signos clínicos en la región:<br/><br/>
                - Se pueden asignar puntajes de 1.5 y 2.5. No se permite la asignación de puntajes de 0.5. Por ejemplo: si hay áreas con eritema donde se asigna un puntaje de 2 y otras áreas donde se asigna un puntaje de 3, se debe promediar dicho puntaje, es decir, se asignaría 2.5.<br/>
                - Tenga cuidado de no registrar el puntaje más alto, seleccione el promedio.<br/>
            </p>
            <p style={{backgroundColor: 'RGB(55, 37, 121, 0.3)', padding: '20px'}}>
            {/*<p style={{backgroundColor: '#D78C76', padding: '20px'}}>*/}
                Defina los signos clínicos:<br/><br/>
                - Eritema: hace referencia al enrojecimiento de la piel.<br/>
                - Edema/pápulas: son los signos agudos de la dermatitis atópica, reflejan el hallazgo histológico de espongiosis.<br/>
                - Excoriaciones: erosiones, una pérdida de la continuidad de la superficie cutánea secundarias a rascado.<br/>
                - Liquenificación: engrosamiento epidérmico en las lesiones crónicas. Los surcos de la piel están muy acentuados, se desarrollan como resultado del rascado o frote persistente en dermatosis crónicas. Las lesiones de prurigo se incluyen en este ítem. En los fototipos más oscuros, la liquenificación folicular puede presentarse como pequeñas pápulas firmes de superficie plana.<br/>
            </p>
            <div style={{backgroundColor: 'RGB(136, 34, 109, 0.3)', padding: '20px', display: 'flex'}}>
            {/*<p style={{backgroundColor: '#EFC7B8', padding: '20px'}}>*/}
                <img src={require('./assets/tipsscorad2.png')} style={{alignSelf: 'center', margin: 10, width: 130}} />
                <p>
                Incluya solo áreas inflamadas en su evaluación EASI. No incluya áreas de:<br/><br/>
                - Xerosis (sequedad), ictiosis, queratosis pilaris, palmas hiperlineales, urticaria e infección (a menos que exista eccema subyacente) o cambios de pigmentación post inflamatoria.
                </p>
            </div>
            <p>
              Analice el resultado y determine la gravedad de la dermatitis atópica:<br/><br/>
              - El puntaje EASI varía de 0 a 72
            </p>
            <Box sx={{ width: '100%', display: { xs: 'block', md: 'flex' }, alignItems: 'stretch'}}>
              <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Box sx={{backgroundColor: commonColors.morado, color: '#FFFFFF', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>
                  Escala  
                </Box>
                <Box sx={{backgroundColor: commonColors.moradoClaro, color: '#000000', textAlign: 'center', padding: '50px', fontWeight: 'bold', flex: 1}}>
                  EASI  
                </Box>
              </Box>
              <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Box sx={{backgroundColor: commonColors.morado, color: '#FFFFFF', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>
                  Puntaje máximo
                </Box>
                <Box sx={{backgroundColor: commonColors.moradoClaro, color: '#000000', textAlign: 'center', padding: '50px', fontWeight: 'bold', flex: 1}}>
                  72  
                </Box>
              </Box>
              <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Box sx={{backgroundColor: commonColors.morado, color: '#FFFFFF', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>
                  Interpretación
                </Box>
                <Box sx={{backgroundColor: commonColors.moradoClaro, color: '#000000', textAlign: 'center', padding: '50px', fontWeight: 'bold', flex: 1}}>
                  ≤ 7 : Leve <br/>
                  7-21 : Moderada<br/>
                  ≥ 21 : Severa  
                </Box>
              </Box>
            </Box>
            <p>
              <br/>Bibliografía:<br/>
              - http://www.homeforeczema.org/research/easi-for-clinical-signs.aspx<br/><br/>
            </p>
        </Container>
      <Footer
        title="Footer"
        description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
      />
    </>
  );

export const Creditos = () => (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: { xs: 2, md: 12 },
            width: '100%',
            backgroundColor: 'transparent',
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover'
          }}
      >
        <h1 style={{color: 'white', textAlign: 'center'}}>Créditos</h1>
      </Box>
      <Container maxWidth="lg">
            <div _ngcontent-nbp-c80="" class="credits-description__description">
            <div _ngcontent-nbp-c80="" class="credits-description__doc">
              <img _ngcontent-nbp-c80="" src={require('./assets/doc.png')} alt="imágen doctora" width="150px" /><br />
              <div _ngcontent-nbp-c80=""><strong _ngcontent-nbp-c80="" class="principal-title">Claudia Marcela Arenas Soto</strong><br />
                <a _ngcontent-nbp-c80="" href="https://www.claudiaarenas.com" target="_blank">www.claudiaarenas.com</a><br />
                <a _ngcontent-nbp-c80="" href="mailto:draclaudiaarenas@gmail.com">draclaudiaarenas@gmail.com</a><br />
                <label _ngcontent-nbp-c80="" class="text-gray">@draclaudiaarenas</label><br />
                <a _ngcontent-nbp-c80="" href="https://orcid.org/0000-0003-3689-5355" target="_blank"> Connecting Research and Researchers (ORCID)</a>
              </div>
            </div>
            <br />
              <ul _ngcontent-nbp-c80="">
              <li _ngcontent-nbp-c80="">Médico y Cirujano</li>
              <li _ngcontent-nbp-c80="">Especialista en Dermatología</li>
              <li _ngcontent-nbp-c80="">Especialista en Docencia Universitaria</li>
              <li _ngcontent-nbp-c80="">Universidad Militar Nueva Granada</li>
              <li _ngcontent-nbp-c80=""> MBA Finanzas, Coaching y Liderazgo- BIU UNIVERSITY (Broward Internacional) </li>
              <li _ngcontent-nbp-c80="">PPCR- Harvard University</li>
              <li _ngcontent-nbp-c80=""> Presidente Asociación Colombiana de Dermatología (AsocolDerma) 2023-2025. </li>
              <li _ngcontent-nbp-c80=""> Vicepresidente Asociación Colombiana de Dermatología (AsocolDerma)2018-2023. </li>
              <li _ngcontent-nbp-c80=""> Secretaria Asociación Colombiana de Dermatología (AsoColderma) 2016-2018. </li>
              <li _ngcontent-nbp-c80=""> Presidente Asociación Colombiana de Dermatología y Cirugía Dermatológica- Capítulo Centro 2018-2022. </li>
              <li _ngcontent-nbp-c80=""> Presidente de CevDerm (Programa de Certificación Voluntaria en Dermatología) AsocolDerma. Noviembre 2016- 2023 </li>
              <li _ngcontent-nbp-c80=""> Docente de pregrado y postgrado Universidad Militar Nueva Granada </li>
              <li _ngcontent-nbp-c80=""> Docente de Postgrado Fundación Universitaria Sanitas, Universidad Javeriana </li>
              <li _ngcontent-nbp-c80="">Docente de Pregrado Universidad del Rosario</li>
              <li _ngcontent-nbp-c80="">Delegado CILAD en Colombia 2022- 2024</li>
              <li _ngcontent-nbp-c80="">Dermatóloga con Experiencia en Investigación</li>
              <li _ngcontent-nbp-c80=""> Miembro del Comité de Ética- Hospital Universitario Dermatológico Federico Lleras Acosta Desde 2015 – Julio 2018 </li>
              <li _ngcontent-nbp-c80=""> Miembro de Asociación Colombiana de Dermatología y Cirugía Dermatológica (AsoColderma). </li>
              <li _ngcontent-nbp-c80=""> Miembro del Colegio Iberolatinoamericano de Dermatología (CILAD) </li>
              <li _ngcontent-nbp-c80="">Miembro de la Academia Nacional de Medicina</li>
              <li _ngcontent-nbp-c80=""> Par Evaluador e Investigador Junior del Departamento Administrativo de Ciencia, Tecnología e Innovación (Colciencias) </li>
              <li _ngcontent-nbp-c80="">Conferencista Nacional e Internacional</li>
              <li _ngcontent-nbp-c80=""> Autor del Libro Texto <a _ngcontent-nbp-c80="" href="https://www.dermatologia.gov.co/?idcategoria=2464&amp;download=Y" target="_blank">Atlas Las Leishmaniasis</a>, de varios Capítulos del Libro de Dermatología General, <a _ngcontent-nbp-c80="" href="https://iris.paho.org/handle/10665.2/52645" target="_blank">Atlas interactivo de Leishmaniasis en las américas </a>(OPS) y Artículos Científicos https://iris.paho.org/handle/10665.2/52645 </li>
              <li _ngcontent-nbp-c80=""> Autor sitio web <a _ngcontent-nbp-c80="" href="https://inmunoderm.co" target="_blank">Inmunoderm.co</a></li>
              <li _ngcontent-nbp-c80=""> Autor aplicación web <a _ngcontent-nbp-c80="" href="http://www.dermatopic.co/" target="_blank">Dermatopic.co</a></li>
              <li _ngcontent-nbp-c80=""> Coordinadora académica del diplomado Dermatitis Atópica: “Una visión holística de la enfermedad” </li></ul></div>
        </Container>
      <Footer
        title="Footer"
        description="Las escalas para la medición y diagnóstico dermatológico publicadas e incluidas en DERMATOPIC son de uso exclusivo de los usuarios que adquirieron el derecho a a acceder al contenido de esta aplicación, únicamente con fines de ilustración, enseñanza y como apoyo al diagnóstico profesional. En consecuencia, queda absolutamente prohibido la copia, reproducción o utilización de estos con propósito de lucro o con el objeto de comercializarlos u obtener cualquier provecho económico, por cuanto tales conductas infringen los derechos de sus autores y genera consecuencias legales penales, civiles y disciplinarias."
      />
    </>
  );